import React, { useState } from 'react';
import { ChevronRight, ChevronLeft } from 'lucide-react';

interface AlertAndIssuesProps {
  data: any;
  type: 'alert' | 'issues';
}

const AlertAndIssues: React.FC<AlertAndIssuesProps> = ({ data, type }) => {
  const [isOpen,setIsOpen] = useState(false);
  return (
    <>
        <div className={`mx-auto w-full rounded-lg h-full bg-gray-900 text-white overflow-y-auto shadow-lg`}>
        {/* Header */}
            <div className="flex items-center justify-between px-4 py-3 bg-gray-800 border-b border-gray-700">
            <h2 className="text-lg font-semibold">{type === "alert" ? "Alerts" : "Issues"}</h2>
            <button
                className="text-sm text-gray-400 hover:text-white transition"
                onClick={() => setIsOpen(prev=> !prev)}
            >
                {!isOpen?`Show All ${data?.length}`:`Close`}
            </button>
            </div>

            {/* Alerts */}
            {type ==="alert" &&<div className="p-4 space-y-4">
                {data?.map((alert: any, index: number) => (
                    (isOpen || index < 1) && <div
                    key={alert.id}
                    className={`rounded-lg p-4 shadow-lg relative hover:shadow-xl transition ${
                        alert.priority.charAt(0).toUpperCase() + alert.priority.slice(1) === 'High'
                        ? 'bg-red-500'
                        : alert.priority.charAt(0).toUpperCase() + alert.priority.slice(1) === 'Medium'
                        ? 'bg-yellow-500'
                        : 'bg-green-500'
                    }`}
                    >
                    {/* Header */}
                    <div className="flex items-center justify-between">
                        <span className="text-sm font-bold uppercase tracking-wide">
                        {alert.priority.charAt(0).toUpperCase() + alert.priority.slice(1)}
                        </span>
                        <p>
                        <strong>ID:</strong> {alert?.alert_id}
                        </p>
                    </div>

                    {/* Body */}
                    <div className="mt-2 text-sm">
                        <p>
                        <strong>Description:</strong> {alert.purpose}
                        </p>
                        <p>
                        <strong>Raised On:</strong> {alert.raised_date}
                        </p>
                    </div>
                    </div>
                ))}
            </div>}
            {type ==="issues" &&<div className="p-4 space-y-4">
                {data.map((issue: any, index: number) => (
                    (isOpen || index < 1) && <div
                    key={issue.issue_id}
                    className={`rounded-lg p-4 shadow-lg relative hover:shadow-xl transition ${
                        issue.priority.charAt(0).toUpperCase() + issue.priority.slice(1) === 'High'
                        ? 'bg-red-500'
                        : issue.priority.charAt(0).toUpperCase() + issue.priority.slice(1) === 'Medium'
                        ? 'bg-yellow-500'
                        : 'bg-green-500'
                    }`}
                    >
                    {/* Header */}
                    <div className="flex items-center justify-between">
                        <span className="text-sm font-bold uppercase tracking-wide">
                        {issue.priority.charAt(0).toUpperCase() + issue.priority.slice(1)}
                        </span>
                        <p>
                        <strong>ID:</strong> {issue?.issue_id}
                        </p>
                    </div>

                    {/* Body */}
                    <div className="mt-2 text-sm">
                        <p>
                        <strong>Type:</strong> {issue.type}
                        </p>
                        <p>
                        <strong>Raised On:</strong> {issue.raised_date}
                        </p>
                    </div>
                    </div>
                ))}
            </div>}
            {/* No data */}
            {data.length === 0 && type==="alert" && (
                <p className="text-center text-gray-400">Silence is golden. No alerts at the moment</p>
            )}
            {data.length === 0 && type === "issues" && (
                <p className="text-center text-gray-400">Looks like smooth sailing ahead!. No issues at the momemnt</p>
            )}
            {data?.length-1>0 && !isOpen && <div className='px-4'>
                <p
                    className="text-right pb-3 text-sm text-gray-400 hover:text-white transition"
                >
                    {`${data?.length-1} More`}
                </p>
            </div>}
        </div>
    </>
  );
};

export default AlertAndIssues;