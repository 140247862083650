import React, { useState, useEffect } from 'react';
import {
    Paper,
    Typography,
    Autocomplete,
    TextField,
    Grid,
    Button,
    Box
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { selectUserData } from '../../../store/store/authSlice';
import { reportIssue, getAllDepartments, reportDocumentIssue } from '../../../api/endpoints/APIEndpoints';
import { toast } from 'react-toastify';
import Multiselect from 'multiselect-react-dropdown';
import { Select } from '../../common/Select';

interface ReportIssueProps {
    documents: any[];
}

interface FormData {
    issue_type: string;
    description: string;
    fromDepartment: string;
    targetDepartments: any[];
}

const ReportIssue: React.FC<ReportIssueProps> = ({ documents }) => {
    const [selectedDocument, setSelectedDocument] = useState<any>(null);
    const [departments, setDepartments] = useState<any[]>([]);
    const userDetails = useSelector(selectUserData);
    
    const { control, handleSubmit, reset } = useForm<FormData>({
        defaultValues: {
            issue_type: '',
            description: '',
            fromDepartment: '',
            targetDepartments: []
        }
    });

    // Fetch departments
    useEffect(() => {
        getAllDepartments.get()
            .then((res) => {
                const formattedDepts = res.map((dept: any) => ({
                    value: dept.department_id,
                    label: dept.department_name
                }));
                setDepartments(formattedDepts);
            })
            .catch(err => console.error("Error fetching departments:", err));
    }, []);

    const onSubmit = (formData: FormData) => {
        const payload = {
            document_id: selectedDocument?.document_id,
            issue_type: formData.issue_type,
            description: formData.description,
            raised_by_department_id: Number(formData.fromDepartment),
            target_department_ids: formData.targetDepartments.map((dept: any) => dept.id),
            reported_by: userDetails?.employee_id
        };

        reportDocumentIssue.post(payload)
            .then((res) => {
                toast.success('Issue reported successfully');
                // Reset form
                setSelectedDocument(null);
                reset();
            })
            .catch((err) => {
                toast.error('Failed to report issue');
                console.error(err);
            });
    };

    const multiSelectFormattedDepartmentOptions = departments.map(item => ({
        name: item.label,
        id: item.value
    }));

    return (
        <Paper className="p-8 max-w-3xl mx-auto mt-8">
            <Typography variant="h5" className="mb-6 text-gray-800 font-medium">
                Report Document Issue
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={documents || []}
                            getOptionLabel={(option) =>
                                `${option.document_name} - ${option.document_number}`
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Document"
                                    required
                                />
                            )}
                            value={selectedDocument}
                            onChange={(_, newValue) => setSelectedDocument(newValue)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="issue_type"
                            control={control}
                            rules={{ required: 'Issue Type is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    label="Issue Type"
                                    required
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="description"
                            control={control}
                            rules={{ required: 'Description is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    label="Issue Description"
                                    required
                                    multiline
                                    rows={4}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="fromDepartment"
                            control={control}
                            rules={{ required: 'From Department is required' }}
                            render={({ field }) => (
                                <Select
                                    label="From Department"
                                    options={departments}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <p className='text-left text-sm pb-2 text-gray-800'>Target Department(s)</p>
                        <Controller
                            name="targetDepartments"
                            control={control}
                            render={({ field }) => (
                                <Multiselect
                                    options={multiSelectFormattedDepartmentOptions}
                                    selectedValues={field.value}
                                    onSelect={(selectedList) => field.onChange(selectedList)}
                                    onRemove={(selectedList) => field.onChange(selectedList)}
                                    displayValue="name"
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <Box className="mt-8 flex justify-end">
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                    >
                        Report Issue
                    </Button>
                </Box>
            </form>
        </Paper>
    );
};

export default ReportIssue;
