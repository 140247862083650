import React, { useState, useEffect } from 'react';
import { fetchAllAppliances, fetchRoutines, getAllProjects } from '../../api/endpoints/APIEndpoints';
import { InspectionRoutine, Project } from '../../types/common';
import AddRoutineForm from '../../components/app/AddRoutineForm';
import { Appliance } from '../../types/tasks';
import { Tabs, Tab, Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { selectUserData } from '../../store/store/authSlice';
import { shallowEqual, useSelector } from 'react-redux';

const StyledTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '16px',
  '&.Mui-selected': {
    color: '#1890ff',
  },
});

export const InspectionDashboard: React.FC = () => {
    const [routines, setRoutines] = useState<InspectionRoutine[]>([]);
    const [applianceData, setApplianceData] = useState<Appliance[]>([])
    const [projects, setProjects] = useState<Project[]>()
    const [tabValue, setTabValue] = useState(0);
    const userData = useSelector(selectUserData, shallowEqual);

    useEffect(() => {
        fetchRoutines.get()
            .then((res) => setRoutines(res))
            .catch(err => console.log("ERROR!!", err))

        fetchAllAppliances.post({employeeId: userData?.employee_id})
            .then((res) => {
                console.log("All appliance List", res)
                setApplianceData(res)
            })
            .catch((error) => console.log("ERROR!!", error))

        getAllProjects.post({employee_id: userData?.employee_id})
            .then((res) => {
                setProjects(res)
            }).catch(err => console.log("ERROR!!", err))
    }, []);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <div className="container mx-auto p-4">
            <Typography variant="h4" component="h1" gutterBottom>
                Inspection Dashboard
            </Typography>

            <StyledTabs value={tabValue} onChange={handleTabChange} aria-label="inspection dashboard tabs">
                <StyledTab label="Dashboard" />
                <StyledTab label="Add Inspection Routine" />
            </StyledTabs>

            <Box sx={{ mt: 3 }}>
                {tabValue === 0 && (
                    <>
                        <Typography variant="h5" gutterBottom>Inspection Routines</Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Frequency</TableCell>
                                        <TableCell>Due Date Offset</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {routines.map((routine) => (
                                        <TableRow key={routine.routine_id}>
                                            <TableCell>{routine.routine_name}</TableCell>
                                            <TableCell>{routine.frequency}</TableCell>
                                            <TableCell>{routine.due_date_offset} days</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
                {tabValue === 1 && (
                    <AddRoutineForm
                        projectOptions={projects?.map((item) => ({
                            label: item.project_name,
                            value: item.project_id.toString(),
                        })) || []}
                        applianceOptions={applianceData?.map((item) => ({
                            label: item.appliance_name,
                            value: item.appliance_id.toString(),
                        })) || []}
                    />
                )}
            </Box>
        </div>
    );
};



