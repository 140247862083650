import React, { useEffect, useState } from 'react';

interface ConfirmationPopupProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  isOpen,
  onConfirm,
  onCancel,
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Block background scrolling
      setVisible(true);
    } else {
      document.body.style.overflow = ''; // Allow background scrolling
      setTimeout(() => setVisible(false), 300); // Wait for animation to complete
    }
  }, [isOpen]);

  const handleConfirm = () => {
    const confirmButton = document.getElementById('confirm-button');
    confirmButton?.classList.add('animate-pulse');
    setTimeout(() => {
      confirmButton?.classList.remove('animate-pulse');
      onConfirm();
    }, 500); // Simulate animation duration
  };

  const handleCancel = () => {
    const cancelButton = document.getElementById('cancel-button');
    cancelButton?.classList.add('animate-pulse');
    setTimeout(() => {
      cancelButton?.classList.remove('animate-pulse');
      onCancel();
    }, 500); // Simulate animation duration
  };

  if (!isOpen && !visible) return null;

  return (
    <div
      className={`fixed inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm overflow-y-auto h-full w-full z-50 flex items-center justify-center transition-opacity duration-300 ${
        isOpen ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <div
        className={`relative p-5 w-96 rounded-lg bg-white transform transition-all duration-300 ${
          isOpen ? 'translate-y-0 scale-100' : '-translate-y-10 scale-95'
        } shadow-[0_10px_15px_rgba(0,0,0,0.2),0_20px_40px_rgba(0,0,0,0.3),0_1px_2px_rgba(0,0,0,0.05)]`}
        style={{
          boxShadow: '0 10px 15px rgba(0, 0, 0, 0.2), 0 20px 40px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.05)',
        }}
      >
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Wait, Are You Sure About This?</h3>
          <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-500">
             Deleting this is permanent, and there’s no going back.
            </p>
          </div>
          <div className="items-center px-4 py-3">
            <button
              id="confirm-button"
              onClick={handleConfirm}
              className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-24 mr-2 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300 transition-transform transform hover:scale-105 shadow-lg"
            >
              Delete
            </button>
            <button
              id="cancel-button"
              onClick={handleCancel}
              className="px-4 py-2 bg-gray-300 text-gray-700 text-base font-medium rounded-md w-24 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 transition-transform transform hover:scale-105 shadow-lg"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
