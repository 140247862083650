import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Input } from '../common/Input';
import { Select } from '../common/Select';
import { updateAppliance } from '../../api/endpoints/APIEndpoints';
import Textarea from '../common/TextArea';
import Multiselect from 'multiselect-react-dropdown';
import CustomDatePicker from '../common/CustomDatePicker';
import { FaPrint } from 'react-icons/fa';
import Barcode from './Barcode';
import Checkbox from '../common/Checkbox';
import { ThreeDots } from 'react-loader-spinner';
import { toast } from 'react-toastify';

interface ApplianceEditForm {
    appliance: any;
    departmentOptions: { value: string; label: string }[];
    projectOptions: { value: string; label: string }[];
    addParentQues: boolean;
    checklistData: { value: string; label: string; checklist_type: string }[];
    routineOptions: any[];
    onCloseForm: () => void;
}

const ApplianceEditForm: React.FC<ApplianceEditForm> = ({ 
    appliance, 
    departmentOptions, 
    projectOptions, 
    addParentQues, 
    checklistData,
    routineOptions,
    onCloseForm
}) => {
    const { control, handleSubmit } = useForm();
    
    // Basic appliance details
    const [applianceName, setApplianceName] = useState(appliance.appliance_name);
    const [applianceDescription, setApplianceDescription] = useState(appliance.appliance_description);
    const [applianceStatus, setApplianceStatus] = useState(appliance.appliance_status);
    const [parentApplianceId, setParentApplianceId] = useState(appliance.parent_appliance_id || '');
    const [location, setLocation] = useState(appliance.location || '');
    const [manufacturer, setManufacturer] = useState(appliance.manufacturer || '');
    const [model, setModel] = useState(appliance.model || '');
    const [serialNumber, setSerialNumber] = useState(appliance.serial_number || '');
    const [purchaseDate, setPurchaseDate] = useState<Date | null>(appliance.purchase_date ? new Date(appliance.purchase_date) : null);
    const [image, setImage] = useState<File | null>(null);
    const [imageBase64, setImageBase64] = useState<string | null>(appliance.image || null);

    // Projects and Departments
    const [selectedProjects, setSelectedProjects] = useState(
        appliance.departments?.map((proj: any) => ({
            id: proj.project_id,
            name: proj.project_name || projectOptions.find(p => p.value === proj.project_id)?.label
        })) || []
    );

    const [selectedDepartments, setSelectedDepartments] = useState<any[]>(
        appliance.departments?.map((dept: any) => ({
            id: dept.department_id,
            name: dept.department_name
        })) || []
    );

    // Department data structure
    const [departmentData, setDepartmentData] = useState<{
        [departmentName: string]: {
            id: string;
            isInspection: boolean;
            isMaintenance: boolean;
            InspectionData: {
                checklist: string;
                routine: string;
                frequency: string;
                dueDateOffset: string;
            };
            MaintenanceData: {
                checklist: string;
                routine: string;
                frequency: string;
                dueDateOffset: string;
            };
        };
    }>({});

    // Options for dropdowns
    const frequencyOptions = [
        { value: "Weekly", label: "Weekly" },
        { value: "Fortnightly", label: "Fortnightly" },
        { value: "Monthly", label: "Monthly" },
        { value: "Quarterly", label: "Quarterly" },
        { value: "Half-Yearly", label: "Half-Yearly" },
        { value: "Yearly", label: "Yearly" }
    ];

    const dueDateOffsetOptions = [
        { value: "7", label: "7 days from scheduled inspection date" },
        { value: "15", label: "15 days from scheduled inspection date" }
    ];

    // Handlers
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setImage(e.target.files[0]);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageBase64(reader.result as string);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onSelect = (selectedList: any[], selectedItem: any) => {
        setSelectedDepartments(selectedList);
        setDepartmentData(prevData => ({
            ...prevData,
            [selectedItem.name]: {
                id: selectedItem.id,
                isInspection: false,
                isMaintenance: false,
                InspectionData: { checklist: '', routine: '', frequency: '', dueDateOffset: '' },
                MaintenanceData: { checklist: '', routine: '', frequency: '', dueDateOffset: '' }
            }
        }));
    };

    const onRemove = (selectedList: any[], removedItem: any) => {
        setSelectedDepartments(selectedList);
        setDepartmentData(prevData => {
            const { [removedItem.name]: _, ...rest } = prevData;
            return rest;
        });
    };

    const handleCheckboxChange = (deptName: string, type: 'isInspection' | 'isMaintenance', value: boolean) => {
        setDepartmentData(prevData => ({
            ...prevData,
            [deptName]: {
                ...prevData[deptName],
                [type]: value
            }
        }));
    };

    // Initialize department data from existing appliance data
    useEffect(() => {
        if (appliance.departments) {
            const initialDepartmentData: any = {};
            appliance.departments.forEach((dept: any) => {
                initialDepartmentData[dept.department_name] = {
                    id: dept.department_id,
                    isInspection: !!dept.inspection_checklist_id,  // Check if inspection exists
                    isMaintenance: !!dept.maintenance_checklist_id,  // Check if maintenance exists
                    InspectionData: {
                        checklist: dept.inspection_checklist_id?.toString() || '',
                        routine: dept.inspection_routine_id?.toString() || '',
                        frequency: dept.inspection_frequency || '',
                        dueDateOffset: dept.inspection_due_date_offset?.toString() || ''
                    },
                    MaintenanceData: {
                        checklist: dept.maintenance_checklist_id?.toString() || '',
                        routine: dept.maintenance_routine_id?.toString() || '',
                        frequency: dept.maintenance_frequency || '',
                        dueDateOffset: dept.maintenance_due_date_offset?.toString() || ''
                    }
                };
            });
            setDepartmentData(initialDepartmentData);
        }
    }, [appliance]);

    // Filter checklist options based on type
    const getFilteredChecklistOptions = (type: 'Inspection' | 'Maintenance') => {
        return checklistData.filter(checklist => checklist.checklist_type === type)
            .map(checklist => ({
                value: checklist.value,
                label: checklist.label
            }));
    };

    const renderDetailsSection = (deptName: string, type: 'Inspection' | 'Maintenance') => {
        const data = type === 'Inspection' 
            ? departmentData[deptName].InspectionData 
            : departmentData[deptName].MaintenanceData;
        const filteredChecklistOptions = getFilteredChecklistOptions(type);

        return (
            <div className="mt-4 p-4 bg-gray-50 rounded">
                <h4 className="text-md font-medium mb-3">{type} Details - {deptName}</h4>
                <div className="grid grid-cols-1 gap-4">
                    <Select
                        label="Checklist"
                        options={filteredChecklistOptions}
                        value={data.checklist}
                        onChange={(e) => handleDetailsChange(deptName, type, 'checklist', e.target.value)}
                        required
                    />
                    <Select
                        label="Routine name"
                        options={routineOptions.map(r => ({ 
                            value: r.routine_id.toString(),
                            label: r.routine_name
                        }))}
                        value={data.routine}
                        onChange={(e) => handleDetailsChange(deptName, type, 'routine', e.target.value)}
                        required
                    />
                    <Select
                        label="Frequency"
                        options={frequencyOptions}
                        value={data.frequency}
                        onChange={(e) => handleDetailsChange(deptName, type, 'frequency', e.target.value)}
                        required
                    />
                    <Select
                        label="Due Date Offset"
                        options={dueDateOffsetOptions}
                        value={data.dueDateOffset}
                        onChange={(e) => handleDetailsChange(deptName, type, 'dueDateOffset', e.target.value)}
                        required
                    />
                </div>
            </div>
        );
    };

    const handleDetailsChange = (deptName: string, type: 'Inspection' | 'Maintenance', field: string, value: string) => {
        setDepartmentData(prevData => ({
            ...prevData,
            [deptName]: {
                ...prevData[deptName],
                [`${type}Data`]: {
                    ...prevData[deptName][`${type}Data`],
                    [field]: value
                }
            }
        }));
    };

    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const SuccessModal = ({ onClose, applianceName, departments }: { 
        onClose: () => void;
        applianceName: string;
        departments: any[];
    }) => (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-md w-full">
                <div className="flex items-center justify-center mb-4">
                    <div className="bg-green-100 p-3 rounded-full">
                        <svg className="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                    </div>
                </div>
                <h3 className="text-lg font-semibold text-center text-gray-900 mb-2">Update Successful!</h3>
                <div className="bg-gray-50 p-4 rounded-md mb-4">
                    <p className="text-sm text-gray-600 mb-2">
                        <span className="font-medium">Appliance Name:</span> {applianceName}
                    </p>
                    <p className="text-sm text-gray-600 mb-2">
                        <span className="font-medium">Departments:</span> {departments.map(dept => dept.name).join(', ')}
                    </p>
                    <p className="text-sm text-gray-600">
                        <span className="font-medium">Last Updated:</span> {new Date().toLocaleString()}
                    </p>
                </div>
                <div className="flex justify-end space-x-3">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 transition-colors"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );

    const onSubmit = async (formData: any) => {
        setIsLoading(true);
        try {
            // Create departments array with corresponding projects
            const departmentsWithProjects = Object.entries(departmentData).map(([name, data]) => ({
                department_id: data.id,
                inspection_checklist_id: data.isInspection ? data.InspectionData.checklist : null,
                maintenance_checklist_id: data.isMaintenance ? data.MaintenanceData.checklist : null,
                inspection_routine_id: data.isInspection ? data.InspectionData.routine : null,
                maintenance_routine_id: data.isMaintenance ? data.MaintenanceData.routine : null
            }));

            // Create projects array matching departments length
            const projectsArray = selectedProjects.map((proj: { id: any; }) => ({
                project_id: proj.id
            }));

            const updatedAppliance = {
                appliance_id: appliance.appliance_id,
                appliance_name: applianceName,
                appliance_description: applianceDescription,
                appliance_status: applianceStatus,
                parent_appliance_id: parentApplianceId || null,
                location,
                manufacturer,
                model,
                serial_number: serialNumber,
                purchase_date: purchaseDate,
                image: imageBase64,
                departments: departmentsWithProjects,
                projects: projectsArray
            };

            const response = await updateAppliance.post(updatedAppliance);
            if (response) {
                setShowSuccessModal(true);
                toast.success('Appliance updated successfully');
            }
        } catch (error) {
            console.error("Error updating appliance:", error);
            toast.error('Failed to update appliance');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="relative">
            <div className="px-4">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <div className="grid grid-cols-2 gap-6">
                        <Input
                            label="Appliance Name"
                            value={applianceName}
                            onChange={(e) => setApplianceName(e.target.value)}
                            required
                        />
                        <Textarea
                            label="Appliance Description"
                            value={applianceDescription}
                            onChange={(e) => setApplianceDescription(e.target.value)}
                        />
                        {/* Add all other fields similar to AddAppliance */}
                        {/* ... */}
                    </div>

                    {/* Projects Section */}
                    <div className="mt-6">
                        <p className='text-left text-sm pb-2 text-gray-800'>Projects</p>
                        <Controller
                            name="projects"
                            control={control}
                            defaultValue={selectedProjects}
                            render={({ field }) => (
                                <Multiselect
                                    options={projectOptions.map(option => ({ 
                                        id: option.value, 
                                        name: option.label 
                                    }))}
                                    selectedValues={selectedProjects}
                                    onSelect={(selectedList) => {
                                        setSelectedProjects(selectedList);
                                        field.onChange(selectedList);
                                    }}
                                    onRemove={(selectedList) => {
                                        setSelectedProjects(selectedList);
                                        field.onChange(selectedList);
                                    }}
                                    displayValue="name"
                                />
                            )}
                        />
                    </div>

                    {/* Departments Section */}
                    <div className="col-span-2 mt-4 p-4 border rounded">
                        <p className='text-sm text-left pb-2'>Departments:</p>
                        <Multiselect
                            options={departmentOptions.map(dept => ({
                                id: dept.value,
                                name: dept.label
                            }))}
                            selectedValues={selectedDepartments}
                            onSelect={onSelect}
                            onRemove={onRemove}
                            displayValue="name"
                        />

                        {Object.entries(departmentData).map(([deptName, deptInfo]) => (
                            <div key={deptInfo.id} className="mt-4 p-4 border rounded">
                                <h3 className="text-lg font-semibold mb-3">Department: {deptName}</h3>
                                <div className="flex space-x-4 mb-4">
                                    <Checkbox
                                        label="Inspection"
                                        checked={deptInfo.isInspection}
                                        onChange={(checked) => handleCheckboxChange(deptName, 'isInspection', checked)}
                                    />
                                    <Checkbox
                                        label="Maintenance"
                                        checked={deptInfo.isMaintenance}
                                        onChange={(checked) => handleCheckboxChange(deptName, 'isMaintenance', checked)}
                                    />
                                </div>
                                {deptInfo.isInspection && renderDetailsSection(deptName, 'Inspection')}
                                {deptInfo.isMaintenance && renderDetailsSection(deptName, 'Maintenance')}
                            </div>
                        ))}
                    </div>

                    {/* Add the buttons at the bottom of the form */}
                    <div className="flex justify-end space-x-4 mt-6">
                        <button
                            type="button"
                            onClick={() => {
                                onCloseForm();
                            }}
                            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                            disabled={isLoading}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center transition-colors"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <>
                                    <ThreeDots
                                        height="20"
                                        width="20"
                                        color="white"
                                        ariaLabel="loading"
                                    />
                                    <span className="ml-2">Processing...</span>
                                </>
                            ) : (
                                <>
                                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"/>
                                    </svg>
                                    Update Appliance
                                </>
                            )}
                        </button>
                    </div>
                </form>
            </div>

            {/* Loading Modal */}
            {isLoading && (
                <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-40">
                    <div className="bg-white p-6 rounded-lg shadow-xl">
                        <div className="flex flex-col items-center">
                            <ThreeDots
                                height="50"
                                width="50"
                                color="#3B82F6"
                                ariaLabel="loading"
                            />
                            <p className="mt-4 text-gray-700">Updating Appliance...</p>
                            <p className="text-sm text-gray-500">Please wait while we process your changes</p>
                        </div>
                    </div>
                </div>
            )}

            {/* Success Modal */}
            {showSuccessModal && (
                <SuccessModal
                    onClose={() => {
                        setShowSuccessModal(false);
                        onCloseForm();
                    }}
                    applianceName={applianceName}
                    departments={selectedDepartments}
                />
            )}
        </div>
    );
};

export default ApplianceEditForm;
