import React, { useState } from 'react';
import {
    Paper,
    TextField,
    MenuItem,
    Button,
    Box,
    Typography,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { updateRenewal } from '../../../api/endpoints/APIEndpoints';
import Modal from '../../common/Modal';

interface UpdateDocumentProps {
    documentData: any[];
    departmentOptions: any[];
}

interface FormData {
    next_renewal_date: Dayjs | null;
    next_due_date: Dayjs | null;
    reminder_frequencies: string;
    comment: string;
    completed_by: string;
    document_id: string | null;
}

const calculateCurrentRenewalDate = (initialDate: string, frequency: string): string => {
    const issueDate = new Date(initialDate);
    let renewalDate = new Date(issueDate);

    switch (frequency.toLowerCase()) {
        case 'monthly':
            renewalDate.setDate(issueDate.getDate() + 30);
            break;
        case 'half-yearly':
            renewalDate.setDate(issueDate.getDate() + 180);
            break;
        case 'yearly':
            renewalDate.setFullYear(issueDate.getFullYear() + 1);
            break;
        default:
            return 'Invalid frequency';
    }

    return renewalDate.toLocaleDateString();
};

const UpdateDocument: React.FC<UpdateDocumentProps> = ({ documentData, departmentOptions }) => {
    const [selectedDocument, setSelectedDocument] = useState('');
    const [isDocumentRenewed, setIsDocumentRenewed] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        next_renewal_date: null,
        next_due_date: null,
        reminder_frequencies: '',
        comment: '',
        completed_by: '',
        document_id: null
    });
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [modalDetails, setModalDetails] = useState<any>(null);

    const handleDocumentSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const docNumber = event.target.value;
        setSelectedDocument(docNumber);
        setIsDocumentRenewed(false);

        const selectedDoc = documentData.find(doc => doc.document_number === docNumber);

        setFormData({
            next_renewal_date: null,
            next_due_date: null,
            reminder_frequencies: selectedDoc?.reminder_frequencies || '',
            comment: '',
            completed_by: '',
            document_id: selectedDoc?.document_id || null
        });
    };

    const selectedDocumentData = documentData.find(doc => doc.document_number === selectedDocument);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        
        const payload = {
            next_renewal_date: formData.next_renewal_date?.format('YYYY-MM-DD'),
            next_due_date: formData.next_due_date?.format('YYYY-MM-DD'),
            reminder_frequencies: formData.reminder_frequencies,
            comment: formData.comment,
            completed_by: formData.completed_by,
            document_id: formData.document_id
        };

        updateRenewal.post(payload)
            .then((res) => {
                setModalDetails({
                    ...selectedDocumentData,
                    ...formData
                });
                setShowSuccessModal(true);
            })
            .catch((err) => {
                console.log("Error updating renewal", err);
            });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="space-y-6">

                <Paper className="p-6">
                    <TextField
                        select
                        fullWidth
                        label="Select Document"
                        value={selectedDocument}
                        onChange={handleDocumentSelect}
                        className="mb-6"
                    >
                        {documentData.map((doc) => (
                            <MenuItem key={doc.document_number} value={doc.document_number}>
                                {doc.document_name} - {doc.document_number}
                            </MenuItem>
                        ))}
                    </TextField>

                    {selectedDocument && (
                        <form onSubmit={handleSubmit} className="space-y-4 pt-4">
                            {/* Non-editable section */}
                            <div className="mb-6">
                                <Typography variant="body1" className="mb-2">
                                    <strong>Document Id:</strong> {selectedDocumentData?.document_id}
                                </Typography>
                                <Typography variant="body1" className="mb-2">
                                    <strong>Document Name:</strong> {selectedDocumentData?.document_name}
                                </Typography>
                                <Typography variant="body1" className="mb-2">
                                    <strong>Document Number:</strong> {selectedDocumentData?.document_number}
                                </Typography>
                                <Typography variant="body1" className="mb-2">
                                    <strong>Document Description:</strong> {selectedDocumentData?.document_description}
                                </Typography>
                            </div>

                            <Typography variant="body1" className="mb-2">
                                <strong>Current Renewal Date:</strong>{' '}
                                {selectedDocumentData && calculateCurrentRenewalDate(
                                    selectedDocumentData.initial_issue_date,
                                    selectedDocumentData.renewal_frequency
                                )}
                            </Typography>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isDocumentRenewed}
                                        onChange={(e) => setIsDocumentRenewed(e.target.checked)}
                                    />
                                }
                                label="Document Renewed"
                            />

                            {isDocumentRenewed && (
                                <>
                                    <DatePicker
                                        label="Next Renewal Date"
                                        value={formData.next_renewal_date}
                                        onChange={(newValue) => setFormData({ ...formData, next_renewal_date: newValue })}
                                        slotProps={{ textField: { fullWidth: true } }}
                                    />

                                    <DatePicker
                                        label="Due Date for Next Renewal"
                                        value={formData.next_due_date}
                                        onChange={(newValue) => setFormData({ ...formData, next_due_date: newValue })}
                                        slotProps={{ textField: { fullWidth: true } }}
                                    />

                                    <TextField
                                        select
                                        fullWidth
                                        label="Reminder Frequency"
                                        value={formData.reminder_frequencies}
                                        onChange={(e) => setFormData({ ...formData, reminder_frequencies: e.target.value })}
                                    >
                                        <MenuItem value="daily">Daily</MenuItem>
                                        <MenuItem value="weekly">Weekly</MenuItem>
                                        <MenuItem value="monthly">Monthly</MenuItem>
                                    </TextField>

                                    <TextField
                                        fullWidth
                                        label="Comment"
                                        multiline
                                        rows={3}
                                        value={formData.comment}
                                        onChange={(e) => setFormData({ ...formData, comment: e.target.value })}
                                    />

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        size="large"
                                    >
                                        UPDATE
                                    </Button>
                                </>
                            )}
                        </form>
                    )}
                </Paper>
                <Modal show={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
                    <div className="p-8 text-center bg-white rounded-lg">
                        <img
                            src="/success.png"
                            alt="Success"
                            className="mx-auto mb-6 w-24 h-24"
                        />
                        <h3 className="mb-4 text-2xl font-semibold text-green-600">
                            Document Renewal Updated Successfully!
                        </h3>
                        <div className="mb-6 text-left bg-gray-50 p-4 rounded-md">
                            <p className="mb-2">
                                <span className="font-semibold">Document Name:</span> {selectedDocumentData?.document_name}
                            </p>
                            <p className="mb-2">
                                <span className="font-semibold">Document Number:</span> {selectedDocumentData?.document_number}
                            </p>
                            <p className="mb-2">
                                <span className="font-semibold">Next Renewal Date:</span> {modalDetails?.next_renewal_date?.format('DD/MM/YYYY')}
                            </p>
                            <p className="mb-2">
                                <span className="font-semibold">Next Due Date:</span> {modalDetails?.next_due_date?.format('DD/MM/YYYY')}
                            </p>
                            <p className="mb-2">
                                <span className="font-semibold">Reminder Frequency:</span> {modalDetails?.reminder_frequencies}
                            </p>
                            <p className="mb-2">
                                <span className="font-semibold">Comment:</span> {modalDetails?.comment}
                            </p>
                        </div>
                        <button
                            type="button"
                            className="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center transition duration-300 ease-in-out transform hover:scale-105"
                            onClick={() => setShowSuccessModal(false)}
                        >
                            Close
                        </button>
                    </div>
                </Modal>
            </div>
        </LocalizationProvider>
    );
};

export default UpdateDocument; 