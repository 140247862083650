import React, { useEffect, useState, useRef } from 'react';
import { FaUserCircle, FaBars } from 'react-icons/fa';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectUserData } from '../../store/store/authSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchEmployeeApprovalRequest, logout } from '../../api/endpoints/APIEndpoints';
import ApprovalComponent from '../app/ApprovalComponent';
import NotificationComponent from '../app/NotificationComponent';
import { ApprovalRequest } from '../../types/common';
import { setApprovalRequestsData } from '../../store/store/mainSlice';
import  CompanyLogo  from '../../../public/company_logo.png';

interface HeaderProps {
  toggleMenu: () => void;
}

const Header: React.FC<HeaderProps> = ({ toggleMenu }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const userData = useSelector(selectUserData, shallowEqual);
  const navigate = useNavigate();
  const location = useLocation();
  const [approvalRequests, setApprovalRequests] = React.useState<ApprovalRequest[]>([]);
  const dispatch = useDispatch()
  const dropdownRef = useRef<HTMLDivElement>(null);

  const isInspectionRoute = () => {
    return location.pathname.startsWith('/inspection');
  };

  const navItems = [
    { name: 'Inspection', path: '/inspection' },
    { name: 'Asset Management', path: '/asset-management' },
    { name: 'Documents', path: '/documents' },
  ];

  const handleApprove = (id: number) => {
    setApprovalRequests(prev =>
      prev.map(req => req.id === id ? { ...req, status: 'approved' } : req)
    );
    // Here you would also make an API call to update the status on the server
  };

  const handleReject = (id: number) => {
    setApprovalRequests(prev =>
      prev.map(req => req.id === id ? { ...req, status: 'denied' } : req)
    );
    // Here you would also make an API call to update the status on the server
  };

  const handleLogout = () => {
    logout.post({ employeeId: userData?.employee_id })
      .then((res) => {
        console.log("logged out", res)
        navigate("/login")
      })
      .catch((error) => console.log("ERROR!!", error))
  }

  useEffect(() => {
    fetchEmployeeApprovalRequest.post({ employeeId: userData?.employee_id })
      .then((res) => {
        setApprovalRequests(res)
        dispatch(setApprovalRequestsData(res))
      })
      .catch((err) => console.log("ERROR!!", err))
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        dropdownOpen) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]); // Add dropdownOpen to dependency array

  useEffect(() => {
    // Set Inspection as default if no path is selected
    if (location.pathname === '/') {
      navigate('/inspection');
    }
  }, []);

  return (
    <header className="flex justify-between sticky z-50 top-0 items-center p-5 shadow-lg bg-white h-16">
      <div className="flex items-center">
        {/* <button onClick={toggleMenu} className="mr-4 focus:outline-none hover:text-gray-600 transition-colors">
          <FaBars size={24} />
        </button> */}
        <div className="text-2xl font-bold cursor-pointer hover:text-gray-700 transition-colors mr-8" onClick={() => navigate('/')}>
          <div className='flex items-center'>
            <img src="/company_logo.png" alt="error" width="60" />
            <div>Spectent</div></div>
        </div>
        <nav className="flex justify-center">
          {navItems.map((item) => (
            <a
              key={item.name}
              href={item.path}
              className={`text-sm font-medium px-4 py-2 ${
                (item.path === '/inspection' && isInspectionRoute()) || 
                location.pathname === item.path
                  ? 'text-blue-600 border-b-2 border-blue-600'
                  : 'text-gray-500 hover:text-gray-900'
              } transition-colors duration-200`}
              onClick={(e) => {
                e.preventDefault();
                navigate(item.path);
              }}
            >
              {item.name}
            </a>
          ))}
        </nav>
      </div>
      <div className='flex items-center'>
        <div className='mr-6 flex space-x-4'>
          <ApprovalComponent
            approvalRequests={approvalRequests}
            onApprove={handleApprove}
            onReject={handleReject}
          />
          <NotificationComponent />
        </div>
        <div ref={dropdownRef} className="relative">
          <div className='flex items-center border border-gray-200 p-2 rounded-lg cursor-pointer hover:bg-gray-50 transition-colors' onClick={() => setDropdownOpen(!dropdownOpen)}>
            <button className="focus:outline-none">
              <FaUserCircle size={28} className="text-gray-600" />
            </button>
            <div className='pl-3'>
              <div className='font-semibold text-sm'>{userData && (userData?.first_name + " " + userData?.last_name)}</div>
              <div className='text-xs text-gray-500'>{userData?.role.level !== 4 && userData?.departments[0]?.department_name}</div>
            </div>
          </div>
          {dropdownOpen && (
            <div className="absolute z-50 right-0 mt-2 w-48 top-full bg-white rounded-lg shadow-xl border border-gray-100 cursor-pointer">
              <p
                className="block px-4 py-2 hover:bg-gray-50 cursor-pointer transition-colors"
                onClick={() => {
                  navigate("/profile");
                  setDropdownOpen(false);
                }}
              >
                Profile
              </p>
              <p
                className="block px-4 py-2 hover:bg-gray-50 cursor-pointer transition-colors"
                onClick={() => {
                  navigate("/change-password");
                  setDropdownOpen(false);
                }}
              >
                Change Password
              </p>
              <p
                className="block px-4 py-2 hover:bg-gray-50 cursor-pointer transition-colors"
                onClick={() => {
                  handleLogout();
                  setDropdownOpen(false);
                }}
              >
                Logout
              </p>

            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;