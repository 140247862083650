import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Tooltip, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Description as DocumentIcon,
  BugReport as IssueIcon,
} from '@mui/icons-material';
import { FaPrint } from 'react-icons/fa';
import DocumentDashboard from '../components/app/Document/DocumentDashboard';
import AddDocument from '../components/app/Document/AddDocument';
import UpdateDocument from '../components/app/Document/UpdateDocument';
import ReportIssue from '../components/app/Document/ReportIssue';
import ResolveIssue from '../components/app/Document/ResolveIssue';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../store/store/authSlice';
import Loader from '../components/common/Loader';
import { fetchAllDocuments } from '../api/endpoints/APIEndpoints';
import * as XLSX from 'xlsx';
import IssueDashboard from '../components/app/Document/IssueDashboard';

const StyledTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '14px',
  '&.Mui-selected': {
    color: '#1890ff',
    fontWeight: 600,
  },
});

const Document: React.FC = () => {
  const [selectedSection, setSelectedSection] = useState<'documents' | 'issues'>('documents');
  const [tabValue, setTabValue] = useState(0);
  const [documentData, setDocumentData] = useState([]);
  const [departments, setDepartments] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useSelector(selectUserData, shallowEqual);

  useEffect(() => {
    // setIsLoading(true);
    // Fetch documents and departments data here
    // Example:
    fetchAllDocuments.get()
      .then((res) => setDocumentData(res.data))
      .catch((error) => console.log("error", error))
      .finally(() => setIsLoading(false));

    // getAllDepartments()
    //   .then((res) => {
    //     const formattedDepts = res.map(dept => ({
    //       value: dept.department_id,
    //       label: dept.department_name
    //     }));
    //     setDepartments(formattedDepts);
    //   })
    //   .catch(err => console.log("ERROR!!", err))
    //   .finally(() => setIsLoading(false));
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const renderContent = () => {
    if (selectedSection === 'documents') {
      return (
        <>
          <StyledTabs value={tabValue} onChange={handleTabChange}>
            <StyledTab label="Dashboard" />
            <StyledTab label="Add Document" />
            <StyledTab label="Update Document" />
          </StyledTabs>
          <Box sx={{ mt: 3 }}>
            {tabValue === 0 && <DocumentDashboard documentData={documentData} />}
            {tabValue === 1 && <AddDocument />}
            {tabValue === 2 && <UpdateDocument documentData={documentData} departmentOptions={departments} />}
          </Box>
        </>
      );
    } else {
      return (
        <>
          <StyledTabs value={tabValue} onChange={handleTabChange}>
            <StyledTab label="Dashboard" />
            <StyledTab label="Report Issue" />
            <StyledTab label="Resolve Issue" />
          </StyledTabs>
          <Box sx={{ mt: 3 }}>
            {tabValue === 0 && <IssueDashboard />}
            {tabValue === 1 && <ReportIssue documents={documentData} />}
            {tabValue === 2 && <ResolveIssue />}
          </Box>
        </>
      );
    }
  };

  const exportToExcel = () => {
    const excelData = documentData.map((doc: any) => ({
      'Document ID': doc.document_id,
      'Document Name': doc.document_name,
      'Document Description': doc.document_description,
      'Initial Issue Date': doc.initial_issue_date,
      'Renewal Date': doc.renewal_history?.current_renewal?.renewal_date,
      'Renewal Frequency': doc.renewal_frequency,
      'Priority': doc.priority,
      'Additional Requirements': doc.additional_requirements || 'N/A',
      // Current Renewal Details
      'Current Renewal Status': doc.renewal_history?.current_renewal?.status || 'N/A',
      'Current Renewal Due Date': doc.renewal_history?.current_renewal?.due_date 
          ? new Date(doc.renewal_history?.current_renewal?.due_date).toLocaleDateString()
          : 'N/A',
      'Current Renewal Date': doc.renewal_history?.current_renewal?.renewal_date
          ? new Date(doc.renewal_history?.current_renewal?.renewal_date).toLocaleDateString()
          : 'N/A',
      'Reminder Frequency': doc.renewal_history?.current_renewal?.reminder_frequencies || 'N/A',
      'Department ID': doc.department_id,
      'Created At': new Date(doc.created_at).toLocaleDateString(),
      'Updated At': new Date(doc.updated_at).toLocaleDateString(),
    }));

    // Create worksheet
    const ws = XLSX.utils.json_to_sheet(excelData);
    
    // Create workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Documents");
    
    // Generate Excel file and trigger download
    XLSX.writeFile(wb, `Documents_${new Date().toISOString().split('T')[0]}.xlsx`);
  };

  return (
    <div className="flex">
      {/* Sidebar - Only Documents and Issues */}
      <div className="bg-blue-900 text-white text-sm h-[100vh] w-48 flex flex-col text-left pt-4">
        <div className="space-y-1">
          <div
            className={`flex items-center gap-2 px-4 py-3 cursor-pointer ${
              selectedSection === 'documents' ? 'bg-blue-800 font-medium' : 'hover:bg-blue-800'
            }`}
            onClick={() => {
              setSelectedSection('documents');
              setTabValue(0);
            }}
          >
            <DocumentIcon />
            <span>Documents</span>
          </div>
          <div
            className={`flex items-center gap-2 px-4 py-3 cursor-pointer ${
              selectedSection === 'issues' ? 'bg-blue-800 font-medium' : 'hover:bg-blue-800'
            }`}
            onClick={() => {
              setSelectedSection('issues');
              setTabValue(0);
            }}
          >
            <IssueIcon />
            <span>Issues</span>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-6">
        <div className="flex justify-between items-center mb-6">
          <Box>
            <Typography variant="h4" component="h1" className="text-gray-800">
              {selectedSection === 'documents' ? 'Document Management' : 'Issue Management'}
            </Typography>
            <Typography variant="body1" className="text-gray-600 mt-2">
              {selectedSection === 'documents' 
                ? 'Manage your documents, track renewals, and maintain documentation history'
                : 'Track and manage document-related issues'
              }
            </Typography>
          </Box>
          {selectedSection === 'documents' && (
            <Tooltip title="Export to Excel">
              <IconButton 
                onClick={exportToExcel}
                className="bg-blue-500 hover:bg-blue-600 text-white"
                size="large"
              >
                <FaPrint />
              </IconButton>
            </Tooltip>
          )}
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          renderContent()
        )}
      </div>
    </div>
  );
};

export default Document;
