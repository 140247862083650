import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';

const MainLayout: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="">
      <Header toggleMenu={toggleMenu} />
      <div className="main-content w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
