import React, { useEffect, useState } from 'react'
import { fetchAllAppliances } from '../../../api/endpoints/APIEndpoints';
import { Appliance } from '../../../types/tasks';
import { useSelector } from 'react-redux';
import { shallowEqual } from 'react-redux';
import { selectUserData } from '../../../store/store/authSlice';
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip, Legend, Sector } from 'recharts';
import { Card, CardContent, Typography, Box } from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';

const ApplianceStats = () => {

    const [appliances, setAppliances] = useState<{name:string;value:number}[]>([]);
    const userData = useSelector(selectUserData, shallowEqual);
    useEffect(() => {
        fetchAllAppliances.post({employeeId: userData?.employee_id}).then((res) => {
       if(Array.isArray(res)&&res.length>0){
        const applianceCount:{[key:string]:{name:string,value:number}} = {};
        res.forEach((appliance: Appliance) => {
            const applianceName = appliance.appliance_name.replace(/-.*$/, '').trim();
            if(applianceCount[applianceName]){
                applianceCount[applianceName]={
                    name:applianceName,
                    value:applianceCount[applianceName].value+1
                }
            }else{
                applianceCount[applianceName]={
                    name:applianceName,
                    value:1
                }
            }
        })
        console.log("applianceCount", applianceCount);   
        const applianceCountArray = Object.values(applianceCount);
        setAppliances(applianceCountArray);}
        });
    }, []);

    const [activeIndex, setActiveIndex] = useState(0);
    const totalAppliances = appliances.reduce((sum, item) => sum + item.value, 0);

    const COLORS = ['#228822', '#B22222', '#FFE135', '#4169E1', '#8B008B', '#FF8C00'];

    const renderActiveShape = (props: any) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" fontSize={12}>
                    {`${payload.name}`}
                </text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999" fontSize={12}>
                    {`${value} (${(percent * 100).toFixed(1)}%)`}
                </text>
            </g>
        );
    };

    const onPieEnter = (_: any, index: number) => {
        setActiveIndex(index);
    };

    const renderLegend = () => (
        <Box sx={{ 
            display: "flex", 
            flexDirection: "column", 
            gap: 2,
            justifyContent: "center",
            overflowY: 'auto',
            minWidth: '150px' // Add minimum width for legend
        }}>
            {appliances.map((item, index) => (
                <Box key={item.name} display="flex" alignItems="center">
                    <Box
                        sx={{
                            width: 12,
                            height: 12,
                            backgroundColor: COLORS[index % COLORS.length],
                            marginRight: 1,
                            borderRadius: '50%'
                        }}
                    />
                    <Typography variant="body2">{item.name}</Typography>
                </Box>
            ))}
        </Box>
    );

    return (
        <Card sx={{ maxWidth: 800, margin: 'auto', mt: 4, height: 720 }}>
            <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Appliance Distribution</Typography>
                    <Box>
                        <Typography variant="h4" component="div" fontWeight="bold">
                            {totalAppliances}
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <InventoryIcon color="primary" fontSize="small" sx={{ mr: 0.5 }} />
                            <Typography variant="body2" color="primary">
                                Total Appliances
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" sx={{ height: 500 }}>
                    {/* Legend on the left */}
                    {renderLegend()}
                    
                    {/* Pie chart on the right */}
                    <Box sx={{ flex: 1 }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    activeIndex={activeIndex}
                                    activeShape={renderActiveShape}
                                    data={appliances}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={90}
                                    outerRadius={120}
                                    fill="#8884d8"
                                    dataKey="value"
                                    nameKey="name"
                                    onMouseEnter={onPieEnter}
                                    paddingAngle={5}
                                >
                                    {appliances.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}

export default ApplianceStats