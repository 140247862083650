import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Tooltip, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Inventory as AssetIcon,
  Print as PrintIcon,
  BugReport as IssueIcon,
  People as TeamIcon,
} from '@mui/icons-material';
import { useSelector, shallowEqual } from 'react-redux';
import { selectUserData } from '../store/store/authSlice';
import Loader from '../components/common/Loader';
import { fetchAllAssets } from '../api/endpoints/APIEndpoints';
import AssetDashboard from '../components/app/Assets/AssetDashboard';
import AddAsset from '../components/app/Assets/AddAsset';
import UpdateAsset from '../components/app/Assets/UpdateAsset';
import AssetIssues from '../components/app/Assets/AssetIssues';
import AssetStatisticsCards from '../components/app/Assets/AssetStatisticsCards';
import * as XLSX from 'xlsx';
import AssetWorth from '../components/app/Assets/AssetWorth';
import TeamDashboard from '../components/app/Assets/Team/TeamDashboard';

const StyledTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '14px',
  '&.Mui-selected': {
    color: '#1890ff',
    fontWeight: 600,
  },
});

const AssetManagement: React.FC = () => {
  const [selectedSection, setSelectedSection] = useState<'assets' | 'history' | 'team'>('assets');
  const [tabValue, setTabValue] = useState(0);
  const [assetData, setAssetData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useSelector(selectUserData, shallowEqual);

  useEffect(() => {
    fetchAllAssets.get()
      .then((res) => setAssetData(res.data))
      .catch((error) => console.log("error", error))
      .finally(() => setIsLoading(false));
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const exportToExcel = () => {
    const excelData = assetData.map((asset: any) => ({
      'Asset ID': asset.asset_id,
      'Asset Name': asset.name,
      'Location': asset.location,
      'Purchase Date': asset.purchase_date,
      'Purchase Cost': asset.purchase_cost,
      'Current Value': asset.current_value,
      'Status': asset.status,
      'Department': asset.department?.department_name || 'N/A',
    }));

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Assets");
    XLSX.writeFile(wb, `Assets_${new Date().toISOString().split('T')[0]}.xlsx`);
  };

  const renderContent = () => {
    switch (selectedSection) {
      case 'assets':
        return (
          <>
            <StyledTabs value={tabValue} onChange={handleTabChange}>
              <StyledTab label="Dashboard" />
              <StyledTab label="Add Asset" />
              <StyledTab label="Update Asset" />
              <StyledTab label="Asset Worth" />
            </StyledTabs>
            <Box sx={{ mt: 3 }}>
              {tabValue === 0 && <AssetDashboard assetData={assetData} />}
              {tabValue === 1 && <AddAsset />}
              {tabValue === 2 && <UpdateAsset assetData={assetData} />}
              {tabValue === 3 && <AssetWorth assetData={assetData} />}
            </Box>
          </>
        );
      case 'history':
        return <AssetIssues assetData={assetData}/>;
      case 'team':
        return <TeamDashboard />;
      default:
        return null;
    }
  };

  return (
    <div className="flex">
      {/* Sidebar */}
      <div className="bg-blue-900 text-white text-sm h-[100vh] w-48 flex flex-col text-left pt-4">
        <div className="space-y-1">
          <div
            className={`flex items-center gap-2 px-4 py-3 cursor-pointer ${
              selectedSection === 'assets' ? 'bg-blue-800 font-medium' : 'hover:bg-blue-800'
            }`}
            onClick={() => {
              setSelectedSection('assets');
              setTabValue(0);
            }}
          >
            <AssetIcon />
            <span>Assets</span>
          </div>
          <div
            className={`flex items-center gap-2 px-4 py-3 cursor-pointer ${
              selectedSection === 'history' ? 'bg-blue-800 font-medium' : 'hover:bg-blue-800'
            }`}
            onClick={() => {
              setSelectedSection('history');
              setTabValue(0);
            }}
          >
            <IssueIcon />
            <span>Issues</span>
          </div>
          <div
            className={`flex items-center gap-2 px-4 py-3 cursor-pointer ${
              selectedSection === 'team' ? 'bg-blue-800 font-medium' : 'hover:bg-blue-800'
            }`}
            onClick={() => {
              setSelectedSection('team');
              setTabValue(0);
            }}
          >
            <TeamIcon />
            <span>Members</span>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-6 h-[100vh]">
        <div className="flex justify-between items-center mb-6">
          <Box>
            <Typography variant="h4" component="h3" className="text-gray-800 font-md">
              {selectedSection === 'assets' ? 'Asset Management' : 'Asset Issues'}
            </Typography>
            <Typography variant="body1" className="text-gray-600 mt-2">
              {selectedSection === 'assets' 
                ? 'Manage your assets, track depreciation, and maintain asset inventory'
                : 'Track all issues reported and resolved for assets'
              }
            </Typography>
          </Box>
          {selectedSection === 'assets' && (
            <Tooltip title="Export to Excel">
              <IconButton 
                onClick={exportToExcel}
                className="bg-blue-500 hover:bg-blue-600 text-white"
                size="large"
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            {/* {selectedSection === 'assets' && <AssetStatisticsCards assetData={assetData} />} */}
            {renderContent()}
          </>
        )}
      </div>
    </div>
  );
};

export default AssetManagement;
