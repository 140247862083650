import React, { useState, useEffect, useCallback } from 'react';
import {
    Paper,
    Typography,
    Autocomplete,
    TextField,
    Button,
    Box
} from '@mui/material';
// import { getReportedIssues, resolveIssue } from '../../../api/endpoints/APIEndpoints';
import { toast } from 'react-toastify';
import { fetchDocumentIssues, resolveDocumentIssue } from '../../../api/endpoints/APIEndpoints';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../../../store/store/authSlice';

interface ResolveIssueProps { }

const ResolveIssue: React.FC<ResolveIssueProps> = () => {
    const [issues, setIssues] = useState([]);
    const [selectedIssue, setSelectedIssue] = useState<any>(null);
    const [resolution, setResolution] = useState('');
    const userDetails = useSelector(selectUserData, shallowEqual);

    useEffect(() => {
        fetchDocumentIssues.post({ employee_id: userDetails?.employee_id })
            .then((res) => {
                setIssues(Array.isArray(res.issues) ? res.issues : []);
            })
            .catch((error) => {
                console.log("ERROR!!", error);
                setIssues([]);
            })
    }, []);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!selectedIssue) return;

        resolveDocumentIssue.post({
            issue_id: selectedIssue.issue_id,
            resolution: resolution
        })
            .then((res: { data: React.SetStateAction<never[]>; }) => {
                toast.success('Issue resolved successfully');
                setSelectedIssue(null);
                setResolution('');
                // Refresh issues list
                fetchDocumentIssues.post({ employee_id: userDetails?.employee_id }).then((res) => {
                    setIssues(Array.isArray(res.issues) ? res.issues : []);
                })
            })
            .catch((err: any) => {
                console.error(err);
                toast.error('Failed to resolve issue');
            });
    };

    return (
        <Paper className="p-8 max-w-3xl mx-auto mt-8">
            <Typography variant="h5" className="mb-6 text-gray-800 font-medium">
                Resolve Issue
            </Typography>

            <form onSubmit={handleSubmit}>
                <Box className="space-y-6">
                    <Autocomplete
                        options={issues}
                        getOptionLabel={(option) =>
                            `${option.document.document_name} - ${option.type} (${option.issue_id})`
                        }
                        renderInput={(params) =>
                            <TextField {...params} label="Select Issue" required />
                        }
                        value={selectedIssue}
                        onChange={(_, newValue) => setSelectedIssue(newValue)}
                        noOptionsText="No pending issues"
                    />

                    {selectedIssue && (
                        <TextField
                            fullWidth
                            label="Resolution"
                            required
                            multiline
                            rows={4}
                            value={resolution}
                            onChange={(e) => setResolution(e.target.value)}
                        />
                    )}

                    <Box className="flex justify-end">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={!selectedIssue || !resolution}
                        >
                            Resolve Issue
                        </Button>
                    </Box>
                </Box>
            </form>
        </Paper>
    );
};

export default ResolveIssue;
