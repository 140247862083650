import React, { useEffect, useState } from 'react';
import { completedEmployeeInspection, teamInspectionStats } from '../../api/endpoints/APIEndpoints';
import Modal from '../common/Modal';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../../store/store/authSlice';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loader from '../common/Loader';  // Import the Loader component
import NewModal from '../common/newModal';

interface TeamMember {
    enrollmentNumber: number;
    employeeName: string;
    inspectionsConducted: number;
    inspectionsScheduled: number;
    followUpsTaken: number;
    inspectionsDoneBeforeDueDate: number;
    inspectionsDoneAfterDueDate: number;
}

interface TeamData {
    teamName: string;
    employeeStats: TeamMember[];
}

type SortableField = keyof TeamMember;

type SortOrder = 'asc' | 'desc';

interface SortConfig {
    field: SortableField;
    order: SortOrder;
}

interface TeamPerformanceTableProps {
    // Add any props if needed
}

const TeamPerformanceTable: React.FC<TeamPerformanceTableProps> = () => {
    const [teamsData, setTeamsData] = useState<TeamData[]>([]);
    const [sortConfig, setSortConfig] = useState<SortConfig>({ field: 'employeeName', order: 'asc' });
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [inspectionDetails, setInspectionDetails] = useState<any>([]);
    const [expandedTeam, setExpandedTeam] = useState<string | false>(false);
    const userData = useSelector(selectUserData, shallowEqual);
    const [isLoading, setIsLoading] = useState(false);  // Add this line

    useEffect(() => {
        if (userData?.teams && userData.teams.length > 0) {
            const fetchTeamData = async () => {
                setIsLoading(true);  // Set loading to true when starting to fetch data
                try {
                    const teamDataPromises = userData.teams.map(team =>
                        teamInspectionStats.post({ teamId: team.team_id })
                            .then((res: TeamData) => res)
                            .catch(err => {
                                console.error(`Error fetching team inspection stats for team ${team.team_id}:`, err);
                                return null;
                            })
                    );

                    const results = await Promise.all(teamDataPromises);
                    const validResults = results.filter((result): result is TeamData => result !== null);
                    setTeamsData(validResults);
                    if (validResults.length > 0) {
                        setExpandedTeam(validResults[0].teamName);
                    }
                } catch (error) {
                    console.error("Error fetching team data:", error);
                } finally {
                    setIsLoading(false);  // Set loading to false after data is fetched (or if an error occurred)
                }
            };

            fetchTeamData();
        }
    }, [userData?.teams]);

    const handleSort = (field: SortableField) => {
        setSortConfig((prevConfig) => ({
            field,
            order: prevConfig.field === field && prevConfig.order === 'asc' ? 'desc' : 'asc',
        }));

        if (teamsData) {
            const sortedData = [...teamsData].map((teamData) => teamData.employeeStats).flat().sort((a, b) => {
                if (a[field] < b[field]) return sortConfig.order === 'asc' ? -1 : 1;
                if (a[field] > b[field]) return sortConfig.order === 'asc' ? 1 : -1;
                return 0;
            });

            setTeamsData(teamsData.map((teamData) => ({ ...teamData, employeeStats: sortedData })));
        }
    };

    const renderSortIndicator = (field: SortableField) => {
        if (sortConfig.field === field) {
            return <span className="ml-1">{sortConfig.order === 'asc' ? '▲' : '▼'}</span>;
        }
        return null;
    };

    const handleInspectionConductedModal = (member: TeamMember) => {
        completedEmployeeInspection.post({ employeeId: member?.enrollmentNumber })
            .then((res) => {
                setInspectionDetails(res)
                setShowInfoModal(true)
            }).catch((err) => console.log("ERROR!!", err))
    }

    const handleAccordionChange = (teamName: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedTeam(isExpanded ? teamName : false);
    };

    if (isLoading) {
        return <Loader />;  // Show the loader while data is being fetched
    }

    return (
        <div className="overflow-x-auto">
            {teamsData.length === 0 ? (
                <p>No team data available.</p>
            ) : (
                teamsData.map((teamData, index) => (
                    <Accordion
                        key={teamData.teamName}
                        expanded={expandedTeam === teamData.teamName}
                        onChange={handleAccordionChange(teamData.teamName)}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                            sx={{
                                backgroundColor: '#f0f0f0', // Light gray background
                                '&:hover': {
                                    backgroundColor: '#e0e0e0', // Slightly darker on hover
                                },
                            }}>
                            <Typography className="text-xl font-bold">{teamData.teamName} Inspection Statistics</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <table className="min-w-full bg-white border border-gray-300">
                                <thead className="bg-gray-100">
                                    <tr>
                                        {(['employeeName', 'enrollmentNumber', 'inspectionsConducted', 'inspectionsScheduled', 'followUpsTaken', 'inspectionsDoneBeforeDueDate', 'inspectionsDoneAfterDueDate'] as const).map((field) => (
                                            <th
                                                key={field}
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                onClick={() => handleSort(field)}
                                            >
                                                {field.replace(/([A-Z])/g, ' $1').trim()}
                                                {renderSortIndicator(field)}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-300">
                                    {teamData.employeeStats.map((member) => (
                                        <tr key={member.enrollmentNumber} className="hover:bg-gray-50">
                                            <td className="px-6 py-4 whitespace-nowrap">{member.employeeName}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{member.enrollmentNumber}</td>
                                            <td className="px-6 py-4 whitespace-nowrap cursor-pointer" onClick={() => handleInspectionConductedModal(member)}>{member.inspectionsConducted}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{member.inspectionsScheduled}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{member.followUpsTaken}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{member.inspectionsDoneBeforeDueDate}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{member.inspectionsDoneAfterDueDate}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </AccordionDetails>
                    </Accordion>
                ))
            )}

            <NewModal isOpen={showInfoModal} onClose={() => setShowInfoModal(false)} title='Tags Inspected:'>
                <>
                    {
                        inspectionDetails?.completedTagInspections?.map((item: { tag_id: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) => <p>{item?.tag_id}</p>)
                    }
                </>
            </NewModal>
        </div>
    );
};

export default TeamPerformanceTable;
