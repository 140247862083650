import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './SideBar';

const InspectionLayout: React.FC = () => {
  return (
    <div className='flex'>
      <Sidebar />
      <div className="main-content w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default InspectionLayout;