import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Autocomplete, TextField, Button } from '@mui/material';
import { useSelector, shallowEqual } from 'react-redux';
import { selectUserData } from '../../../store/store/authSlice';
import { resolveAssetIssue } from '../../../api/endpoints/APIEndpoints';
import { convertToBase64 } from '../../../utils/common';
import imageCompression from 'browser-image-compression';
import Modal from '../../common/Modal';
import Textarea from '../../common/TextArea';

interface ResolveAssetIssueProps {
  onSuccess: () => void;
  issues: any[];
}

interface FormData {
  issueId: string;
  comments: string;
}

const ResolveAssetIssue: React.FC<ResolveAssetIssueProps> = ({ onSuccess, issues }) => {
  const [selectedIssue, setSelectedIssue] = useState<any | null>(null);
  const [imageBase64, setImageBase64] = useState<string | null>(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [resolvedDetails, setResolvedDetails] = useState<any>(null);
  const userDetails = useSelector(selectUserData, shallowEqual);

  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      issueId: '',
      comments: '',
    }
  });

  const compressImage = async (file: File): Promise<File> => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };

    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error("Error compressing image:", error);
      return file;
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const compressedFile = await compressImage(file);
      const base64 = await convertToBase64(compressedFile);
      setImageBase64(base64);
    }
  };

  const onFormSubmit = handleSubmit(async (data) => {
    const payload = {
      ...data,
      issueId: selectedIssue.issue_id,
      resolution_evidence: imageBase64,
      resolved_by: userDetails?.employee_id,
      resolved_date: new Date().toISOString().split('T')[0]
    };

    try {
      await resolveAssetIssue.post(payload);
      setResolvedDetails(payload);
      setSuccessModalOpen(true);
    } catch (error) {
      console.error("Error resolving asset issue:", error);
    }
  });

  return (
    <div className="bg-white p-6 rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Resolve Asset Issue</h2>
      <form onSubmit={onFormSubmit} className='space-y-4'>
        <Autocomplete
          options={issues}
          getOptionLabel={(option) => 
            `${option.asset.asset_name} (${option.issue_id} - ${option.type})`
          }
          renderInput={(params) => <TextField {...params} label="Select Issue" />}
          value={selectedIssue}
          onChange={(_, newValue) => setSelectedIssue(newValue)}
          noOptionsText="All issues are resolved"
        />

        <Controller
          name="comments"
          control={control}
          render={({ field }) => <Textarea label="Resolution Comments" {...field} />}
        />

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Resolution Evidence
          </label>
          <input
            type="file"
            onChange={handleFileChange}
            accept="image/*"
            className="mt-1 block w-full text-sm text-gray-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-blue-50 file:text-blue-700
              hover:file:bg-blue-100"
          />
        </div>

        <div className="flex justify-end">
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
            Resolve Issue
          </button>
        </div>
      </form>

      {/* Success Modal */}
      <Modal
        show={successModalOpen}
        onClose={() => setSuccessModalOpen(false)}
      >
        <div className="p-6 bg-white rounded-lg">
          <img
            src="/success.png"
            alt="Success"
            className="mx-auto mb-6 w-24 h-24"
          />
          <h3 className="mb-4 text-2xl font-semibold text-green-600">
            Asset Issue Resolved Successfully!
          </h3>
          <div className="mb-6 text-left bg-gray-50 p-4 rounded-md">
            <p className="mb-2">
              <span className="font-semibold">Issue ID:</span> {resolvedDetails?.issueId}
            </p>
            <p className="mb-2">
              <span className="font-semibold">Resolved By:</span> {userDetails?.employee_id}
            </p>
            <p className="mb-2">
              <span className="font-semibold">Resolve Date:</span> {resolvedDetails?.resolved_date}
            </p>
            <p className="mb-2">
              <span className="font-semibold">Comments:</span> {resolvedDetails?.comments}
            </p>
            {resolvedDetails?.resolution_evidence && (
              <p className="mb-2">
                <span className="font-semibold">Resolution Evidence:</span>
                <img 
                  src={resolvedDetails.resolution_evidence} 
                  alt="Evidence" 
                  className="mt-2 w-32 h-32 object-cover" 
                />
              </p>
            )}
          </div>
          <button
            type="button"
            className="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center transition duration-300 ease-in-out transform hover:scale-105"
            onClick={() => {
              setSuccessModalOpen(false);
              onSuccess();
            }}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ResolveAssetIssue; 