import React, { useState } from 'react';
import { CheckCircle, Calendar, Clock, TrendingUp } from 'lucide-react';

interface Banner {
    title: string;
    count: number;
}

const Banner: React.FC<Banner> = ({ title, count }) => {

    return (
        <div className="rounded-lg backdrop-blur-lg text-left">
            <div className="bg-[#5896e1] bg-opacity-20 p-4 rounded-lg backdrop-blur-lg border">
                <div className="flex items-center justify-between">
                    <div>
                        <p className="text-lg font-bold">{count || "--"}</p>
                        <p className="text-sm uppercase">{title}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;

