import React, { useState, useEffect, FormEvent } from 'react';
import { TextField, MenuItem, Button, Grid, Paper, FormControlLabel, Checkbox, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Modal from '../../common/Modal';
import dayjs from 'dayjs';
import { addAsset, fetchAllChecklist, fetchRoutines, getAllDepartments, getAllProjects, fetchAllAssets } from '../../../api/endpoints/APIEndpoints';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../../../store/store/authSlice';
import EXIF from 'exif-js';
import imageCompression from 'browser-image-compression';
import { ThreeDots } from 'react-loader-spinner';
import Multiselect from 'multiselect-react-dropdown';
import { Autocomplete } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
// import { toast } from 'react-hot-toast';

interface AddAssetProps {
    onClose?: () => void;
    isEditMode?: boolean;
    editData?: any;
}

interface DepartmentData {
    id: string;
    isInspection: boolean;
    isMaintenance: boolean;
    InspectionData: {
        checklist: string;
        routine: string;
        frequency: string;
        dueDateOffset: string;
    };
    MaintenanceData: {
        checklist: string;
        routine: string;
        frequency: string;
        dueDateOffset: string;
    };
}

type TaskType = 'Inspection' | 'Maintenance';

const AccordionHeader = styled(AccordionSummary)({
    backgroundColor: '#f8fafc', // Light blue-gray background
    '& .MuiTypography-h6': {
        color: '#334155', // Slate-700 for text
        fontSize: '1.1rem',
        fontWeight: 600
    },
    '&:hover': {
        backgroundColor: '#f1f5f9' // Slightly darker on hover
    }
});

const ScrollableContainer = styled('div')({
    maxHeight: 'calc(100vh - 200px)', // Adjust this value based on your needs
    overflowY: 'auto',
    padding: '20px',
    '&::-webkit-scrollbar': {
        width: '8px',
    },
    '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '4px',
        '&:hover': {
            background: '#555',
        },
    },
});

const AddAsset: React.FC<AddAssetProps> = ({ onClose, isEditMode = false, editData }) => {
    const [formData, setFormData] = useState({
        asset_name: editData?.asset_name || '',
        description: editData?.description || '',
        status: editData?.status || '',
        location: editData?.location || '',
        manufacturer: editData?.manufacturer || '',
        serial_number: editData?.serial_number || '',
        model: editData?.model || '',
        purchase_date: editData?.purchase_date ? dayjs(editData.purchase_date) : null,
        purchase_cost: editData?.purchase_cost || '',
        depreciation_rate: editData?.depreciation_rate || '',
        inactive_date: editData?.inactive_date ? dayjs(editData.inactive_date) : null,
        reminder_frequency: editData?.reminder_frequency || '',
        department_id: editData?.department_id || '',
        photo_url: editData?.photo_url || '',
        purchase_invoice_url: '',
        parent_asset_id: '',
        maintenance_history: {
            amount: '',
            invoice: '',
            date: new Date(),
            notes: ''
        }
    });

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [modalDetails, setModalDetails] = useState<any>(null);
    const [allDepartments, setAllDepartments] = useState<any>([]);
    const userData = useSelector(selectUserData, shallowEqual);
    const [image, setImage] = useState<File | null>(null);
    const [imageBase64, setImageBase64] = useState<string | null>(null);
    const [geolocationCoordinates, setGeolocationCoordinates] = useState<any>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedDepartments, setSelectedDepartments] = useState<any[]>([]);
    const [selectedProjects, setSelectedProjects] = useState<any[]>([]);
    const [departmentData, setDepartmentData] = useState<{
        [departmentName: string]: DepartmentData
    }>({});
    const [projectOptions, setProjectOptions] = useState<any[]>([]);
    const [allChecklistData, setAllChecklistData] = useState<any[]>([]);
    const [routineOptions, setRoutineOptions] = useState<any[]>([]);
    const frequencyOptions = [
        { value: "Weekly", label: "Weekly" },
        { value: "Fortnightly", label: "Fortnightly" },
        { value: "Monthly", label: "Monthly" },
        { value: "Quarterly", label: "Quarterly" },
        { value: "Half-Yearly", label: "Half-Yearly" },
        { value: "Yearly", label: "Yearly" }
    ];

    const dueDateOffsetOptions = [
        { value: "7", label: "7 days from scheduled inspection date" },
        { value: "15", label: "15 days from scheduled inspection date" }
    ];

    const [isAppliance, setIsAppliance] = useState(false);
    const [purchaseInvoice, setPurchaseInvoice] = useState<File | null>(null);
    const [maintenanceInvoice, setMaintenanceInvoice] = useState<File | null>(null);
    const [parentAssetOptions, setParentAssetOptions] = useState<any[]>([]);
    const [selectedParentAsset, setSelectedParentAsset] = useState<any>(null);

    useEffect(() => {
        getAllDepartments.get()
            .then((res) => {
                let formattedDepartmentData = res?.map((department: {
                    department_id: any;
                    department_name: any;
                }) => {
                    if (userData?.role?.level === 4) {
                        return {
                            value: department?.department_id,
                            label: department?.department_name
                        };
                    }
                    if (userData?.role?.level === 2 || userData?.role?.level === 3) {
                        const hasAccess = userData.departments.some(
                            (userDept: any) => userDept.department_id === department.department_id
                        );
                        if (hasAccess) {
                            return {
                                value: department?.department_id,
                                label: department?.department_name
                            };
                        }
                    }
                    return null;
                }).filter(Boolean);
                setAllDepartments(formattedDepartmentData);
            })
            .catch((err) => console.log("ERROR!!", err));

        getAllProjects.post({ employee_id: userData?.employee_id })
            .then((res) => {
                setProjectOptions(res?.map((item: any) => ({
                    label: item.project_name,
                    value: item.project_id.toString(),
                })) || []);
            })
            .catch((err) => console.log("ERROR!!", err));

        fetchAllChecklist.post({})
            .then((res) => {
                setAllChecklistData(res?.map((item: any) => ({
                    label: item.name,
                    value: item.checklist_id.toString(),
                    checklist_type: item.checklist_type,
                    department_id: item.department?.department_id
                })) || []);
            })
            .catch((err) => console.log("ERROR!!", err));

        fetchRoutines.get()
            .then((res) => {
                setRoutineOptions(res);
            })
            .catch((err) => console.log("ERROR!!", err));

        fetchAllAssets.get({})
            .then((res) => {
                const filteredAssets = isEditMode 
                    ? res.data.filter((asset: any) => asset.asset_id !== editData?.asset_id)
                    : res.data;
                
                setParentAssetOptions(filteredAssets.map((asset: any) => ({
                    label: asset.asset_name,
                    value: asset.asset_id.toString()
                })));
            })
            .catch((err) => console.log("Error fetching parent assets:", err));
    }, [userData, isEditMode, editData?.asset_id]);

    const getFilteredChecklistOptions = (type: TaskType, deptInfo: any) => {
        return allChecklistData.filter((checklist: any) => checklist.checklist_type === type && deptInfo.id === checklist.department_id);
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);

        const formattedDepartments = selectedDepartments.map(dept => ({
            department_id: parseInt(dept.id),
            inspection_routine_id: departmentData[dept.name].isInspection
                ? parseInt(departmentData[dept.name].InspectionData.routine)
                : null,
            maintenance_routine_id: departmentData[dept.name].isMaintenance
                ? parseInt(departmentData[dept.name].MaintenanceData.routine)
                : null,
            inspection_checklist_id: departmentData[dept.name].isInspection
                ? departmentData[dept.name].InspectionData.checklist
                : null,
            maintenance_checklist_id: departmentData[dept.name].isMaintenance
                ? departmentData[dept.name].MaintenanceData.checklist
                : null
        }));

        const convertFileToBase64 = (file: File): Promise<string> => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = error => reject(error);
            });
        };

        const purchaseInvoiceBase64 = purchaseInvoice ? await convertFileToBase64(purchaseInvoice) : null;
        const maintenanceInvoiceBase64 = maintenanceInvoice ? await convertFileToBase64(maintenanceInvoice) : null;

        const payload = {
            asset_name: formData.asset_name,
            description: formData.description,
            status: formData.status,
            location: formData.location,
            manufacturer: formData.manufacturer,
            serial_number: formData.serial_number,
            model: formData.model,
            purchase_date: formData.purchase_date?.format('YYYY-MM-DD'),
            purchase_cost: formData.purchase_cost,
            depreciation_rate: formData.depreciation_rate,
            inactive_date: formData.inactive_date?.format('YYYY-MM-DD'),
            reminder_frequency: formData.reminder_frequency,
            department_id: formData.department_id,
            photo_url: imageBase64 || '',
            geolocation: geolocationCoordinates || null,
            is_appliance: isAppliance,
            departments: formattedDepartments,
            projects: selectedProjects.map(project => ({ project_id: project.id })),
            parent_asset_id: formData.parent_asset_id || null,
            purchase_invoice: {
                file: purchaseInvoiceBase64,
                filename: purchaseInvoice?.name
            },
            maintenance_history: {
                amount: formData.maintenance_history.amount,
                date: dayjs(formData.maintenance_history.date).format('YYYY-MM-DD'),
                notes: formData.maintenance_history.notes,
                invoice: {
                    file: maintenanceInvoiceBase64,
                    filename: maintenanceInvoice?.name
                }
            }
        };

        try {
            addAsset.post(payload)
                .then((res) => {
                    setModalDetails({
                        asset_name: res.data.asset_name,
                        status: res.data.status,
                        serial_number: res.data.serial_number,
                        purchase_date: res.data.purchase_date ? dayjs(res.data.purchase_date) : null,
                        purchase_cost: res.data.purchase_cost,
                        department: res.data.department_name,
                        location: res.data.location
                    });
                    setShowSuccessModal(true);
                    if (!isEditMode) {
                        setFormData({
                            asset_name: '',
                            description: '',
                            status: '',
                            location: '',
                            manufacturer: '',
                            serial_number: '',
                            model: '',
                            purchase_date: null,
                            purchase_cost: '',
                            depreciation_rate: '',
                            inactive_date: null,
                            reminder_frequency: '',
                            department_id: '',
                            photo_url: '',
                            purchase_invoice_url: '',
                            parent_asset_id: '',
                            maintenance_history: {
                                amount: '',
                                invoice: '',
                                date: new Date(),
                                notes: ''
                            }
                        });
                        setImage(null);
                        setImageBase64(null);
                        setGeolocationCoordinates(null);
                    }

                    if (onClose) {
                        onClose();
                    }
                })
                .catch((err) => {
                    console.error("Error adding asset:", err);
                    // toast.error('Failed to add asset. Please try again.');
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        } catch (error) {
            console.error("Error submitting form:", error);
            setIsSubmitting(false);
        }
    };

    const compressImage = async (file: File): Promise<File> => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        try {
            return await imageCompression(file, options);
        } catch (error) {
            console.error("Error compressing image:", error);
            return file;
        }
    };

    const convertToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    };

    const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const compressedFile = await compressImage(file);
            setImage(compressedFile);
            const base64 = await convertToBase64(compressedFile);
            setImageBase64(base64);
            setFormData({ ...formData, photo_url: base64 });
        }
    };

    const onDepartmentSelect = (selectedList: any[], selectedItem: any) => {
        setSelectedDepartments(selectedList);
        setDepartmentData(prevData => ({
            ...prevData,
            [selectedItem.name]: {
                id: selectedItem.id,
                isInspection: false,
                isMaintenance: false,
                InspectionData: { checklist: '', routine: '', frequency: '', dueDateOffset: '' },
                MaintenanceData: { checklist: '', routine: '', frequency: '', dueDateOffset: '' }
            }
        }));
    };

    const onDepartmentRemove = (selectedList: any[], removedItem: any) => {
        setSelectedDepartments(selectedList);
        setDepartmentData(prevData => {
            const { [removedItem.name]: _, ...rest } = prevData;
            return rest;
        });
    };

    const handleCheckboxChange = (deptName: string, type: 'isInspection' | 'isMaintenance', value: boolean) => {
        setDepartmentData(prevData => ({
            ...prevData,
            [deptName]: {
                ...prevData[deptName],
                [type]: value
            }
        }));
    };

    const handleDataChange = (deptName: string, type: 'Inspection' | 'Maintenance', field: string, value: any) => {
        setDepartmentData(prevData => ({
            ...prevData,
            [deptName]: {
                ...prevData[deptName],
                [`${type}Data`]: {
                    ...prevData[deptName][`${type}Data`],
                    [field]: value
                }
            }
        }));
    };

    const routineOptionsFormatted = routineOptions?.map((item) => ({
        label: item.routine_name,
        value: item.routine_id.toString(),
    })) || []

    const renderDetailsSection = (deptName: string, type: 'Inspection' | 'Maintenance', deptInfo: any) => {
        const data = departmentData[deptName][`${type}Data`];
        const title = `${type} Details - ${deptName}`;

        return (
            <div className="mt-4 p-4 border rounded">
                <h3 className="text-lg font-semibold mb-3">{title}</h3>
                <div className="space-y-4">
                    <TextField
                        fullWidth
                        select
                        label="Checklist"
                        value={data.checklist}
                        onChange={(e) => handleDataChange(deptName, type, 'checklist', e.target.value)}
                        required
                    >
                        {/* Add your checklist options here */}
                        {getFilteredChecklistOptions(type, deptInfo).map((option: any) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        fullWidth
                        select
                        label="Routine"
                        value={data.routine}
                        onChange={(e) => handleDataChange(deptName, type, 'routine', e.target.value)}
                        required
                    >
                        {/* Add your routine options here */}
                        {routineOptionsFormatted.map((option: any) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        fullWidth
                        select
                        label="Frequency"
                        value={data.frequency}
                        onChange={(e) => handleDataChange(deptName, type, 'frequency', e.target.value)}
                        required
                    >
                        {frequencyOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        fullWidth
                        select
                        label="Due Date Offset"
                        value={data.dueDateOffset}
                        onChange={(e) => handleDataChange(deptName, type, 'dueDateOffset', e.target.value)}
                        required
                    >
                        {dueDateOffsetOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </div>
        );
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'purchase' | 'maintenance') => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            if (type === 'purchase') {
                setPurchaseInvoice(file);
            } else {
                setMaintenanceInvoice(file);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <ScrollableContainer>
                <div className="space-y-4">
                    <Accordion defaultExpanded>
                        <AccordionHeader expandIcon={<ExpandMoreIcon sx={{ color: '#64748b' }} />}>
                            <Typography variant="h6">Basic Information</Typography>
                        </AccordionHeader>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Asset Name"
                                        value={formData.asset_name}
                                        onChange={(e) => setFormData({ ...formData, asset_name: e.target.value })}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Status"
                                        value={formData.status}
                                        onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                                        required
                                    >
                                        <MenuItem value="active">Active</MenuItem>
                                        <MenuItem value="inactive">Inactive</MenuItem>
                                        <MenuItem value="maintenance">Maintenance</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Manufacturer"
                                        value={formData.manufacturer}
                                        onChange={(e) => setFormData({ ...formData, manufacturer: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Serial Number"
                                        value={formData.serial_number}
                                        onChange={(e) => setFormData({ ...formData, serial_number: e.target.value })}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Model"
                                        value={formData.model}
                                        onChange={(e) => setFormData({ ...formData, model: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Purchase Date"
                                            value={formData.purchase_date}
                                            onChange={(newValue) => setFormData({ ...formData, purchase_date: newValue })}
                                            slotProps={{ textField: { fullWidth: true } }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Depreciation Rate (%)"
                                        value={formData.depreciation_rate}
                                        onChange={(e) => setFormData({ ...formData, depreciation_rate: e.target.value })}
                                        required
                                        helperText="Applicable for 1 year"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Inactive Date"
                                            value={formData.inactive_date}
                                            onChange={(newValue) => setFormData({ ...formData, inactive_date: newValue })}
                                            slotProps={{ textField: { fullWidth: true } }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Reminder Frequency"
                                        value={formData.reminder_frequency}
                                        onChange={(e) => setFormData({ ...formData, reminder_frequency: e.target.value })}
                                        required
                                    >
                                        {frequencyOptions.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={3}
                                        label="Description"
                                        value={formData.description}
                                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Location"
                                        value={formData.location}
                                        onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="flex flex-col gap-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Asset Image
                                        </label>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded>
                        <AccordionHeader expandIcon={<ExpandMoreIcon sx={{ color: '#64748b' }} />}>
                            <Typography variant="h6">Departments & Projects</Typography>
                        </AccordionHeader>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div className="mb-4">
                                        <p className="text-sm font-medium text-gray-700 mb-2">Projects:</p>
                                        <Multiselect
                                            options={projectOptions.map(option => ({
                                                id: option.value,
                                                name: option.label
                                            }))}
                                            selectedValues={selectedProjects}
                                            onSelect={(selectedList) => setSelectedProjects(selectedList)}
                                            onRemove={(selectedList) => setSelectedProjects(selectedList)}
                                            displayValue="name"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <p className="text-sm font-medium text-gray-700 mb-2">Departments:</p>
                                        <Multiselect
                                            options={allDepartments.map((dept: any) => ({
                                                id: dept.value,
                                                name: dept.label
                                            }))}
                                            selectedValues={selectedDepartments}
                                            onSelect={onDepartmentSelect}
                                            onRemove={onDepartmentRemove}
                                            displayValue="name"
                                        />
                                    </div>

                                    {Object.entries(departmentData).map(([deptName, deptInfo]) => (
                                        <div key={deptInfo.id} className="mt-4 p-4 border rounded">
                                            <h3 className="text-lg font-semibold mb-3">Department: {deptName}</h3>
                                            <div className="flex space-x-4 mb-4">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={deptInfo.isInspection}
                                                            onChange={(e) => handleCheckboxChange(deptName, 'isInspection', e.target.checked)}
                                                        />
                                                    }
                                                    label="Inspection"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={deptInfo.isMaintenance}
                                                            onChange={(e) => handleCheckboxChange(deptName, 'isMaintenance', e.target.checked)}
                                                        />
                                                    }
                                                    label="Maintenance"
                                                />
                                            </div>
                                            {deptInfo.isInspection && renderDetailsSection(deptName, 'Inspection', deptInfo)}
                                            {deptInfo.isMaintenance && renderDetailsSection(deptName, 'Maintenance', deptInfo)}
                                        </div>
                                    ))}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded>
                        <AccordionHeader expandIcon={<ExpandMoreIcon sx={{ color: '#64748b' }} />}>
                            <Typography variant="h6">Purchase & Cost Information</Typography>
                        </AccordionHeader>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <div className="flex flex-col gap-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Purchase Invoice
                                        </label>
                                        <input
                                            type="file"
                                            accept=".pdf"
                                            onChange={(e) => handleFileChange(e, 'purchase')}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Purchase Cost"
                                        value={formData.purchase_cost}
                                        onChange={(e) => setFormData({ ...formData, purchase_cost: e.target.value })}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded>
                        <AccordionHeader expandIcon={<ExpandMoreIcon sx={{ color: '#64748b' }} />}>
                            <Typography variant="h6">Maintenance Information</Typography>
                        </AccordionHeader>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Maintenance Amount"
                                        value={formData.maintenance_history.amount}
                                        onChange={(e) => setFormData({
                                            ...formData,
                                            maintenance_history: {
                                                ...formData.maintenance_history,
                                                amount: e.target.value
                                            }
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Maintenance Date"
                                            value={dayjs(formData.maintenance_history.date).isValid() ? dayjs(formData.maintenance_history.date) : null}
                                            onChange={(newValue) => setFormData({
                                                ...formData,
                                                maintenance_history: {
                                                    ...formData.maintenance_history,
                                                    date: newValue ? newValue.toDate() : new Date()
                                                }
                                            })}
                                            slotProps={{ textField: { fullWidth: true } }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="flex flex-col gap-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Maintenance Invoice
                                        </label>
                                        <input
                                            type="file"
                                            accept=".pdf"
                                            onChange={(e) => handleFileChange(e, 'maintenance')}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={3}
                                        label="Maintenance Notes"
                                        value={formData.maintenance_history.notes}
                                        onChange={(e) => setFormData({
                                            ...formData,
                                            maintenance_history: {
                                                ...formData.maintenance_history,
                                                notes: e.target.value
                                            }
                                        })}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded>
                        <AccordionHeader expandIcon={<ExpandMoreIcon sx={{ color: '#64748b' }} />}>
                            <Typography variant="h6">Asset Relationships</Typography>
                        </AccordionHeader>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={parentAssetOptions}
                                        getOptionLabel={(option) => option.label}
                                        renderOption={(props, option) => (
                                            <li {...props} style={{ fontSize: '14px' }}>
                                                {option.label}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Parent Asset"
                                                value={selectedParentAsset ? selectedParentAsset.label : ''}
                                            />
                                        )}
                                        value={selectedParentAsset}
                                        onChange={(_, newValue) => {
                                            setSelectedParentAsset(newValue);
                                            setFormData({
                                                ...formData,
                                                parent_asset_id: newValue?.value || ''
                                            });
                                        }}
                                        isOptionEqualToValue={(option, value) => option.value === value?.value}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className="mt-6 flex justify-end gap-3 sticky bottom-0 bg-white py-4 border-t">
                    {onClose && (
                        <Button variant="outlined" onClick={onClose}>
                            Cancel
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                        className="min-w-[120px]"
                    >
                        {isSubmitting ? (
                            <ThreeDots color="#ffffff" height={24} width={24} />
                        ) : (
                            isEditMode ? 'Update Asset' : 'Add Asset'
                        )}
                    </Button>
                </div>
            </ScrollableContainer>
        </form>
    );
};

export default AddAsset;