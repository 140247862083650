import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Modal,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Search, Close as CloseIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectUserData } from '../../../store/store/authSlice';
import { fetchDocumentIssues } from '../../../api/endpoints/APIEndpoints';
import Loader from '../../common/Loader';
import NewModal from '../../common/newModal';

// Styled Components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 600,
  backgroundColor: theme.palette.grey[100],
}));

const StyledTableHead = styled(TableHead)({
  '& th': {
    fontWeight: 600,
  },
});

// Helper functions for status and priority colors
const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case 'resolved': return 'success';
    case 'pending': return 'warning';
    case 'in progress': return 'info';
    default: return 'default';
  }
};

const getPriorityColor = (priority: string) => {
  switch (priority.toLowerCase()) {
    case 'high': return 'error';
    case 'medium': return 'warning';
    case 'low': return 'success';
    default: return 'default';
  }
};

const IssueDashboard: React.FC = () => {
  const [issues, setIssues] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIssue, setSelectedIssue] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userDetails = useSelector(selectUserData);

  // Filter issues based on search term
  const filteredIssues = issues.filter((issue) => {
    if (!issue) return false;
    const searchLower = searchTerm.toLowerCase();
    return (
      (issue.name?.toLowerCase().includes(searchLower) ?? false) ||
      (issue.type?.toLowerCase().includes(searchLower) ?? false) ||
      (issue.status?.toLowerCase().includes(searchLower) ?? false)
    );
  });

  // Fetch issues
  const fetchIssues = useCallback(() => {
    return fetchDocumentIssues.post({ employee_id: userDetails?.employee_id })
      .then((res) => {
        setIssues(Array.isArray(res.issues) ? res.issues : []);
      })
      .catch((error) => {
        console.log("ERROR!!", error);
        setIssues([]);
      })
      .finally(() => setIsLoading(false));
  }, [userDetails?.employee_id]);

  useEffect(() => {
    fetchIssues();
  }, [fetchIssues]);

  const handleIssueClick = (issue: any) => {
    setSelectedIssue(issue);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedIssue(null);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box>
      {/* Search Bar */}
      <div className="relative mb-4">
        <input
          type="text"
          placeholder="Search issues..."
          className="pl-10 pr-4 py-2 border rounded-lg w-full"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Search className="absolute left-3 top-2.5 text-gray-400" />
      </div>

      {/* Issues Table */}
      <TableContainer component={Paper}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <StyledTableCell>Issue ID</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Department</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Raised Date</StyledTableCell>
              <StyledTableCell>Resolved Date</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Priority</StyledTableCell>
              <StyledTableCell>Location</StyledTableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {filteredIssues.map((issue: any) => (
              <TableRow key={issue.issue_id}>
                <TableCell
                  onClick={() => handleIssueClick(issue)}
                  style={{ cursor: 'pointer', color: 'blue' }}
                >
                  {issue.issue_id}
                </TableCell>
                <TableCell>{issue.name}</TableCell>
                <TableCell>{issue.description}</TableCell>
                <TableCell>{issue.Department.department_name}</TableCell>
                <TableCell>{issue.type}</TableCell>
                <TableCell>{issue.raised_date}</TableCell>
                <TableCell>{issue.resolved_date || "--"}</TableCell>
                <TableCell>
                  <Chip 
                    label={issue.status}
                    color={getStatusColor(issue.status)}
                  />
                </TableCell>
                <TableCell>
                  <Chip 
                    label={issue.priority}
                    color={getPriorityColor(issue.priority)}
                  />
                </TableCell>
                <TableCell>{issue.location}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Issue Details Modal */}
      <NewModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title='Issue Details'
      >
          {selectedIssue && (
            <>
              <div className="px-4 py-2">
                <div className='flex justify-between items-start py-1 gap-4'>
                    <p><strong>Issue ID:</strong></p>
                    <p className='text-right '>{selectedIssue.issue_id}</p>
                </div>
                <div className='flex justify-between items-start py-1 gap-4'>
                    <p><strong>Name:</strong></p>
                    <p className='text-right '>{selectedIssue.name}</p>
                </div>
                <div className='flex justify-between items-start py-1 gap-4'>
                    <p><strong>Type:</strong></p>
                    <p>{selectedIssue.type}</p>
                </div>
                <div className='flex justify-between items-start py-1 gap-4'>
                    <p><strong>Raised Date:</strong></p>
                    <p>{selectedIssue.raised_date}</p>
                </div>
                <div className='flex justify-between items-start py-1 gap-4'>
                    <p><strong>Status:</strong></p>
                    <p>{selectedIssue.status}</p>
                </div>
                <div className='flex justify-between items-start py-1 gap-4'>
                    <p><strong>Priority:</strong></p>
                    <p>{selectedIssue.priority}</p>
                </div>
                <div className='flex justify-between items-start py-1 gap-4'>
                    <p><strong>Location:</strong></p>
                    <p>{selectedIssue.location}</p>
                </div>
                {selectedIssue.photo_evidence && (
                  <p>
                    <strong>Photo evidence:</strong><br />
                    <img 
                      src={selectedIssue.photo_evidence} 
                      alt="Photo Evidence"
                      className="mt-2 max-w-full h-auto" 
                    />
                  </p>
                )}
              </div>
            </>
          )}
      </NewModal>
    </Box>
  );
};

export default IssueDashboard;
