import React from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@mui/lab';
import { Typography, Paper, Chip, Box, IconButton, Tooltip } from '@mui/material';
import {
  Update as UpdateIcon,
  Build as MaintenanceIcon,
  LocationOn as LocationIcon,
} from '@mui/icons-material';
import dayjs from 'dayjs';

interface AssetHistoryProps {
  history: any[];
  onClose: () => void;
}

const getEventIcon = (eventType: string) => {
  switch (eventType) {
    case 'ASSET_UPDATED':
      return <UpdateIcon sx={{ color: '#3b82f6' }} />;
    case 'ASSET_MAINTENANCE_ADDED':
      return <MaintenanceIcon sx={{ color: '#10b981' }} />;
    default:
      return <UpdateIcon />;
  }
};

const getEventColor = (eventType: string) => {
  switch (eventType) {
    case 'ASSET_UPDATED':
      return 'primary';
    case 'ASSET_MAINTENANCE_ADDED':
      return 'success';
    default:
      return 'default';
  }
};

const formatEventData = (eventType: string, eventData: any) => {
  switch (eventType) {
    case 'ASSET_UPDATED':
      return `Changed ${eventData.field} from "${eventData.old_value}" to "${eventData.new_value}"`;
    case 'ASSET_MAINTENANCE_ADDED':
      return `Maintenance #${eventData.maintenance_no} recorded - Amount: ₹${eventData.amount}`;
    case 'ASSET_STATUS_CHANGED':
      return `Asset status updated to "${eventData.new_status}"`;
    default:
      return JSON.stringify(eventData);
  }
};

const AssetHistoryTimeline: React.FC<AssetHistoryProps> = ({ history }) => {
  return (
    <Paper className="p-6 max-w-4xl mx-auto" elevation={0}>
      <Box className="mb-6">
        <Typography variant="h6" className="text-gray-800 mb-2">
          Asset Activity History
        </Typography>
        <Typography variant="body2" className="text-gray-600">
          Track all changes and maintenance activities performed on this asset
        </Typography>
      </Box>

      <Timeline position="right">
        {history.map((event, index) => (
          <TimelineItem key={event.trail_id}>
            <TimelineOppositeContent className="flex items-center">
              <Typography variant="caption" color="textSecondary">
                {dayjs(event.created_at).format('MMM DD, YYYY h:mm A')}
              </Typography>
            </TimelineOppositeContent>

            <TimelineSeparator>
              <TimelineDot sx={{ 
                bgcolor: 'background.paper',
                boxShadow: 1,
                p: 1
              }}>
                {getEventIcon(event.event_type)}
              </TimelineDot>
              {index < history.length - 1 && <TimelineConnector />}
            </TimelineSeparator>

            <TimelineContent>
              <Paper elevation={0} className="p-3 border border-gray-100">
                <div className="mb-2 flex items-center justify-between">
                  <Chip
                    label={event.event_type.split('_').join(' ')}
                    color={getEventColor(event.event_type)}
                    size="small"
                    variant="outlined"
                  />
                  <Typography variant="caption" className="text-gray-500">
                    by {event.performer.first_name} {event.performer.last_name}
                  </Typography>
                </div>
                <Typography variant="body2" className="text-gray-700">
                  {formatEventData(event.event_type, event.event_data)}
                </Typography>
                {event.notes && (
                  <Typography variant="caption" className="text-gray-500 mt-1 block">
                    Note: {event.notes}
                  </Typography>
                )}
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Paper>
  );
};

export default AssetHistoryTimeline;