import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, Button, Grid, Paper, FormControlLabel, Switch, Typography, Divider } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { addDocument, getAllDepartments, updateDocument } from '../../../api/endpoints/APIEndpoints';
import Modal from '../../common/Modal';
import dayjs from 'dayjs';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../../../store/store/authSlice';

interface AddDocumentProps {
  onClose?: () => void;
  isEditMode?: boolean;
  editData?: any;
}

const AddDocument: React.FC<AddDocumentProps> = ({
  onClose,
  isEditMode = false,
  editData
}) => {
  const [formData, setFormData] = useState({
    document_name: editData?.document_name || '',
    document_description: editData?.document_description || '',
    document_number: editData?.document_number || '',
    initial_issue_date: editData?.initial_issue_date ? dayjs(editData.initial_issue_date) : null,
    department_id: editData?.department_id || '',
    additional_requirements: editData?.additional_requirements || '',
    priority: editData?.priority || 'medium',
    comment: editData?.comment || '',
    document_file: editData?.document_file || '',
    is_generic: editData?.is_generic || false,
    renewal_date: editData?.renewal_history?.current_renewal?.renewal_date ? 
      dayjs(editData.renewal_history.current_renewal.renewal_date) : null,
    due_date: editData?.renewal_history?.current_renewal?.due_date ? 
      dayjs(editData.renewal_history.current_renewal.due_date) : null,
    reminder_frequencies: editData?.renewal_history?.current_renewal?.reminder_frequencies || '',
    renewal_frequency: editData?.renewal_frequency || '',
    custom_renewal_years: '',
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDetails, setModalDetails] = useState<any>(null);
  const userData = useSelector(selectUserData, shallowEqual);

  const [allDepartment, setAllDepartment] = useState<any>()
  const [showCustomRenewal, setShowCustomRenewal] = useState(false);

  useEffect(() => {
    getAllDepartments.get()
      .then((res) => {
        let formattedDepartmentData = res?.map((department: {
          department_id: any;
          department_name: any;
        }) => {
          // For level 4, include all departments
          if (userData?.role?.level === 4) {
            return {
              value: department?.department_id,
              label: department?.department_name
            };
          }

          // For levels 2 and 3, include only departments the user has access to
          if (userData?.role?.level === 2 || userData?.role?.level === 3) {
            const hasAccess = userData.departments.some(
              (userDept: any) => userDept.department_id === department.department_id
            );
            if (hasAccess) {
              return {
                value: department?.department_id,
                label: department?.department_name
              };
            }
          }

          // For other levels, don't include any departments
          return null;
        }).filter(Boolean) ?? []; // Filter out null values

        setAllDepartment(formattedDepartmentData)

      })
      .catch((err) => console.log("ERROR!!", err))
  }, [])

  useEffect(() => {
    if (editData?.renewal_frequency) {
      const match = editData.renewal_frequency.match(/^(\d+)_years$/);
      if (match) {
        setFormData(prev => ({
          ...prev,
          renewal_frequency: 'custom',
          custom_renewal_years: match[1]
        }));
        setShowCustomRenewal(true);
      }
    }
  }, [editData]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Prepare the data for submission
    const submissionData = {
      ...formData,
      // Only include renewal fields if not generic
      ...(formData.is_generic ? {
        renewal_date: null,
        due_date: null,
        reminder_frequencies: null,
        renewal_frequency: null
      } : {
        renewal_frequency: formData.renewal_frequency === 'custom' 
          ? `${formData.custom_renewal_years}_years`
          : formData.renewal_frequency
      })
    };

    if (isEditMode) {
      updateDocument.post({ ...submissionData, document_id: editData?.document_id })
        .then((res) => {
          setModalDetails(submissionData);
          setShowSuccessModal(true);
        })
        .catch((error) => console.log("error", error));
    } else {
      addDocument.post(submissionData)
        .then((res) => {
          setModalDetails(submissionData);
          setShowSuccessModal(true);
        })
        .catch((error) => console.log("error", error));
    }
  };

  const getFormattedDepartments = () => {
    if (!allDepartment) return [];

    if (userData?.role?.level === 4) {
      return allDepartment;
    }

    if (userData?.role?.level === 2 || userData?.role?.level === 3) {
      return allDepartment.filter((dept: { value: any; }) =>
        userData.departments?.some(
          (userDept: any) => userDept.department_id === dept.value
        )
      );
    }

    return [];
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        // Remove the data:application/pdf;base64, prefix
        const base64Content = base64String.split(',')[1];
        setFormData(prev => ({ ...prev, document_file: base64Content }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Paper className="p-6">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.is_generic}
                  onChange={(e) => {
                    setFormData(prev => ({
                      ...prev,
                      is_generic: e.target.checked,
                      // Reset renewal fields when switching to generic
                      ...(e.target.checked && {
                        renewal_date: null,
                        due_date: null,
                        reminder_frequencies: '',
                        renewal_frequency: '',
                        custom_renewal_years: ''
                      })
                    }));
                    if (e.target.checked) {
                      setShowCustomRenewal(false);
                    }
                  }}
                />
              }
              label="Generic Document (No Renewal Required)"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Document Name"
              value={formData.document_name}
              onChange={(e) => setFormData({ ...formData, document_name: e.target.value })}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Document Number"
              value={formData.document_number}
              onChange={(e) => setFormData({ ...formData, document_number: e.target.value })}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Document Description"
              value={formData.document_description}
              onChange={(e) => setFormData({ ...formData, document_description: e.target.value })}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Initial Issue Date"
                value={formData.initial_issue_date}
                onChange={(newValue) => setFormData({ ...formData, initial_issue_date: newValue })}
                maxDate={dayjs()}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: formData.initial_issue_date ? dayjs(formData.initial_issue_date).isAfter(dayjs()) : undefined,
                    helperText: formData.initial_issue_date && dayjs(formData.initial_issue_date).isAfter(dayjs())
                      ? 'Issue date cannot be in the future'
                      : ''
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          {!formData.is_generic && (
            <>
              <Grid item xs={12}>
                <Typography variant="subtitle1" className="mb-2">
                  Renewal Information
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Renewal Date"
                    value={formData.renewal_date}
                    onChange={(newValue) => setFormData({ ...formData, renewal_date: newValue })}
                    minDate={formData.initial_issue_date || undefined}
                    slotProps={{
                      textField: { fullWidth: true }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Due Date"
                    value={formData.due_date}
                    onChange={(newValue) => setFormData({ ...formData, due_date: newValue })}
                    minDate={formData.initial_issue_date || undefined}
                    maxDate={formData.renewal_date ? dayjs(formData.renewal_date).subtract(1, 'day') : undefined}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: Boolean(
                          formData.due_date && (
                            (formData.initial_issue_date && dayjs(formData.due_date).isBefore(formData.initial_issue_date)) ||
                            (formData.renewal_date && dayjs(formData.due_date).isAfter(dayjs(formData.renewal_date).subtract(1, 'day')))
                          )
                        ),
                        helperText: formData.due_date && (
                          (formData.initial_issue_date && dayjs(formData.due_date).isBefore(formData.initial_issue_date))
                            ? 'Due date must be after issue date'
                            : (formData.renewal_date && dayjs(formData.due_date).isAfter(dayjs(formData.renewal_date).subtract(1, 'day')))
                              ? 'Due date must be before renewal date'
                              : ''
                        )
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  select
                  label="Reminder Frequencies"
                  value={formData.reminder_frequencies}
                  onChange={(e) => setFormData({ ...formData, reminder_frequencies: e.target.value })}
                  required
                >
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  select
                  label="Renewal Frequency"
                  value={formData.renewal_frequency}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormData({ ...formData, renewal_frequency: value });
                    setShowCustomRenewal(value === 'custom');
                  }}
                  required
                >
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="quarterly">Quarterly</MenuItem>
                  <MenuItem value="yearly">Yearly</MenuItem>
                  <MenuItem value="custom">Custom</MenuItem>
                </TextField>
              </Grid>
              {showCustomRenewal && (
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Custom Renewal (in years)"
                    value={formData.custom_renewal_years}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || (Number(value) > 0 && Number(value) <= 100)) {
                        setFormData({ ...formData, custom_renewal_years: value });
                      }
                    }}
                    required={showCustomRenewal}
                    inputProps={{ min: 1, max: 100 }}
                    helperText="Please enter a value between 1 and 100 years"
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              select
              label="Process Department"
              value={formData.department_id}
              onChange={(e) => setFormData({ ...formData, department_id: e.target.value })}
              required
            >
              {getFormattedDepartments().map((dept: any) => (
                <MenuItem key={dept.value} value={dept.value}>
                  {dept.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              select
              label="Priority"
              value={formData.priority}
              onChange={(e) => setFormData({ ...formData, priority: e.target.value })}
              required
            >
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="High">High</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Additional Requirements"
              value={formData.additional_requirements}
              onChange={(e) => setFormData({ ...formData, additional_requirements: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={2}
              label="Comment"
              value={formData.comment}
              onChange={(e) => setFormData({ ...formData, comment: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="file"
              inputProps={{ accept: 'application/pdf' }}
              onChange={handleFileChange}
              helperText="Upload PDF document"
            />
          </Grid>
          <Grid item xs={12} className="flex justify-end gap-3">
            {onClose && (
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            )}
            <Button variant="contained" type="submit">
              {isEditMode ? 'Update Document' : 'Add Document'}
            </Button>
          </Grid>
        </Grid>
      </form> 

      <Modal show={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
        <div className="p-8 text-center bg-white rounded-lg">
          <img
            src="/success.png"
            alt="Success"
            className="mx-auto mb-6 w-24 h-24"
          />
          <h3 className="mb-4 text-2xl font-semibold text-green-600">
            Document {`${isEditMode ? 'Updated' : 'Added'}`} Successfully!
          </h3>
          <div className="mb-6 text-left bg-gray-50 p-4 rounded-md">
            <p className="mb-2">
              <span className="font-semibold">Document Name:</span> {modalDetails?.document_name}
            </p>
            <p className="mb-2">
              <span className="font-semibold">Document Number:</span> {modalDetails?.document_number}
            </p>
            <p className="mb-2">
              <span className="font-semibold">Department:</span> {modalDetails?.department?.department_name}
            </p>
            <p className="mb-2">
              <span className="font-semibold">Priority:</span> {modalDetails?.priority}
            </p>
            <p className="mb-2">
              <span className="font-semibold">Renewal Date:</span> {modalDetails?.renewal_date?.format('DD/MM/YYYY')}
            </p>
            <p className="mb-2">
              <span className="font-semibold">Due Date:</span> {modalDetails?.due_date?.format('DD/MM/YYYY')}
            </p>
          </div>
          <button
            type="button"
            className="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center transition duration-300 ease-in-out transform hover:scale-105"
            onClick={() => setShowSuccessModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </Paper>
  );
};

export default AddDocument;