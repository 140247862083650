import React from 'react';

interface DisplayCard {
    title: string;
    quantity: number;
    lastMonthQuantityApprox: number;
    thisMonthQuantityApprox: number;
}

const DisplayCard: React.FC<DisplayCard> = ({title, quantity, lastMonthQuantityApprox, thisMonthQuantityApprox}) => {
  return (
    <div className="bg-gray-800 text-white rounded-lg p-4">
      <h2 className="text-md font-semibold">{title}</h2>
      <p className="text-sm">{quantity}</p>
      {/* <div className="flex space-x-4">
        <p className="text-[12px]">
          <span className="text-green-500">+{lastMonthQuantityApprox}</span>
          <br />
          Last month
        </p>
        <p className="text-[12px]">
          <span className="text-green-500">+{thisMonthQuantityApprox}</span>
          <br />
          This month
        </p>
      </div> */}
    </div>
  );
};

export default DisplayCard;