import React, { useEffect, useState } from 'react';
import { FaEdit, FaPlus, FaMinus, FaSearch } from 'react-icons/fa';
import Modal from '../common/Modal';
import Textarea from '../common/TextArea';
import Button from '../common/Button';
import { updateQuestion } from '../../api/endpoints/APIEndpoints';
import { Select } from '../common/Select';
import BackButton from '../../components/common/BackButton';
import NewModal from '../common/newModal';

interface QuestionForm {
  appQuestions: any;
  onDataSend?: any
  category?: string
}

interface Question {
  question_id: number;
  question_text: string;
  question_type: string;
  variant: string;
  category: string;
  maintenance_phase: string;
}

interface QuestionFormProps {
  appQuestions: any;
  onDataSend: (data: any) => void;
  category?: string;
  onSave: (questions: any[]) => void;
  handleQuestionsBackButtonClick: any;
}

const QuestionForm: React.FC<QuestionFormProps> = ({ appQuestions, onDataSend, category, onSave, handleQuestionsBackButtonClick }) => {
  const [questions, setQuestions] = useState<any>(appQuestions || []);
  const [selectedQuestions, setSelectedQuestions] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
  const [showEditQuestionModal, setShowEditQuestionModal] = useState(false);
  const [newQuestion, setNewQuestion] = useState("");
  const [editingQuestion, setEditingQuestion] = useState<Question | null>(null);
  const [phase, setPhase] = useState("");
  const [questionType, setQuestionType] = useState("");

  useEffect(() => {
    setQuestions(appQuestions);
  }, [appQuestions]);

  const filteredQuestions = questions.filter((question: Question) => 
    question.question_text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleQuestionSelect = (question: Question) => {
    if (selectedQuestions.some(q => q.question_id === question.question_id)) {
      setSelectedQuestions(selectedQuestions.filter(q => q.question_id !== question.question_id));
    } else {
      setSelectedQuestions([...selectedQuestions, question]);
    }
  };

  const handleSave = () => {
    if (selectedQuestions.length === 0) {
      alert('Please select at least one question');
      return;
    }
    onSave(selectedQuestions);
    onDataSend(selectedQuestions);
  };

  const handleEdit = (question: Question) => {
    setEditingQuestion(question);
    setShowEditQuestionModal(true);
  };

  const handleUpdateQuestion = async () => {
    if (editingQuestion) {
      try {
        await updateQuestion.post({
          question_id: editingQuestion.question_id,
          question_text: editingQuestion.question_text,
          question_type: editingQuestion.question_type
        });

        setQuestions(questions.map((q: { question_id: number; }) =>
          q.question_id === editingQuestion.question_id ? editingQuestion : q
        ));
        setShowEditQuestionModal(false);
        setEditingQuestion(null);
      } catch (error) {
        console.error("Failed to update question:", error);
      }
    }
  };

  const handleAddQuestion = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    
    if (questions.length === 0) {
      alert('Please add at least one question');
      return;
    }

    onSave(questions);
    onDataSend(questions);
  };

  const handleCancel = () => {
    console.log('Cancel changes');
    setShowAddQuestionModal(false)
  };

  const handleBackClick = () => {
    handleQuestionsBackButtonClick()
  };

  const EmptyStateWithAdd = () => (
    <div className="w-full bg-white p-6 rounded-lg shadow">
      <div className="text-center mb-6">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">No Questions Available</h3>
        <p className="text-gray-500">Start by adding new questions below</p>
      </div>
      
      {questions.length > 0 && (
        <div className="mb-6">
          {questions.map((q: Question, index: number) => (
            <div key={index} className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg mb-2">
              <div className="flex-grow">
                <p className="font-medium">{q.question_text}</p>
                <p className="text-sm text-gray-600">Type: {q.question_type}</p>
              </div>
              <button
                onClick={() => {
                  const updatedQuestions = questions.filter((_: any, i: number) => i !== index);
                  setQuestions(updatedQuestions);
                }}
                className="p-2 text-red-600 hover:bg-red-50 rounded-full"
              >
                <FaMinus />
              </button>
            </div>
          ))}
        </div>
      )}

      <div className="border rounded-lg p-4">
        <div className="flex gap-4 mb-4">
          <input
            type="text"
            placeholder="Enter question text..."
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
            className="flex-grow p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            autoFocus
          />
          <select
            value={questionType}
            onChange={(e) => setQuestionType(e.target.value)}
            className="w-48 p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Question Type</option>
            <option value="yes_no">Yes/No</option>
            <option value="descriptive">Descriptive</option>
          </select>
          
          {category?.toLowerCase() === "maintenance" && (
            <select
              value={phase}
              onChange={(e) => setPhase(e.target.value)}
              className="w-48 p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select Phase</option>
              <option value="pre">Pre</option>
              <option value="post">Post</option>
              <option value="during">During</option>
            </select>
          )}
        </div>
        
        <div className="flex justify-end gap-4">
          <Button
            label="Add Question"
            onClick={() => {
              if (!newQuestion || !questionType) {
                alert('Please fill in all required fields');
                return;
              }
              
              setQuestions([...questions, {
                question_text: newQuestion,
                question_type: questionType,
                variant: 'new',
                category: category,
                maintenance_phase: phase || undefined
              }]);
              
              setNewQuestion('');
              setQuestionType('');
              setPhase('');
            }}
            className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg"
          />
        </div>
      </div>

      {questions.length > 0 && (
        <div className="mt-6 flex justify-end">
          <Button
            label="Save All Questions"
            onClick={handleAddQuestion}
            className="bg-green-600 hover:bg-green-700 text-white px-6 py-3 rounded-lg"
          />
        </div>
      )}
    </div>
  );

  return (
    <div className="h-[calc(100vh-100px)] p-4">
      <BackButton handleClick={handleBackClick}/>
      
      {appQuestions?.length > 0 ? (
        <div className="flex h-full gap-4">
          <div className="w-1/2 flex flex-col">
            <div className="bg-white p-4 rounded-lg shadow mb-4">
              <div className="relative mb-4">
                <FaSearch className="absolute left-3 top-3 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search questions..."
                  className="w-full pl-10 pr-4 py-2 border rounded-lg"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="overflow-y-auto h-[calc(100vh-250px)]">
                {filteredQuestions.map((question: Question) => (
                  <div
                    key={question.question_id}
                    className="border border-gray-200 rounded-lg p-3 mb-2 flex justify-between items-center hover:bg-gray-50"
                  >
                    <div className="flex-grow">
                      <p className="text-sm font-medium text-gray-800">{question.question_text}</p>
                      <p className="text-xs text-gray-600">Type: {question.question_type}</p>
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => handleEdit(question)}
                        className="p-2 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200"
                        title="Edit question"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => handleQuestionSelect(question)}
                        className={`p-2 rounded-full ${
                          selectedQuestions.some(q => q.question_id === question.question_id)
                            ? 'bg-red-100 text-red-600 hover:bg-red-200'
                            : 'bg-green-100 text-green-600 hover:bg-green-200'
                        }`}
                        title={selectedQuestions.some(q => q.question_id === question.question_id) 
                          ? "Remove question" 
                          : "Add question"}
                      >
                        {selectedQuestions.some(q => q.question_id === question.question_id) 
                          ? <FaMinus /> 
                          : <FaPlus />}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="w-1/2 flex flex-col">
            <div className="bg-white p-4 rounded-lg shadow">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">Selected Questions ({selectedQuestions.length})</h2>
                <Button
                  label="Save Selection"
                  onClick={handleSave}
                  className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-lg"
                />
              </div>
              <div className="overflow-y-auto h-[calc(100vh-250px)]">
                {selectedQuestions.map((question: Question) => (
                  <div
                    key={question.question_id}
                    className="border border-green-200 bg-green-50 rounded-lg p-3 mb-2 flex justify-between items-center"
                  >
                    <div className="flex-grow">
                      <p className="text-sm font-medium text-gray-800">{question.question_text}</p>
                      <p className="text-xs text-gray-600">Type: {question.question_type}</p>
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => handleEdit(question)}
                        className="p-2 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200"
                        title="Edit question"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => handleQuestionSelect(question)}
                        className="p-2 rounded-full bg-red-100 text-red-600 hover:bg-red-200"
                        title="Remove question"
                      >
                        <FaMinus />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyStateWithAdd />
      )}

      <NewModal isOpen={showEditQuestionModal} onClose={() => setShowEditQuestionModal(false)} title='Update Question'>
        {editingQuestion && (
          <>
            <Textarea
              label={'Edit Question'}
              value={editingQuestion.question_text}
              onChange={(e) => setEditingQuestion({ ...editingQuestion, question_text: e.target.value })}
            />
            <Select
              label={'Question Type'}
              options={[
                { value: 'yes_no', label: 'Yes/No' },
                { value: 'descriptive', label: 'Descriptive' }
              ]}
              value={editingQuestion.question_type}
              onChange={(e) => setEditingQuestion({ ...editingQuestion, question_type: e.target.value })}
            />
            <Button
              label={'Update Question'}
              onClick={handleUpdateQuestion}
            />
          </>
        )}
      </NewModal>
    </div>
  );
};

export default QuestionForm;
