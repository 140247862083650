import React, { useEffect, useState } from 'react';
import { Input } from '../common/Input';
import { Select } from '../common/Select';
import { addChecklist, addCustomQuestion, fetchAppQuestions, getAllDepartments } from '../../api/endpoints/APIEndpoints';
import QuestionForm from './QuestionForm';
import { Checklists, Question } from '../../types/common';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../../store/store/authSlice';
import { useNavigate } from 'react-router-dom';
import Modal from '../common/Modal';
import CloseIcon from '@mui/icons-material/Close';
import NewModal from '../common/newModal';

interface ChecklistForm {
    departmentOptions?: { value: string; label: string }[];
    projectOptions?: { value: string; label: string }[];
    addParentQues?: boolean;
    selectedChecklist?: Checklists
}

const CheckListForm: React.FC<ChecklistForm> = ({ selectedChecklist }) => {
    const navigate = useNavigate();
    const [checklistName, setChecklistName] = useState(selectedChecklist?.name || '');
    const [checklistDescription, setChecklistDescription] = useState(selectedChecklist?.description || '');
    const [checklistPreference, setChecklistPreference] = useState('');
    const [maintenanceType, setMaintenanceType] = useState('')
    const [checklistType, setChecklistType] = useState('');
    const [showChecklistQuestions, setShowChecklistQuestions] = useState(false);
    const [addedChecklistData, setAddedChecklistData] = useState()
    const userDetails = useSelector(selectUserData, shallowEqual);
    const [buttonText, setButtonText] = useState("NEXT")
    const [appQuestions, setAppQuestions] = useState<Question[]>([])
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [newChecklistDetails, setNewChecklistDetails] = useState<any>(null);
    const [departments, setDepartments] = useState<{ value: string; label: string }[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');

    const handleQuestionsData = (data: any) => {
        console.log("Questions data received:", data);
        addNewChecklist(data);
    }

    useEffect(() => {
        getAllDepartments.get()
            .then((res) => {
                let formattedDepartmentData = res?.map((department: {
                    department_id: any;
                    department_name: any;
                }) => {
                    // For level 4, include all departments
                    if (userDetails?.role?.level === 4) {
                        return {
                            value: department?.department_id,
                            label: department?.department_name
                        };
                    }

                    // For levels 2 and 3, include only departments the user has access to
                    if (userDetails?.role?.level === 2 || userDetails?.role?.level === 3) {
                        const hasAccess = userDetails.departments.some(
                            (userDept: any) => userDept.department_id === department.department_id
                        );
                        if (hasAccess) {
                            return {
                                value: department?.department_id,
                                label: department?.department_name
                            };
                        }
                    }

                    // For other levels, don't include any departments
                    return null;
                }).filter(Boolean) ?? []; // Filter out null values

                setDepartments(formattedDepartmentData)
            })
            .catch((error) => console.error("Error fetching departments:", error));
    }, []);


    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!checklistName) {
            alert("Please fill out all fields.");
            return;
        }

        if (checklistPreference === "Company") {
            const payload: any = {
                variant: checklistPreference,
                category: checklistType,
            }
            if (maintenanceType) {
                payload.maintenance_phase = maintenanceType
            }

            fetchAppQuestions.post(payload)
                .then((res) => setAppQuestions(res))
                .catch((error) => {
                    setAppQuestions([])
                    console.log("ERROR!!", error)
                }
                )
        }
        else {
            setAppQuestions([])
        }

        setShowChecklistQuestions(true)
    };

    const addNewChecklist = (data: any) => {
        const newChecklist: any = {
            name: checklistName,
            description: checklistDescription,
            variant: checklistPreference,
            approver_id: userDetails?.supervisor_id || userDetails?.employee_id,
            requestor_id: userDetails?.employee_id,
            associatedQuestions: data,
            checklist_type: checklistType,
            department_id: Number(selectedDepartment)
        };

        addChecklist.post(newChecklist)
            .then((res) => {
                console.log("Checklist request submitted successfully", res);
                setNewChecklistDetails({
                    ...newChecklist,
                    checklist_id: res.checklist_id, // assuming the API returns the new checklist ID
                    questions: data
                });
                setShowSuccessModal(true);
            })
            .catch(error => console.log("ERROR!!", error));
    }

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        navigate('/inspection/checklist'); // Redirect to checklist dashboard
    }

    const handleCancel = () => {
        setChecklistName('');
    };

    const handleQuestionsBackButtonClick = () => {
        setShowChecklistQuestions(false);
    }
    console.log(userDetails)
    return (
        <div>
            <form onSubmit={handleSubmit} >
                {
                    !showChecklistQuestions
                        ? <div className='gap-x-4 w-[80%] m-auto'>
                            <div className="">
                                <Input
                                    label="Checklist Name"
                                    value={checklistName}
                                    onChange={(e) => setChecklistName(e.target.value)}
                                    required
                                />
                            </div>

                            <Select
                                label="Checklist Type"
                                options={[{ value: "Inspection", label: 'Inspection' },
                                { value: "Maintenance", label: 'Maintenance' }
                                ]}
                                value={checklistType}
                                onChange={(e) => setChecklistType(e.target.value)}
                                required
                            />

                            {
                                checklistType === "Maintenance" && <div className="">
                                    <Select
                                        label="Maintenance Type "
                                        options={[{ value: "breakdown", label: 'Breakdown' },
                                        { value: "scheduled", label: 'Scheduled' }
                                        ]}
                                        value={maintenanceType}
                                        onChange={(e) => setMaintenanceType(e.target.value)}
                                        required
                                    />
                                </div>
                            }

                            <div className="">
                                <Input label="Checklist Description"
                                    value={checklistDescription}
                                    onChange={(e) => setChecklistDescription(e.target.value)}
                                />
                            </div>

                            <div className="mb-4">
                                <Select
                                    label="Department"
                                    options={departments}
                                    value={selectedDepartment}
                                    onChange={(e) => setSelectedDepartment(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="">
                                <Select
                                    label="Checklist Preference"
                                    options={[{ value: "Company", label: 'Checklist-Ready Made Questions' },
                                    { value: "Custom_new", label: 'Checklist-Personalized Questions' }
                                    ]}
                                    value={checklistPreference}
                                    onChange={(e) => setChecklistPreference(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="flex justify-end">
                                <button type="button" onClick={handleCancel} className="mr-2 bg-gray-300 text-gray-800 p-2 rounded">
                                    Cancel
                                </button>
                                <button type="submit" className="bg-blue-500 text-white p-2 rounded">
                                    NEXT
                                </button>
                            </div>
                        </div>
                        : <QuestionForm appQuestions={appQuestions}
                            handleQuestionsBackButtonClick={handleQuestionsBackButtonClick}
                            onDataSend={handleQuestionsData}
                            category={checklistType}
                            onSave={handleQuestionsData} />
                }
            </form>

            {/* Success Modal */}
            <NewModal
                isOpen={showSuccessModal}
                onClose={handleCloseSuccessModal}
                title='Checklist Created Successfully!'
            >
                <div className="p-6">
                    <div className='flex justify-between items-start py-1 gap-4'><p><strong>Checklist Name:</strong></p><p className="text-right"> {newChecklistDetails?.name}</p></div>
                    <div className='flex justify-between items-start py-1 gap-4'><p><strong>Type:</strong></p><p className="text-right"> {newChecklistDetails?.checklist_type}</p></div>
                    <div className='flex justify-between items-start py-1 gap-4'><p><strong>Description:</strong></p><p className="text-right"> {newChecklistDetails?.description}</p></div>
                    <div className='flex justify-between items-start py-1 gap-4'><p><strong>Number of Questions:</strong></p><p className="text-right"> {newChecklistDetails?.associatedQuestions?.length}</p></div>
                </div>
            </NewModal>
        </div>
    );
};

export default CheckListForm;