import React, { useMemo } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    MonetizationOn as MoneyIcon,
    Build as MaintenanceIcon,
    Timeline as DepreciationIcon
} from '@mui/icons-material';

interface AssetWorthProps {
    assetData: any[];
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 600,
    backgroundColor: theme.palette.grey[100],
}));

const StatCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    borderRadius: '12px',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'translateY(-5px)',
    }
}));

const TableWrapper = styled(Box)({
    height: 'calc(100vh - 380px)',
    overflow: 'auto'
});

const AssetWorth: React.FC<AssetWorthProps> = ({ assetData }) => {
    const totalStats = useMemo(() => {
        return assetData.reduce((acc, asset) => ({
            totalPurchaseCost: acc.totalPurchaseCost + (Number(asset.purchase_cost) || 0),
            totalMaintenanceCost: acc.totalMaintenanceCost + (asset.maintenance_history
                ?.maintenance_history
                ?.reduce((acc: number, curr: { amount: any; }) => acc + Number(curr.amount), 0) || 0),
            totalCurrentValue: acc.totalCurrentValue + (Number(asset.current_value) || 0),
        }), {
            totalPurchaseCost: 0,
            totalMaintenanceCost: 0,
            totalCurrentValue: 0,
        });
    }, [assetData]);

    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(amount);
    };

    return (
        <div className="h-full flex flex-col">
            {/* Statistics Cards - Now with fixed height */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                <StatCard elevation={2}>
                    <div className="p-3 rounded-full bg-blue-100">
                        <MoneyIcon className="text-blue-600" />
                    </div>
                    <div>
                        <Typography variant="subtitle2" color="textSecondary">
                            Total Purchase Cost
                        </Typography>
                        <Typography variant="h6" className="font-semibold">
                            {formatCurrency(totalStats.totalPurchaseCost)}
                        </Typography>
                    </div>
                </StatCard>

                <StatCard elevation={2}>
                    <div className="p-3 rounded-full bg-orange-100">
                        <MaintenanceIcon className="text-orange-600" />
                    </div>
                    <div>
                        <Typography variant="subtitle2" color="textSecondary">
                            Total Maintenance Cost
                        </Typography>
                        <Typography variant="h6" className="font-semibold">
                            {formatCurrency(totalStats.totalMaintenanceCost)}
                        </Typography>
                    </div>
                </StatCard>

                <StatCard elevation={2}>
                    <div className="p-3 rounded-full bg-green-100">
                        <DepreciationIcon className="text-green-600" />
                    </div>
                    <div>
                        <Typography variant="subtitle2" color="textSecondary">
                            Current Total Value
                        </Typography>
                        <Typography variant="h6" className="font-semibold">
                            {formatCurrency(totalStats.totalCurrentValue)}
                        </Typography>
                    </div>
                </StatCard>
            </div>

            {/* Table with fixed height and scrolling */}
            <TableWrapper>
                <TableContainer component={Paper} elevation={2} sx={{ height: '100%' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Asset Name</StyledTableCell>
                                <StyledTableCell>Asset ID</StyledTableCell>
                                <StyledTableCell align="right">Purchase Cost</StyledTableCell>
                                <StyledTableCell align="right">Total Maintenance Cost</StyledTableCell>
                                <StyledTableCell align="right">Current Value</StyledTableCell>
                                <StyledTableCell align="right">Total Cost</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assetData.map((asset) => {
                                const totalCost = (Number(asset.purchase_cost) || 0) +
                                    (asset.maintenance_history
                                        ?.maintenance_history
                                        ?.reduce((acc: number, curr: { amount: any; }) => acc + Number(curr.amount), 0) || 0);
                                return (
                                    <TableRow
                                        key={asset.asset_id}
                                        hover
                                        className="transition-colors duration-200"
                                    >
                                        <TableCell>{asset.asset_name}</TableCell>
                                        <TableCell>{asset.asset_id}</TableCell>
                                        <TableCell align="right">
                                            {formatCurrency(asset.purchase_cost || 0)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {formatCurrency(asset.maintenance_history
                                                ?.maintenance_history
                                                ?.reduce((acc: number, curr: { amount: any; }) => acc + Number(curr.amount), 0) || 0)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {formatCurrency(asset.current_value || 0)}
                                        </TableCell>
                                        <TableCell align="right" className="font-semibold">
                                            {formatCurrency(totalCost)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TableWrapper>
        </div>
    );
};

export default AssetWorth;
