import React, { useState } from 'react';
import {
    IconButton,
    Modal,
    Box,
    Typography,
    Tooltip,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    Chip,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import AddDocument from './AddDocument';
import jsPDF from 'jspdf';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { 
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot 
} from '@mui/lab';
import { deleteDocument } from '../../../api/endpoints/APIEndpoints';
import { toast } from 'react-toastify';
import { ExpandMore, WarningAmberRounded, CheckCircleRounded } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArticleIcon from '@mui/icons-material/Article';
import NewModal from '../../common/newModal';
import ConfirmationPopup from '../ConfirmationPopup';

dayjs.extend(utc);

interface DocumentDashboardProps {
    documentData: any[];
}

const DocumentDashboard: React.FC<DocumentDashboardProps> = ({ documentData }) => {
    const [selectedDocument, setSelectedDocument] = useState<any | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const getPriorityStyle = (priority: string) => {
        switch (priority?.toLowerCase()) {
            case "high":
                return "text-red-800 bg-red-100";
            case "medium":
                return "text-yellow-800 bg-yellow-100";
            case "low":
                return "text-green-800 bg-green-100";
            default:
                return "text-gray-800 bg-gray-100";
        }
    };

    const handleDocumentClick = (document: any) => {
        setSelectedDocument(document);
        setIsModalOpen(true);
    };

    const handleEditClick = (document: any, e: React.MouseEvent) => {
        e.stopPropagation();
        setSelectedDocument(document);
        setIsEditModalOpen(true);
    };

    const handleDeleteClick = (document: any, e: React.MouseEvent) => {
        e.stopPropagation();
        setSelectedDocument(document);
        setIsDeleteDialogOpen(true);
    };

    const handlePrintDocument = (document: any, e: React.MouseEvent) => {
        e.stopPropagation();
        const doc = new jsPDF();

        doc.setFontSize(16);
        doc.text(`Document Details: ${document.document_number}`, 14, 15);

        (doc as any).autoTable({
            head: [['Field', 'Value']],
            body: [
                ['Document Number', document.document_number],
                ['Document Name', document.document_name],
                ['Description', document.document_description],
                ['Initial Issue Date', dayjs(document.initial_issue_date).format('YYYY-MM-DD')],
                ['Renewal Date', dayjs(document.renewal_history?.current_renewal?.renewal_date).format('YYYY-MM-DD')],
                ['Renewal Frequency', document.renewal_frequency],
                ['Process Department', document.process_department_for_renewal],
                ['Priority', document.priority],
                ['Additional Requirements', document.additional_requirements],
                ['Comment', document.comment]
            ],
            startY: 25,
            styles: { fontSize: 8 },
            headStyles: { fillColor: [66, 66, 66] },
        });

        doc.save(`document_${document.document_number}.pdf`);
    };

    const handleDelete = () => {
        // Implement delete functionality
        console.log('Deleting document:', selectedDocument);
        setIsDeleteDialogOpen(false);
        setSelectedDocument(null);
        deleteDocument.post({document_id: selectedDocument.document_id})
        .then((res: any) => {
            toast.success("Renewal deleted successfully");
        })
        .catch((err) => {
            console.log(err);
        })
    };

    // Separate documents based on renewal status
    const categorizeDocuments = (documents: any[]) => {
        const today = dayjs();
        return documents.reduce(
            (acc, doc) => {
                // Check if document has no renewal dates - consider it generic
                if (!doc.renewal_history?.current_renewal?.renewal_date || 
                    !doc.renewal_history?.current_renewal?.due_date) {
                    acc.generic.push(doc);
                    return acc;
                }

                const renewalDate = dayjs(doc.renewal_history.current_renewal.renewal_date);
                const dueDate = dayjs(doc.renewal_history.current_renewal.due_date);
                
                if ((today.isAfter(renewalDate) && today.isBefore(dueDate)) || today.isAfter(renewalDate)) {
                    acc.toBeRenewed.push(doc);
                } else {
                    acc.renewed.push(doc);
                }
                return acc;
            },
            { renewed: [], toBeRenewed: [], generic: [] }
        );
    };

    const { renewed, toBeRenewed, generic } = categorizeDocuments(documentData || []);

    return (
        <div className="w-full space-y-6">
            {/* Documents To Be Renewed */}
            <Accordion 
                defaultExpanded 
                sx={{
                    backgroundColor: '#fff8f8',
                    '&:before': { display: 'none' },
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{ 
                        borderBottom: '1px solid #fee2e2',
                        '&.Mui-expanded': {
                            minHeight: '48px',
                            backgroundColor: '#fef2f2'
                        }
                    }}
                >
                    <div className="flex items-center gap-3">
                        <WarningAmberRounded color="error" />
                        <div>
                            <Typography variant="h6" className="font-semibold text-red-700">
                                Documents Due for Renewal
                            </Typography>
                            <Typography variant="body2" className="text-red-600">
                                {toBeRenewed.filter((doc: { renewal_history: { current_renewal: { due_date: string | number | Date | dayjs.Dayjs | null | undefined; }; }; }) => dayjs().isAfter(dayjs(doc.renewal_history?.current_renewal?.due_date))).length} overdue, 
                                {toBeRenewed.filter((doc: { renewal_history: { current_renewal: { due_date: string | number | Date | dayjs.Dayjs | null | undefined; }; }; }) => !dayjs().isAfter(dayjs(doc.renewal_history?.current_renewal?.due_date))).length} pending renewal
                            </Typography>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <DocumentTable 
                        documents={toBeRenewed}
                        handleDocumentClick={handleDocumentClick}
                        handleEditClick={handleEditClick}
                        handleDeleteClick={handleDeleteClick}
                        handlePrintDocument={handlePrintDocument}
                        getPriorityStyle={getPriorityStyle}
                    />
                </AccordionDetails>
            </Accordion>

            {/* Renewed Documents */}
            <Accordion 
                defaultExpanded
                sx={{
                    backgroundColor: '#f7fdf7',
                    '&:before': { display: 'none' },
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{ 
                        borderBottom: '1px solid #dcfce7',
                        '&.Mui-expanded': {
                            minHeight: '48px',
                            backgroundColor: '#f0fdf4'
                        }
                    }}
                >
                    <div className="flex items-center gap-3">
                        <CheckCircleRounded color="success" />
                        <div>
                            <Typography variant="h6" className="font-semibold text-green-700">
                                Renewed Documents
                            </Typography>
                            <Typography variant="body2" className="text-green-600">
                                {renewed.length} documents up to date
                            </Typography>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <DocumentTable 
                        documents={renewed}
                        handleDocumentClick={handleDocumentClick}
                        handleEditClick={handleEditClick}
                        handleDeleteClick={handleDeleteClick}
                        handlePrintDocument={handlePrintDocument}
                        getPriorityStyle={getPriorityStyle}
                    />
                </AccordionDetails>
            </Accordion>

            {/* Generic Documents */}
            <Accordion 
                defaultExpanded
                sx={{
                    backgroundColor: '#f8f9fa',
                    '&:before': { display: 'none' },
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{ 
                        borderBottom: '1px solid #e9ecef',
                        '&.Mui-expanded': {
                            minHeight: '48px',
                            backgroundColor: '#f1f3f5'
                        }
                    }}
                >
                    <div className="flex items-center gap-3">
                        <ArticleIcon sx={{ color: '#6B7280' }} />
                        <div>
                            <Typography variant="h6" className="font-semibold text-gray-700">
                                Generic Documents
                            </Typography>
                            <Typography variant="body2" className="text-gray-600">
                                {generic.length} standard documents
                            </Typography>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <DocumentTable 
                        documents={generic}
                        handleDocumentClick={handleDocumentClick}
                        handleEditClick={handleEditClick}
                        handleDeleteClick={handleDeleteClick}
                        handlePrintDocument={handlePrintDocument}
                        getPriorityStyle={getPriorityStyle}
                        isGeneric={true}
                    />
                </AccordionDetails>
            </Accordion>

            {/* Document Details Modal */}
            <NewModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title='Document Details'
                extraStyles={{maxWidth:"800px", minWidth:"800px"}}
            >       
                    <div className="max-h-[70vh] overflow-y-scroll scrollbar-hide">
                        {/* Header Section */}
                        <Box className="flex justify-between items-center border-b pb-4">
                            <Typography variant="h5" className="text-gray-800">
                                {selectedDocument?.document_name}
                            </Typography>
                        </Box>

                        {/* Main Info Cards */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="bg-gray-50 p-5 rounded-lg border border-gray-100">
                                <div className="flex items-center gap-2 mb-4">
                                    <ArticleIcon sx={{ color: '#4B5563' }} />
                                    <Typography variant="subtitle1" className="font-medium text-gray-700">
                                        Basic Details
                                    </Typography>
                                </div>
                                <div className="space-y-3 text-sm">
                                    <p className="flex justify-between">
                                        <span className="text-gray-600">Document Number:</span>
                                        <span className="font-medium text-gray-900">{selectedDocument?.document_number}</span>
                                    </p>
                                    <p className="flex justify-between">
                                        <span className="text-gray-600">Description:</span>
                                        <span className="font-medium text-gray-900">{selectedDocument?.document_description}</span>
                                    </p>
                                    <p className="flex justify-between">
                                        <span className="text-gray-600">Issue Date:</span>
                                        <span className="font-medium text-gray-900">
                                            {dayjs(selectedDocument?.initial_issue_date).format('DD/MM/YYYY')}
                                        </span>
                                    </p>
                                </div>
                            </div>

                            <div className="bg-gray-50 p-5 rounded-lg border border-gray-100">
                                <div className="flex items-center gap-2 mb-4">
                                    <ArticleIcon sx={{ color: '#4B5563' }} />
                                    <Typography variant="subtitle1" className="font-medium text-gray-700">
                                        Additional Info
                                    </Typography>
                                </div>
                                <div className="space-y-3 text-sm">
                                    <p className="flex justify-between">
                                        <span className="text-gray-600">Renewal Frequency:</span>
                                        <span className="font-medium text-gray-900">{selectedDocument?.renewal_frequency}</span>
                                    </p>
                                    <p className="flex justify-between items-center">
                                        <span className="text-gray-600">Priority:</span>
                                        <span className={`px-3 py-1 text-xs font-medium rounded-full ${getPriorityStyle(selectedDocument?.priority)}`}>
                                            {selectedDocument?.priority}
                                        </span>
                                    </p>
                                    <p className="flex justify-between">
                                        <span className="text-gray-600">Additional Requirements:</span>
                                        <span className="font-medium text-gray-900">{selectedDocument?.additional_requirements}</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Document View Button */}
                        {selectedDocument?.document_file_url && (
                            <div className="flex justify-end">
                                <Button
                                    variant="outlined"
                                    startIcon={<VisibilityIcon />}
                                    onClick={() => window.open(`${selectedDocument.document_file_url}`, '_blank')}
                                    sx={{
                                        borderColor: '#E5E7EB',
                                        color: '#374151',
                                        '&:hover': {
                                            borderColor: '#9CA3AF',
                                            backgroundColor: '#F9FAFB'
                                        }
                                    }}
                                    size="small"
                                >
                                    Open Document
                                </Button>
                            </div>
                        )}

                        <Divider />

                        {/* Current Renewal Section */}
                        {selectedDocument?.renewal_history?.current_renewal && (
                            <div className="mb-4">
                                <Typography variant="h6" className="mb-3 font-semibold">Current Renewal</Typography>
                                <div className="bg-gray-50 p-4 rounded-lg">
                                    <div className="flex justify-between items-center mb-2">
                                        <span className="font-semibold">Renewal ID: {selectedDocument.renewal_history.current_renewal.renewal_id}</span>
                                        <Chip 
                                            label={selectedDocument.renewal_history.current_renewal.status}
                                            color={selectedDocument.renewal_history.current_renewal.status === 'pending' ? 'warning' : 'success'}
                                            size="small"
                                        />
                                    </div>
                                    <p><strong>Due Date:</strong> {dayjs(selectedDocument.renewal_history.current_renewal.due_date).utc().format('DD/MM/YYYY')}</p>
                                    <p><strong>Renewal Date:</strong> {dayjs(selectedDocument.renewal_history.current_renewal.renewal_date).utc().format('DD/MM/YYYY')}</p>
                                    <p><strong>Reminder Frequency:</strong> {selectedDocument.renewal_history.current_renewal.reminder_frequencies}</p>
                                    {selectedDocument.renewal_history.current_renewal.comment && (
                                        <p><strong>Comment:</strong> {selectedDocument.renewal_history.current_renewal.comment}</p>
                                    )}
                                </div>
                            </div>
                        )}

                        {/* Renewal History Section */}
                        {selectedDocument?.renewal_history?.renewals && selectedDocument.renewal_history.renewals.length > 0 && (
                            <div>
                                <Typography variant="h6" className="mb-3 font-semibold">Renewal History</Typography>
                                <Timeline>
                                    {selectedDocument.renewal_history.renewals.map((renewal: any, index: number) => (
                                        <TimelineItem key={renewal.renewal_id}>
                                            <TimelineSeparator>
                                                <TimelineDot 
                                                    color={renewal.status === 'pending' ? 'warning' : 'success'}
                                                />
                                                {index < selectedDocument.renewal_history.renewals.length - 1 && <TimelineConnector />}
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <div className="bg-gray-50 p-3 rounded-lg">
                                                    <div className="flex justify-between items-center mb-2">
                                                        <span className="font-semibold">{renewal.renewal_id}</span>
                                                        <Chip 
                                                            label={renewal.status}
                                                            color={renewal.status === 'pending' ? 'warning' : 'success'}
                                                            size="small"
                                                        />
                                                    </div>
                                                    <div className="text-sm">
                                                        <p><strong>Due Date:</strong> {dayjs(renewal.due_date).utc().format('DD/MM/YYYY')}</p>
                                                        <p><strong>Renewal Date:</strong> {dayjs(renewal.renewal_date).utc().format('DD/MM/YYYY')}</p>
                                                        {renewal.completed_date && (
                                                            <p><strong>Completed Date:</strong> {dayjs(renewal.completed_date).format('DD/MM/YYYY')}</p>
                                                        )}
                                                        {renewal.completed_by && (
                                                            <p><strong>Completed By:</strong> {renewal.completed_by}</p>
                                                        )}
                                                        <p><strong>Reminder Frequency:</strong> {renewal.reminder_frequencies}</p>
                                                        {renewal.comment && (
                                                            <p><strong>Comment:</strong> {renewal.comment}</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </TimelineContent>
                                        </TimelineItem>
                                    ))}
                                </Timeline>
                            </div>
                        )}
                    </div>
            </NewModal>

            {/* Edit Modal */}
            <NewModal
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                title='Edit Document'
            >
                <div className='max-h-[70vh] overflow-y-scroll scrollbar-hide'>
                    <AddDocument
                        onClose={() => setIsEditModalOpen(false)}
                        isEditMode={true}
                        editData={selectedDocument}
                    />
                </div>
                
            </NewModal>

            {/* Delete Confirmation Dialog */}
            <ConfirmationPopup
                isOpen={isDeleteDialogOpen}
                onConfirm={handleDelete}
                onCancel={() => setIsDeleteDialogOpen(false)}
            />
        </div>
    );
};

// Separate component for the document table
const DocumentTable: React.FC<{
    documents: any[];
    handleDocumentClick: (doc: any) => void;
    handleEditClick: (doc: any, e: React.MouseEvent) => void;
    handleDeleteClick: (doc: any, e: React.MouseEvent) => void;
    handlePrintDocument: (doc: any, e: React.MouseEvent) => void;
    getPriorityStyle: (priority: string) => string;
    isGeneric?: boolean;
}> = ({ documents, handleDocumentClick, handleEditClick, handleDeleteClick, handlePrintDocument, getPriorityStyle, isGeneric }) => {
    return (
        <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-white">
                    <tr>
                        <th scope="col" className="px-6 py-3">Status</th>
                        <th scope="col" className="px-6 py-3">Document ID</th>
                        <th scope="col" className="px-6 py-3">Document Number</th>
                        <th scope="col" className="px-6 py-3">Document Name</th>
                        <th scope="col" className="px-6 py-3">Issue Date</th>
                        {!isGeneric && (
                            <>
                                <th scope="col" className="px-6 py-3">Renewal Date</th>
                                <th scope="col" className="px-6 py-3">Due Date</th>
                            </>
                        )}
                        <th scope="col" className="px-6 py-3">Priority</th>
                        <th scope="col" className="px-6 py-3">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {documents.map((document: any) => {
                        const renewalDate = dayjs(document.renewal_history?.current_renewal?.renewal_date);
                        const dueDate = dayjs(document.renewal_history?.current_renewal?.due_date);
                        const today = dayjs();
                        
                        let status = {
                            label: 'Up to Date',
                            color: 'success',
                            bgColor: 'bg-green-100',
                            textColor: 'text-green-800'
                        };

                        if (today.isAfter(renewalDate)) {
                            if (today.isAfter(dueDate)) {
                                status = {
                                    label: 'Overdue',
                                    color: 'error',
                                    bgColor: 'bg-red-100',
                                    textColor: 'text-red-800'
                                };
                            } else {
                                status = {
                                    label: 'Renewal Due',
                                    color: 'warning',
                                    bgColor: 'bg-yellow-100',
                                    textColor: 'text-yellow-800'
                                };
                            }
                        }

                        return (
                            <tr
                                key={document.id}
                                className="bg-white border-b hover:bg-gray-50 cursor-pointer"
                                onClick={() => handleDocumentClick(document)}
                            >
                                <td className="px-6 py-4">
                                    <Chip
                                        label={!document.renewal_history?.current_renewal?.renewal_date ? 
                                            'Generic' : 
                                            document.status || 'Up to Date'
                                        }
                                        size="small"
                                        className={!document.renewal_history?.current_renewal?.renewal_date ? 
                                            'bg-blue-100 text-blue-800' : 
                                            'bg-green-100 text-green-800'
                                        }
                                    />
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900">
                                    {document.document_id}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900">
                                    {document.document_number}
                                </td>
                                <td className="px-6 py-4">{document.document_name}</td>
                                <td className="px-6 py-4">
                                    {dayjs(document.initial_issue_date).format('YYYY-MM-DD')}
                                </td>
                                {!isGeneric && (
                                    <>
                                        <td className="px-6 py-4">
                                            {dayjs(document.renewal_history?.current_renewal?.renewal_date)
                                                .utc()
                                                .format('YYYY-MM-DD')}
                                        </td>
                                        <td className="px-6 py-4">
                                            {dayjs(document.renewal_history?.current_renewal?.due_date)
                                                .utc()
                                                .format('YYYY-MM-DD')}
                                        </td>
                                    </>
                                )}
                                <td className="px-6 py-4">
                                    <span className={`px-2 py-1 text-xs font-semibold rounded-full ${getPriorityStyle(document.priority)}`}>
                                        {document.priority.toUpperCase()}
                                    </span>
                                </td>
                                <td className="px-6 py-4">
                                    <div className="flex gap-2">
                                        <Tooltip title="Print">
                                            <IconButton onClick={(e) => handlePrintDocument(document, e)} size="small">
                                                <PrintIcon className="w-5 h-5" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Edit">
                                            <IconButton onClick={(e) => handleEditClick(document, e)} size="small">
                                                <EditIcon className="w-5 h-5" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <IconButton onClick={(e) => handleDeleteClick(document, e)} size="small">
                                                <DeleteIcon className="w-5 h-5" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default DocumentDashboard; 