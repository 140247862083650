import React, { useRef, useState, useEffect } from 'react';
import { CheckCircle, Calendar, Clock, AlertCircle, RotateCcw, ChevronLeft, ChevronRight } from 'lucide-react';
import { fetchEmployeeTaskStatus } from '../../api/endpoints/APIEndpoints';
import { selectUserData } from '../../store/store/authSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectEmployeeTaskStatus, setEmployeeTaskStatus } from '../../store/store/mainSlice';
interface TaskStatus {
  completed: number;
  scheduled: number;
  pastDue: number;
  due: number;
  followUp: number;
}

interface Employee {
  id: number;
  name: string;
  taskStatus: TaskStatus;
}

const CircularProgress: React.FC<{ percentage: number; color: string }> = ({ percentage, color }) => {
  const radius = 20;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <svg className="w-16 h-16">
      <circle
        className="text-gray-200"
        strokeWidth="5"
        stroke="currentColor"
        fill="transparent"
        r={radius}
        cx="32"
        cy="32"
      />
      <circle
        className={`text-${color}`}
        strokeWidth="5"
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
        stroke="currentColor"
        fill="transparent"
        r={radius}
        cx="32"
        cy="32"
        transform="rotate(-90 32 32)"
      />
      <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="10" fontWeight="bold">
        {isNaN(percentage) ? 0 : percentage}%
      </text>
    </svg>
  );
};

export const TaskStatusCard: React.FC<{ employee: Employee }> = ({ employee }) => {
  const totalTasks = Object.values(employee.taskStatus).reduce((a, b) => a + b, 0);

  const getPercentage = (value: number) => Math.round((value / totalTasks) * 100);


  const statusColors = {
    completed: 'green-500',
    scheduled: 'blue-500',
    pastDue: 'yellow-500',
    due: 'red-500',
    followUp: 'purple-500'
  };

  const statusIcons = {
    completed: CheckCircle,
    scheduled: Calendar,
    pastDue: Clock,
    due: AlertCircle,
    followUp: RotateCcw
  };



  return (
    <div className="flip-card w-full h-40">
      <div className="flip-card-inner w-full">
        <div className="flip-card-front bg-white rounded-lg shadow-md p-4 flex flex-col justify-between">
          <h2 className="text-xl font-bold mb-3 text-center">{employee.name}</h2>
          <div className="grid grid-cols-5 gap-2">
            {Object.entries(employee.taskStatus).map(([key, value]) => (
              <CircularProgress
                key={key}
                percentage={getPercentage(value)}
                color={statusColors[key as keyof typeof statusColors]}
              />
            ))}
          </div>
        </div>
        <div className="flip-card-back bg-white rounded-lg shadow-md p-4 flex flex-col justify-center">
          <p className="font-semibold text-center mb-2">Total Tasks: {totalTasks}</p>
          <div className="grid grid-cols-2 gap-3">
            {Object.entries(employee.taskStatus).map(([key, value]) => {
              const Icon = statusIcons[key as keyof typeof statusIcons];
              return (
                <div key={key} className="flex items-center justify-between">
                  <div className="flex items-center">
                    <Icon className={`w-5 h-5 mr-2 text-${statusColors[key as keyof typeof statusColors]}`} />
                    <span className="text-sm">{key.charAt(0).toUpperCase() + key.slice(1)}:</span>
                  </div>
                  <span className="font-semibold">{value}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const EmployeeTaskStatusDashboard: React.FC<{}> = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);
  const userDetails = useSelector(selectUserData, shallowEqual);
  const dispatch = useDispatch();
  const employeeTaskStatus = useSelector(selectEmployeeTaskStatus, shallowEqual);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowPrevButton(scrollLeft > 0);
      setShowNextButton(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  useEffect(() => {
    const fetchAllTeamsTaskStatus = async () => {
        try {
            if (!userDetails?.teams || userDetails.teams.length === 0) return;

            // Create promises for each team's task status
            const taskStatusPromises = userDetails.teams.map(team => 
                fetchEmployeeTaskStatus.post({ teamId: team.team_id })
            );

            // Wait for all requests to complete
            const allTeamsTaskStatus = await Promise.all(taskStatusPromises);

            // Create a Map to store unique employee task statuses
            const taskStatusMap = new Map();

            // Process each team's response
            allTeamsTaskStatus.forEach((teamData) => {
                // Process each team member in the teamMembers array
                teamData.teamMembers.forEach((employee: any) => {
                    const employeeId = employee.id;

                    if (taskStatusMap.has(employeeId)) {
                        // If employee exists, combine their task statuses
                        const existing = taskStatusMap.get(employeeId);
                        taskStatusMap.set(employeeId, {
                            ...employee,
                            name: employee.name,
                            role: employee.role,
                            companies: employee.companies,
                            taskStatus: {
                                completed: (existing.taskStatus.completed || 0) + (employee.taskStatus.completed || 0),
                                scheduled: (existing.taskStatus.scheduled || 0) + (employee.taskStatus.scheduled || 0),
                                pastDue: (existing.taskStatus.pastDue || 0) + (employee.taskStatus.pastDue || 0),
                                due: (existing.taskStatus.due || 0) + (employee.taskStatus.due || 0),
                                followUp: (existing.taskStatus.followUp || 0) + (employee.taskStatus.followUp || 0)
                            }
                        });
                    } else {
                        // Add new employee with all their details
                        taskStatusMap.set(employeeId, {
                            id: employee.id,
                            name: employee.name,
                            role: employee.role,
                            companies: employee.companies,
                            taskStatus: {
                                completed: employee.taskStatus.completed || 0,
                                scheduled: employee.taskStatus.scheduled || 0,
                                pastDue: employee.taskStatus.pastDue || 0,
                                due: employee.taskStatus.due || 0,
                                followUp: employee.taskStatus.followUp || 0
                            }
                        });
                    }
                });
            });

            // Convert Map back to array
            const uniqueTaskStatuses = Array.from(taskStatusMap.values());
            console.log('Combined task statuses:', uniqueTaskStatuses); // Debug log
            
            // Dispatch combined results
            dispatch(setEmployeeTaskStatus(uniqueTaskStatuses));

        } catch (error) {
            console.error('Error fetching task status:', error);
        }
    };

    fetchAllTeamsTaskStatus();
}, [userDetails?.teams, dispatch]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
      handleScroll(); // Check initial state
    }
    return () => scrollContainer?.removeEventListener('scroll', handleScroll);
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = scrollContainerRef.current.clientWidth;
      scrollContainerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  console.log(employeeTaskStatus)
  return (
    <div className="container mx-auto p-4 relative">
      <div
        ref={scrollContainerRef}
        className="flex space-x-6 overflow-x-auto scrollbar-hide"
        style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
      >
        {employeeTaskStatus?.map((employee: Employee) => (
          <div key={employee.id} className="w-80 flex-shrink-0">
            <TaskStatusCard employee={employee} />
          </div>
        ))}
      </div>
      {showPrevButton && (
        <button
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-500 bg-opacity-50 p-2 rounded-full"
          onClick={() => scroll('left')}
        >
          <ChevronLeft className="text-white" />
        </button>
      )}
      {showNextButton && (
        <button
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-500 bg-opacity-50 p-2 rounded-full"
          onClick={() => scroll('right')}
        >
          <ChevronRight className="text-white" />
        </button>
      )}
    </div>
  );
};

export default EmployeeTaskStatusDashboard;