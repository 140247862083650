import React, { useEffect, useState } from 'react';
import { deleteAppliance, fetchAllAppliances, fetchAllChecklist, fetchRoutines, getAllDepartments, getAllProjects } from '../../api/endpoints/APIEndpoints';
import { Appliance } from '../../types/tasks';
import { Tabs, Tab, Box, Typography, Tooltip, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import BackButton from '../../components/common/BackButton';
import Button from '../../components/common/Button';
import Modal from '../../components/common/Modal';
import AddAppliance from '../../components/app/AddAppliance';
import { Checklists, Department, InspectionRoutine, Project } from '../../types/common';
import ApplianceEditForm from '../../components/app/ApplianceEditForm';
import Banner from '../../components/common/Banner';
import ConfirmationPopup from '../../components/app/ConfirmationPopup';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../../store/store/authSlice';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from '../../components/common/Loader'; 
import { FaPrint } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import NewModal from '../../components/common/newModal';

const StyledTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        backgroundColor: '#1890ff',
    },
});

const StyledTab = styled(Tab)({
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    '&.Mui-selected': {
        color: '#1890ff',
    },
});

const Appliances: React.FC = () => {
    const [applianceData, setApplianceData] = useState<Appliance[]>([])
    const [showAddApplianceModal, setShowAddApplianceModal] = useState(false)
    const [allDepartment, setAllDepartment] = useState<any>()
    const [addParentQues, setAddParentQues] = useState(false);
    const [projects, setProjects] = useState<Project[]>()
    const [allChecklistData, setAllChecklistData] = useState<Checklists[]>()
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedAppliance, setSelectedAppliance] = useState<Appliance | null>(null);
    const [routineOptions, setRoutineOptions] = useState<InspectionRoutine[]>([]);
    const [applianceToDelete, setApplianceToDelete] = useState<Appliance | null>(null);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [view, setView] = useState("appliance_dashboard")
    const [tabValue, setTabValue] = useState(0);
    const userData = useSelector(selectUserData, shallowEqual)
    const [selectedApplianceDetails, setSelectedApplianceDetails] = useState<Appliance | null>(null);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredAppliances, setFilteredAppliances] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true); 

    const handleApplianceEdit = (appliance: Appliance) => {
        setSelectedAppliance(appliance);
        setShowEditModal(true);
    }

    const openConfirmation = (appliance: any) => {
        setApplianceToDelete(appliance);
        setIsConfirmationOpen(true);
    };

    const closeConfirmation = () => {
        setIsConfirmationOpen(false);
        setApplianceToDelete(null);
    };

    const confirmDelete = () => {
        if (applianceToDelete) {
            handleApplianceDelete(applianceToDelete);
        }
        closeConfirmation();
    };

    const handleApplianceDelete = (appliance: Appliance) => {
        setIsLoading(true);
        deleteAppliance.delete({ applianceId: appliance.appliance_id })
            .then(res => {
                console.log("appliance deleted", res)
                let updatedApplianceData = applianceData.filter((item) => item.appliance_id !== appliance.appliance_id)
                setApplianceData(updatedApplianceData)
            })
            .catch((error) => console.log("ERROR!!", error))
            .finally(() => setIsLoading(false));
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        setIsLoading(true);
        
        const formatDepartmentData = (departments: any[], userData: any) => {
            return departments?.map((department: {
                department_id: any;
                department_name: any;
            }) => {
                if (userData?.role?.level === 4) {
                    return {
                        value: department?.department_id,
                        label: department?.department_name
                    };
                }
    
                if (userData?.role?.level === 2 || userData?.role?.level === 3) {
                    const hasAccess = userData.departments.some(
                        (userDept: any) => userDept.department_id === department.department_id
                    );
                    return hasAccess ? {
                        value: department?.department_id,
                        label: department?.department_name
                    } : null;
                }
    
                return null;
            }).filter(Boolean) ?? [];
        };
    
        const fetchData = async () => {
            try {
                const [
                    appliances,
                    departments,
                    projects,
                    checklists,
                    routines
                ] = await Promise.all([
                    fetchAllAppliances.post({ employeeId: userData?.employee_id }),
                    getAllDepartments.get(),
                    getAllProjects.post({ employee_id: userData?.employee_id }),
                    fetchAllChecklist.post({}),
                    fetchRoutines.get()
                ]);
    
                setApplianceData(appliances);
                setAllDepartment(formatDepartmentData(departments, userData));
                setProjects(projects);
                setAllChecklistData(checklists);
                setRoutineOptions(routines);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchData();
    }, [userData?.employee_id, userData?.role?.level, userData?.departments]);

    useEffect(() => {
        if (!applianceData) return;

        const filtered = applianceData.filter((appliance: any) => {
            const searchableFields = [
                appliance.appliance_name,
                appliance.appliance_id,
                appliance.tag_id,
                appliance.appliance_description,
                appliance.location,
                // Add any other fields you want to search through
            ];

            return searchableFields.some(field =>
                field?.toString().toLowerCase().includes(searchTerm.toLowerCase())
            );
        });

        setFilteredAppliances(filtered);
    }, [searchTerm, applianceData]);

    const handleAddAppliance = (dellimeter?: string) => {
        if (dellimeter === "add_subpart") {
            setAddParentQues(true)
        } else {
            setAddParentQues(false)
        }
        setShowAddApplianceModal(true)
        setView("add_appliance")

    }

    const handleCancel = () => {
        setShowAddApplianceModal(false)
        setShowEditModal(false)
    }

    const handleApplianceClick = (appliance: Appliance) => {
        setSelectedApplianceDetails(appliance);
        setIsDetailsModalOpen(true);
    };
    const exportToExcel = () => {
        // Format the data for Excel
        const excelData = filteredAppliances.map(appliance => ({
            'Appliance ID': appliance.appliance_id,
            'Tag ID': appliance?.Tag?.tag_id,
            'Tag Name': appliance?.Tag?.tag_name,
            'Name': appliance.appliance_name,
            'Status': appliance.appliance_status,
            'Description': appliance.appliance_description,
            'Parent Appliance ID': appliance.parent_appliance_id || 'N/A',
            'Manufacturer': appliance.manufacturer || 'N/A',
            'Model': appliance.model || 'N/A',
            'Serial Number': appliance.serial_number || 'N/A',
            'Purchase Date': appliance.purchase_date || 'N/A',
            'Multiple Departments': appliance.is_multi_department ? "Yes" : "No",
            // Department Details
            ...appliance.departments?.reduce((acc: any, dept: any, index: number) => ({
                ...acc,
                [`Department ${index + 1}`]: dept.department_name,
                [`Project ${index + 1}`]: dept.project_name,
                [`Inspection Routine ID ${index + 1}`]: dept.inspection_routine_id || 'N/A',
                [`Maintenance Routine ID ${index + 1}`]: dept.maintenance_routine_id || 'N/A',
                [`Inspection Checklist ID ${index + 1}`]: dept.inspection_checklist_id || 'N/A',
                [`Maintenance Checklist ID ${index + 1}`]: dept.maintenance_checklist_id || 'N/A',
            }), {}),
        }));

        // Create worksheet
        const ws = XLSX.utils.json_to_sheet(excelData);
        
        // Create workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Appliances");
        
        // Generate Excel file and trigger download
        XLSX.writeFile(wb, `Appliances_${new Date().toISOString().split('T')[0]}.xlsx`);
    };

    if (isLoading) {
        return <Loader />
    }

    return (
        <div className="w-full p-5 h-[90vh] overflow-y-auto">
            <Typography variant="h4" component="h1" gutterBottom>
                Appliances Management
            </Typography>

            <StyledTabs value={tabValue} onChange={handleTabChange} aria-label="appliance tabs">
                <StyledTab label="Dashboard" />
                <StyledTab label="Add Appliance" />
                <StyledTab label="Add Subpart" />
            </StyledTabs>

            <Box sx={{ mt: 3 }}>
                {tabValue === 0 && (
                    <>
                        <div className="flex items-center mb-5 w-full">
                            <input
                                type="text"
                                placeholder="Search by name, ID, tag, description..."
                                className="w-full md:w-1/2 p-2 border rounded mr-2"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <Button label="Search" onClick={() => { }} className="bg-green-500 text-white" />
                        </div>
                        <div className='flex justify-between items-center mb-5'>
                            <div className='w-1/3'>
                                <Banner title='Total Appliances' count={filteredAppliances?.length} />
                            </div>
                            <Tooltip title="Export to Excel">
                                <IconButton 
                                    onClick={exportToExcel}
                                    className="bg-blue-500 hover:bg-blue-600 text-white"
                                    size="large"
                                >
                                    <FaPrint />
                                </IconButton>
                            </Tooltip>
                        </div>
                        {/* Table component */}
                        <div className="overflow-x-auto">
                            <table className="min-w-full bg-white">
                                <thead>
                                    <tr className="border-b text-left font-bold text-sm">
                                        <th className="p-2">Appliance Id</th>
                                        <th className="p-2">Name</th>
                                        <th className="p-2">Status</th>
                                        <th className="p-2">Description</th>
                                        <th className="p-2">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        filteredAppliances?.map((appliance) => {
                                            return (
                                                <tr className="border-b text-left hover:bg-gray-50 text-sm">
                                                    <td className="text-blue-600 p-2 cursor-pointer hover:underline" onClick={() => handleApplianceClick(appliance)}>{appliance.appliance_id}</td>
                                                    <td className="p-2">{appliance.appliance_name}</td>
                                                    <td className="p-2">{appliance.appliance_status}</td>
                                                    <td className="p-2">{appliance.appliance_description}</td>
                                                    <td className="p-2 flex gap-2">
                                                        <Tooltip title="Edit">
                                                            <IconButton onClick={() => handleApplianceEdit(appliance)} size="small">
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => openConfirmation(appliance)} size="small">
                                                                <DeleteIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {/* Add more rows as needed */}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
                {tabValue === 1 && (
                    <AddAppliance
                        departmentOptions={allDepartment || []}
                        projectOptions={projects?.map((item) => ({
                            label: item.project_name,
                            value: item.project_id.toString(),
                        })) || []}
                        checklistData={allChecklistData?.map((item) => ({
                            label: item.name,
                            value: item.checklist_id.toString(),
                            checklist_type: item.checklist_type,
                            department_id: item.department?.department_id
                        })) || []}
                        routineOptions={routineOptions}
                        addParentQues={false}
                        onSave={function (appliance: Partial<Appliance>): void {
                            // Implement save functionality
                        }}
                        onCancel={function (): void {
                            setTabValue(0);
                        }}
                    />
                )}
                {tabValue === 2 && (
                    <AddAppliance
                        departmentOptions={allDepartment || []}
                        projectOptions={projects?.map((item) => ({
                            label: item.project_name,
                            value: item.project_id.toString(),
                        })) || []}
                        checklistData={allChecklistData?.map((item) => ({
                            label: item.name,
                            value: item.checklist_id.toString(),
                            checklist_type: item.checklist_type,
                            department_id: item?.department?.department_id
                        })) || []}
                        routineOptions={routineOptions}
                        addParentQues={true}
                        onSave={function (appliance: Partial<Appliance>): void {
                            // Implement save functionality
                        }}
                        onCancel={function (): void {
                            setTabValue(0);
                        }}
                    />
                )}
            </Box>

            <NewModal isOpen={showEditModal} onClose={handleCancel} title={`Update Appliance: ${selectedAppliance?.appliance_id}`}>
                {(selectedAppliance) && (
                    <div className='min-h-[70vh]overflow-y-auto'>
                        <ApplianceEditForm
                            appliance={selectedAppliance}
                            departmentOptions={allDepartment || []}
                            projectOptions={projects?.map((item) => ({
                                label: item.project_name,
                                value: item.project_id.toString(),
                            })) || []}
                            addParentQues={addParentQues}
                            checklistData={allChecklistData?.map((item) => ({
                                label: item.name,
                                value: item.checklist_id.toString(),
                                checklist_type: item.checklist_type
                            })) || []}
                            routineOptions={routineOptions}
                            onCloseForm={handleCancel}
                        />
                    </div>
                )}
            </NewModal>

            <ConfirmationPopup
                isOpen={isConfirmationOpen}
                onConfirm={confirmDelete}
                onCancel={closeConfirmation}
            />

            <NewModal isOpen={isDetailsModalOpen} onClose={() => setIsDetailsModalOpen(false)} title={`Appliance Details: ${selectedApplianceDetails?.appliance_id}`}>
                {selectedApplianceDetails && (
                    <div className="p-4">
                        <div className='flex justify-between items-center py-1'>
                            <p><strong>Tag ID:</strong></p>
                            <p>{selectedApplianceDetails.appliance_id}</p>
                        </div>
                        <div className='flex justify-between items-center py-1'>
                            <p><strong>Tag:</strong></p>
                            <p>{selectedApplianceDetails?.tag_id}</p>
                        </div>
                        <div className='flex justify-between items-center py-1'>
                            <p><strong>Name:</strong></p>
                            <p>{selectedApplianceDetails.appliance_name}</p>
                        </div>
                        <div className='flex justify-between items-center py-1'>
                            <p><strong>Status:</strong></p>
                            <p>{selectedApplianceDetails.appliance_status}</p>
                        </div>
                        <div className='flex justify-between items-center py-1'>
                            <p><strong>Description:</strong></p>
                            <p>{selectedApplianceDetails.appliance_description}</p>
                        </div>
                        <div className='flex justify-between items-center py-1'>
                            <p><strong>Department:</strong></p>
                            <p>{selectedApplianceDetails?.departments?.map((item: any) => item.department_name).join(', ')}</p>
                        </div>
                        <div className='flex justify-between items-center py-1'>
                            <p><strong>Project:</strong></p>
                            <p>{selectedApplianceDetails?.departments?.map((item: any) => item.project_name).join(', ')}</p>
                        </div>
                        <div className='flex justify-between items-center py-1'>
                            <p><strong>Multiple Department:</strong></p>
                            <p>{selectedApplianceDetails?.is_multi_department ? "Yes" : "No"}</p>
                        </div>
                        <div className='flex justify-between items-center py-1'>
                            <p><strong>Geolocation:</strong></p>
                            <p>{selectedApplianceDetails?.image_geolocation?.filter((x:any)=>x)?.join(",") || "N/A" } </p>
                        </div>
                        <div className='flex justify-between items-center py-1'>
                            <p><strong>Inspection Routine:</strong></p>
                            <p>{selectedApplianceDetails?.inspectionRoutine?.routine_name || "N/A"}</p>
                        </div>
                        <div className='flex justify-between items-center py-1'>
                            <p><strong>Inspection Frequency:</strong></p>
                            <p>{selectedApplianceDetails?.inspectionRoutine?.due_date_offset ? selectedApplianceDetails?.inspectionRoutine?.due_date_offset + " days" : "N/A"}</p>
                        </div>
                        <div className='flex justify-between items-center py-1'>
                            <p><strong>Maintenance Routine:</strong></p>
                            <p>{selectedApplianceDetails?.maintenanceRoutine?.routine_name || "N/A"}</p>
                        </div>
                        <div className='flex justify-between items-center py-1'>
                            <p><strong>Maintenance Frequency:</strong></p>
                            <p>{selectedApplianceDetails?.maintenanceRoutine?.due_date_offset ? selectedApplianceDetails?.maintenanceRoutine?.due_date_offset + " days" : "N/A"}</p>
                        </div>
                        {/* Add more details as needed */}
                    </div>
                )}
            </NewModal>
        </div>
    );
};

export default Appliances;
