import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectUserData } from '../../store/store/authSlice';
import { getTeamMemebers, fetchAllEmployees, fetchAllTeams, fetchTeamsWithProjectsAndMembers, fetchEmployeeByDepartment, allEmployeePunchRecords } from '../../api/endpoints/APIEndpoints';
import Banner from '../../components/common/Banner';
import { Employee, Team, ViewStates } from '../../types/teamTypes';
import TeamPageNavigation from '../../components/app/TeamPageNavigaton';
import EmployeeTaskStatusDashboard from '../../components/app/EmployeeTaskStatusDashboard';
import ScheduleInspectionView from '../../components/app/ScheduleInspectionView';
import AddMember from '../../components/app/AddMember';
import TeamPerformanceTable from '../../components/app/TeamPerformanceTable';
import EmployeeRating from '../../components/app/EmployeeRating';
import ResponsibilityReassignmentForm from '../../components/app/ResponsibilityReassignmentForm';
import TeamMembers from '../../components/app/TeamMembers';
import AddMemberModal from '../../components/app/AddMemberModal';
import { setAllEmployees, setAllTeams } from '../../store/store/mainSlice';
import PermissionView from '../../components/app/PermissionView';
import Loader from '../../components/common/Loader';
import EmployeeAttendanceTable from '../../components/app/EmployeeAttendanceTable';

const TeamsDashboard: React.FC = () => {
  const [teams, setTeams] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [views, setViews] = useState<ViewStates>({
    scheduleInspectionView: false,
    addMember: false,
    performanceView: false,
    rating: false,
    permissionView: false,
    manageResponsibility: false,
    attendanceView: false
  });
  const [allDepartmentEmployees, setAllDepartmentEmployees] = useState<Employee[]>([]);
  const userDetails = useSelector(selectUserData, shallowEqual);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [attendanceData, setAttendanceData] = useState<any[]>([]);

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          fetchTeamData(),
          fetchEmployeeData(),
          getAllTeams(),
          fetchAttendanceData()
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError('An error occurred while fetching data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, []);

  const fetchTeamData = async () => {
    try {
        if (!userDetails?.departments) return;

        const employeePromises = userDetails.departments.map(dept => 
            fetchEmployeeByDepartment.post({ departmentId: dept.department_id })
        );

        // Wait for all requests to complete
        const departmentEmployees = await Promise.all(employeePromises);
        
        // Create a Map to store unique employees with their departments
        const employeeMap = new Map();

        // Process each department's employees
        departmentEmployees.forEach((deptEmployees, index) => {
            const currentDept = userDetails.departments[index];
            
            deptEmployees.forEach((employee: any) => {
                const employeeKey = employee.employeeId;
                
                if (employeeMap.has(employeeKey)) {
                    // If employee exists, add the new department
                    const existingEmployee = employeeMap.get(employeeKey);
                    if (!existingEmployee.departments.some(
                        (dept: any) => dept.departmentId === currentDept.department_id
                    )) {
                        existingEmployee.departments.push({
                            departmentId: currentDept.department_id,
                            departmentName: currentDept.department_name
                        });
                    }
                } else {
                    // If employee doesn't exist, create new entry
                    employeeMap.set(employeeKey, {
                        ...employee,
                        departments: [{
                            departmentId: currentDept.department_id,
                            departmentName: currentDept.department_name
                        }]
                    });
                }
            });
        });

        // Convert Map to array and log the results
        const uniqueEmployees = Array.from(employeeMap.values());
        console.log('Unique employees:', uniqueEmployees);
        
        setTeams(uniqueEmployees);
        
    } catch (err) {
        console.error('Error in fetchTeamData:', err);
        setError('An error occurred while fetching teams');
        throw err;
    }
  };

  const getAllTeams = async () => {
    try {
      const res = await fetchAllTeams.get();
      dispatch(setAllTeams(res));
    } catch (error) {
      console.log("ERROR!!", error);
      throw error;
    }
  }

  const fetchEmployeeData = async () => {
    try {
      const res = await fetchAllEmployees.get();
      setAllDepartmentEmployees(res);
      dispatch(setAllEmployees(res));
    } catch (error) {
      console.log("ERROR!!", error);
      throw error;
    }
  };

  const fetchAttendanceData = async () => {
    try {
      // Replace with your actual API endpoint
      allEmployeePunchRecords.get()
      .then((data) => {
        setAttendanceData(data);
        console.log("attendanceData", data);
      })
      .catch((error) => {
        console.error('Error fetching attendance data:', error);
      });
    } catch (error) {
      console.error('Error fetching attendance data:', error);
    }
  };

  if (isLoading) return <Loader />;
  if (error) return <div className="text-red-500 text-center">{error}</div>;

  const handleViewChange = (newView: Partial<ViewStates>) => {
    setViews((prevViews) => {
      // First, set all views to false
      const resetViews = Object.keys(prevViews).reduce((acc, key) => {
        acc[key as keyof ViewStates] = false;
        return acc;
      }, {} as ViewStates);

      // Then, apply the new view
      return { ...resetViews, ...newView };
    });
  };


  const employees = [
    { name: 'Sahil Sangwan', rating: 5 },
    { name: 'S K Sharma', rating: 3 },
    { name: 'Sankalp Shree', rating: 4 },
    { name: 'Soumya Prakash', rating: 4 },
    { name: 'Tushar Singh', rating: 5 },
  ];

  const teamMembers = teams?.map((employee: any) => ({
    id: employee.employee_id,
    name: `${employee.first_name} ${employee.last_name}`
  }));

  const handleReassignment = (fromMember: string, toMember: string) => {
    console.log(`Reassigning from ${fromMember} to ${toMember}`);
    // Implement the logic for reassigning responsibilities here
  };

  console.log("teamMembers", teams, teamMembers);

  return (
    <div className="container mx-auto p-4 h-[90vh] overflow-y-scroll">
      <div className='flex justify-between items-center'>
        <h1 className="text-2xl text-left font-bold mb-4">Teams Dashboard</h1>
        {userDetails?.role.level === 4 && (
          <button
            className="bg-gray-700 text-white px-4 py-2 rounded-md"
            onClick={() => setIsModalOpen(true)}
          >
            Add members
          </button>
        )}
      </div>
      <AddMemberModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <div className='flex justify-between items-center'>
        <TeamPageNavigation onViewChange={handleViewChange} />
        <div className='w-1/4 py-4'>
          <Banner title='Total team members' count={teams?.length || 0} />
        </div>
      </div>
      {/* {views.scheduleInspectionView && <ScheduleInspectionView teamMembers={teams} />} */}
      {views.addMember && <AddMember allDepartmentEmployees={allDepartmentEmployees} userDetails={userDetails} />}
      {views.performanceView && <TeamPerformanceTable />}
      {views.rating && <EmployeeRating employees={employees} />}
      {views.manageResponsibility && <ResponsibilityReassignmentForm teamMembers={teams || []} onSubmit={handleReassignment} />}
      {views.permissionView && <PermissionView />}
      {views.attendanceView && <EmployeeAttendanceTable attendanceData={attendanceData} />}
      {!Object.values(views).some(Boolean) && teams && <TeamMembers teams={teams} />}
    </div>
  );
};

export default TeamsDashboard;
