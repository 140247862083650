import React, { useEffect, useState } from 'react';
import { deleteTasks, fetchAllTasksWithLevel, fetchAllEmployees, fetchAllTasks, getTeamMemebers, updateTasks, fetchTeamsWithProjectsAndMembers, fetchEmployeeByDepartment, fetchAllTeams } from '../../api/endpoints/APIEndpoints';
import { Task } from '../../types/tasks';
import BackButton from '../../components/common/BackButton';
import { Employee } from '../../types/employee';
import Modal from '../../components/common/Modal';
import TaskEditForm from '../../components/app/TaskEditForm';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectUserData } from '../../store/store/authSlice';
import Filter from '../../components/common/Filter';
import Accordion from '../../components/common/Accordion';
import Banner from '../../components/common/Banner';
import Button from '../../components/common/Button';
import ScheduleInspectionView from '../../components/app/ScheduleInspectionView';
import { Team } from '../../types/common';
import ReassignTaskForm from '../../components/app/ReAssignTaskForm';
import ConfirmationPopup from '../../components/app/ConfirmationPopup';
import { Tabs, Tab, Box, Typography, IconButton, Tooltip, Table, TableBody, TableCell, TableContainer, Paper, Chip, TableRow, TableHead } from '@mui/material';
import { styled } from '@mui/material/styles';
import Loader from '../../components/common/Loader';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { selectAllIssues, setAllTeams } from '../../store/store/mainSlice';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IssueDetailsModal from '../../components/app/IssueDetailsModal';
import { formatTableDataForPDF } from '../../utils/common';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Printer from '@mui/icons-material/Print';
import NewModal from '../../components/common/newModal';

const StyledTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        backgroundColor: '#1890ff',
    },
});

const StyledTab = styled(Tab)({
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    '&.Mui-selected': {
        color: '#1890ff',
    },
});

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 600,
}));

const InspectionTasks: React.FC = () => {
    const [tasksData, setTasksData] = useState<any>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [selectedTask, setSelectedTask] = useState<Task | null>(null);
    const userData = useSelector(selectUserData, shallowEqual);
    const [filteredData, setFilteredData] = useState<any>([])
    const [views, setViews] = useState({
        scheduleInspectionView: false,
        reAssignTaskView: false,
    })
    const [teams, setTeams] = useState<Team>();
    const [taskToDelete, setTaskToDelete] = useState<Task | null>(null);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [selectedTaskInfo, setSelectedTaskInfo] = useState<any>(null); // State for selected task info
    const [isTaskInfoPopupOpen, setIsTaskInfoPopupOpen] = useState(false); // State for popup visibility
    const [isLoading, setIsLoading] = useState(true);
    const reportedIssues = useSelector(selectAllIssues, shallowEqual);
    const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [showTaskModal, setShowTaskModal] = useState(false);
    const [showIssueModal, setShowIssueModal] = useState(false);
    const [modalDetails, setModalDetails] = useState<any>(null);
    const [selectedFilter, setSelectedFilter] = useState<any>(null);
    const dispatch = useDispatch();

    const fetchAllData = async () => {
        setIsLoading(true);
        try {
            const [tasksRes, employeesRes, teamsRes] = await Promise.all([
                fetchAllTasksWithLevel.post({ employee_id: userData?.employee_id }),
                fetchAllEmployees.get(),
                fetchEmployeeByDepartment.post({ departmentId: userData?.departments[0].department_id }),
                fetchAllTeams.get()
            ]);

            setTasksData(tasksRes.tasks);
            setFilteredData(tasksRes.tasks);
            setEmployees(employeesRes);
            setTeams(teamsRes);
            dispatch(setAllTeams(teamsRes));
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAllData();
    }, [])

    const handleTaskEdit = (task: Task) => {
        setSelectedTask(task);
        setShowEditModal(true);
    }

    const handleDeleteTask = (task: any) => {
        deleteTasks.delete({
            id: task?.task_id || task?.schedule_id,
            "task_type": "inspection"
        }).then(res => console.log("Task Deleted successfully!", res))
            .catch((error) => console.log("Error", error))
    }

    const handleSave = (updatedTask: Task) => {
        updateTasks.post({
            updatedTask
        }).then((res) => console.log("res", res))
            .catch((error) => console.log("ERROR!!", error))
        setShowEditModal(false);
        // WIP: updated data to be saved in db
    }

    const totalInspectionsCompleted = () => {
        let completedTasks = tasksData?.filter((task: { assignedEmployee: { employee_id: number | undefined; }; status: string; }) => {
            return task.status === 'satisfactory';
        });
        return completedTasks?.length;
    }

    const handleCancel = () => {
        setShowEditModal(false);
    }

    const openConfirmation = (task: any) => {
        setTaskToDelete(task);
        setIsConfirmationOpen(true);
    };

    const closeConfirmation = () => {
        setIsConfirmationOpen(false);
        setTaskToDelete(null);
    };

    const confirmDelete = () => {
        if (taskToDelete) {
            handleDeleteTask(taskToDelete);
        }
        closeConfirmation();
    };

    const handleFilterChange = (selectedFilter: string) => {
        const currentDate = new Date();
        const thirtyDaysAgo = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);

        const newFilteredTasks = tasksData.filter((task: { assignedEmployee: { employee_id: number | undefined; }; status: string; }) => {
            switch (selectedFilter) {
                case 'View All':
                    return true;
                case 'Self':
                    if (task.assignedEmployee.employee_id === userData?.employee_id) {
                        return task
                    }
                    break;
                case 'Completed':
                    return task.status?.toLowerCase() === 'completed';
                case 'Follow up':
                    return task.status?.toLowerCase() === 'follow up';
                case 'Pending':
                    return task.status?.toLowerCase() === 'pending';
                case 'Satisfactory':
                    return task.status?.toLowerCase() === 'satisfactory';
                case 'Due':
                    return task.status?.toLowerCase() === 'due';
                case 'Unsatisfactory':
                    return task.status?.toLowerCase() === 'unsatisfactory';
                case "Upcoming":
                    return task.status?.toLowerCase() ==='upcoming'
                // case 'Pending over 30 days':
                //     return task.status === 'due' && task?.dueDate <= thirtyDaysAgo;
                default:
                    return true;
            }
        });
        setSelectedFilter(selectedFilter);
        setFilteredData(newFilteredTasks);
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleTaskIdClick = (task: Task) => {
        setSelectedTaskInfo(task); // Set the selected task info
        setIsTaskInfoPopupOpen(true); // Open the popup
    };

    const closeTaskInfoPopup = () => {
        setIsTaskInfoPopupOpen(false); // Close the popup
        setSelectedTaskInfo(null); // Clear the selected task info
    };

    const getStatusColor = (status: string) => {
        switch (status.toLowerCase()) {
            case 'open':
                return 'warning';
            case 'resolved':
                return 'success';
            default:
                return 'error';
        }
    };

    const getPriorityColor = (priority: string) => {
        switch (priority.toLowerCase()) {
            case 'high':
                return 'error';
            case 'medium':
                return 'warning';
            case 'low':
                return 'success';
            default:
                return 'default';
        }
    };

    const toggleRowExpansion = (index: number) => {
        setExpandedRows(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const renderJointInspections = () => {
        const jointInspections = filteredData?.filter((task: any) =>
            task.type === "inspection_task" && task.appliance?.is_multi_department
        );

        const groupedInspections: { [key: string]: any[] } = {};
        jointInspections?.forEach((task: any) => {
            const key = task.appliance.appliance_id;
            if (!groupedInspections[key]) {
                groupedInspections[key] = [];
            }
            groupedInspections[key].push(task);
        });

        return (
            <div className="overflow-x-auto">
                {Object.values(groupedInspections).length>0 ?
                (<table className="min-w-full bg-white">
                    <thead>
                        <tr className="bg-gray-100 border-b text-left font-bold text-sm">
                            <th className="p-2"></th>
                            <th className="p-2">TaskId</th>
                            <th className="p-2">Appliance Name</th>
                            <th className="p-2">Tag Id</th>
                            <th className="p-2">Description</th>
                            <th className="p-2">Status</th>
                            <th className="p-2">Assigned To</th>
                            <th className="p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(groupedInspections).map((tasks: any[], index: number) => {
                            const mainTask = tasks[0];
                            const isExpanded = expandedRows[index] || false;
                            return (
                                <React.Fragment key={mainTask.task_id}>
                                    <tr className="border-b text-left hover:bg-gray-50 text-sm">
                                        <td className="p-2">
                                            <IconButton size="small" onClick={() => toggleRowExpansion(index)}>
                                                {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </td>
                                        <td className="p-2 cursor-pointer text-blue-500" onClick={() => handleTaskIdClick(mainTask)}>{mainTask.schedule_id}</td>
                                        <td className="p-2">{mainTask.appliance?.appliance_name}</td>
                                        <td className="p-2">{mainTask.appliance?.tag_id || "--"}</td>
                                        <td className="p-2">{mainTask.appliance?.appliance_description}</td>
                                        <td className="p-2">
                                            <span className={`px-2 py-1 rounded-full text-xs font-semibold ${mainTask.status === 'satisfactory' ? 'bg-green-100 text-green-800' :
                                                mainTask.status === 'unsatisfactory' ? 'bg-red-100 text-red-800' :
                                                    mainTask.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' :
                                                        'bg-gray-100 text-gray-800' // Default case if needed
                                                }`}>
                                                {mainTask.status}
                                            </span>
                                        </td>
                                        <td className="p-2">{mainTask.AssignedTo?.first_name && mainTask.AssignedTo?.last_name ?
                                            `${mainTask.AssignedTo.first_name} ${mainTask.AssignedTo.last_name}` :
                                            mainTask.assignedEmployee?.first_name && mainTask.assignedEmployee?.last_name ?
                                                `${mainTask.assignedEmployee.first_name} ${mainTask.assignedEmployee.last_name}` :
                                                "--"}</td>
                                        <td className="p-2 flex gap-2 items-center">
                                            <div className={`${(['satisfactory', 'unsatisfactory'].includes(mainTask.status)) && 'invisible'}`}>
                                                <Tooltip title="Edit">
                                                    <IconButton onClick={() => handleTaskEdit(mainTask)} size="small">
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div className={`${(['satisfactory', 'unsatisfactory'].includes(mainTask.status)) && 'invisible'}`}>
                                                <Tooltip title="Delete">
                                                    <IconButton onClick={() => openConfirmation(mainTask)} size="small">
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <Tooltip title="Print">
                                                <IconButton onClick={() => handlePrintTask(mainTask)} size="small">
                                                    <Printer className="w-5 h-5" />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                    {isExpanded && tasks.slice(1).map((linkedTask, linkedIndex) => (
                                        <tr key={`${mainTask.task_id}-linked-${linkedIndex}`} className="border-b text-left bg-blue-50 text-sm">
                                            <td className="p-2"></td>
                                            <td className="p-2 cursor-pointer text-blue-500" onClick={() => handleTaskIdClick(linkedTask)}>{linkedTask.schedule_id}</td>
                                            <td className="p-2">{linkedTask.appliance?.appliance_name}</td>
                                            <td className="p-2">{linkedTask.appliance?.tag_id || "--"}</td>
                                            <td className="p-2">{linkedTask.appliance?.appliance_description}</td>
                                            <td className="p-2">
                                                <span className={`px-2 py-1 rounded-full text-xs font-semibold ${linkedTask.status === 'satisfactory' ? 'bg-green-100 text-green-800' :
                                                    linkedTask.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' :
                                                        'bg-red-100 text-red-800'
                                                    }`}>
                                                    {linkedTask.status}
                                                </span>
                                            </td>
                                            <td className="p-2">{linkedTask.AssignedTo?.first_name && linkedTask.AssignedTo?.last_name ?
                                                `${linkedTask.AssignedTo.first_name} ${linkedTask.AssignedTo.last_name}` :
                                                linkedTask.assignedEmployee?.first_name && linkedTask.assignedEmployee?.last_name ?
                                                    `${linkedTask.assignedEmployee.first_name} ${linkedTask.assignedEmployee.last_name}` :
                                                    "--"}</td>
                                            <td className="p-2 flex gap-2 items-center">
                                                <div className={`${(['satisfactory', 'unsatisfactory'].includes(linkedTask.status)) && 'invisible'}`}>
                                                    <Tooltip title="Edit">
                                                        <IconButton onClick={() => handleTaskEdit(linkedTask)} size="small">
                                                            <EditIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <div className={`${(['satisfactory', 'unsatisfactory'].includes(linkedTask.status)) && 'invisible'}`}>
                                                    <Tooltip title="Delete">
                                                        <IconButton onClick={() => openConfirmation(linkedTask)} size="small">
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <Tooltip title="Print">
                                                    <IconButton onClick={() => handlePrintTask(linkedTask)} size="small">
                                                        <Printer className="w-5 h-5" />
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>):(<p className='text-center'>{"This is the calm before the storm. Hold tight!"}</p>)}
            </div>
        );
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const getFilteredData = (data: any[]) => {
        if (!searchTerm) return data;

        return data?.filter((item: any) => {
            const searchableFields = [
                item?.appliance?.appliance_name,
                item?.appliance?.tag_id,
                item?.task_id,
                item?.schedule_id,
                item?.issue_id,
                item?.name, // for issues
                item?.appliance?.appliance_description
            ];

            return searchableFields.some(field =>
                field?.toString().toLowerCase().includes(searchTerm)
            );
        });
    };

    const filteredInspectionTasks = getFilteredData(
        filteredData?.filter((task: any) => task.type === "inspection_task" && !task.appliance?.is_multi_department)
    );

    const filteredMaintenanceTasks = getFilteredData(
        filteredData?.filter((task: any) => task.type === "maintenance_task")
    );

    const filteredJointInspections = getFilteredData(
        filteredData?.filter((task: any) => task.type === "inspection_task" && task.appliance?.is_multi_department)
    );

    const filteredReportedIssues = getFilteredData(reportedIssues);

    // Add a type guard to differentiate between tasks and issues
    const isIssue = (item: any): boolean => {
        return Boolean(item.issue_id);
    };

    // Separate handlers for tasks and issues
    const handleRowClick = (item: any) => {
        if (isIssue(item)) {
            handleIssueClick(item);
        } else {
            handleTaskClick(item);
        }
    };

    // Handler for issues
    const handleIssueClick = (issue: any) => {
        setModalDetails({
            title: 'Issue Details',
            issue_id: issue.issue_id,
            appliance_name: issue.name,
            description: issue.description,
            status: issue.status,
            raised_date: issue.raised_date,
            reported_by: issue.reported_by,
            assigned_to: issue.assigned_to,
            resolution: issue.resolution,
            priority: issue.priority,
            reportedBy: issue.reportedBy,
            resolvedBy: issue.resolvedBy,
            // Add any other relevant issue fields
        });
        setShowIssueModal(true);
    };

    // Handler for tasks
    const handleTaskClick = (task: any) => {
        setModalDetails({
            appliance_name: task.appliance?.appliance_name,
            project_name: task.project?.project_name,
            scheduled_date: task.scheduled_date,
            due_date: task.due_date,
            task_type: task.type,
            assigned_to: task.assignedEmployee
        });
        setShowTaskModal(true);
    };

    // Column definitions for each table type
    const inspectionTaskColumns = [
        'TaskId',
        'Appliance Name',
        'Tag Id',
        'Description',
        'Status',
        'Assigned To'
    ];

    const maintenanceTaskColumns = [
        'TaskId',
        'Appliance Name',
        'Tag Id',
        'Description',
        'Status',
        'Assigned To'
    ];

    const jointInspectionColumns = [
        'TaskId',
        'Appliance Name',
        'Tag Id',
        'Description',
        'Status',
        'Assigned To'
    ];

    const issuesColumns = [
        'Issue ID',
        'Name',
        'Type',
        'Raised Date',
        'Status',
        'Priority',
        'Location'
    ];

    // Data formatters for each table type
    const formatInspectionTasksForPDF = () => {
        return filteredInspectionTasks?.map(task => ({
            'TaskId': task.task_id || task.schedule_id,
            'Appliance Name': task?.appliance?.appliance_name,
            'Tag Id': task.appliance?.tag_id || '--',
            'Description': task?.appliance?.appliance_description,
            'Status': task.status,
            'Assigned To': (task.AssignedTo?.first_name &&
                `${task.AssignedTo?.first_name} ${task.AssignedTo?.last_name}`) ||
                `${task.assignedEmployee?.first_name} ${task.assignedEmployee?.last_name}`
        }));
    };

    const formatMaintenanceTasksForPDF = () => {
        return filteredMaintenanceTasks?.map(task => ({
            'TaskId': task.task_id || task.schedule_id,
            'Appliance Name': task?.appliance?.appliance_name,
            'Tag Id': task.appliance?.tag_id || '--',
            'Description': task?.appliance?.appliance_description,
            'Status': task.status,
            'Assigned To': (task.AssignedTo?.first_name &&
                `${task.AssignedTo?.first_name} ${task.AssignedTo?.last_name}`) ||
                `${task.assignedEmployee?.first_name} ${task.assignedEmployee?.last_name}`
        }));
    };

    const formatJointInspectionsForPDF = () => {
        const jointInspections = filteredJointInspections?.filter((task: any) =>
            task.type === "inspection_task" && task.appliance?.is_multi_department
        );

        return jointInspections?.map(task => ({
            'TaskId': task.task_id || task.schedule_id,
            'Appliance Name': task?.appliance?.appliance_name,
            'Tag Id': task.appliance?.tag_id || '--',
            'Description': task?.appliance?.appliance_description,
            'Status': task.status,
            'Assigned To': (task.AssignedTo?.first_name &&
                `${task.AssignedTo?.first_name} ${task.AssignedTo?.last_name}`) ||
                `${task.assignedEmployee?.first_name} ${task.assignedEmployee?.last_name}`
        }));
    };

    const formatIssuesForPDF = () => {
        return filteredReportedIssues?.map(issue => ({
            'Issue ID': issue.issue_id,
            'Name': issue.name,
            'Type': issue.type,
            'Raised Date': issue.raised_date,
            'Status': issue.status,
            'Priority': issue.priority,
            'Location': issue.location
        }));
    };

    const handlePrintTask = (task: any) => {
        const doc = new jsPDF();

        // Add title
        doc.setFontSize(16);
        doc.text(`Task Details: ${task.schedule_id}`, 14, 15);

        // Create table
        (doc as any).autoTable({
            head: [['Field', 'Value']],
            body: [
                ['Task ID', task.schedule_id],
                ['Task Type', task.type],
                ['Appliance Name', task.appliance.appliance_name],
                ['Appliance ID', task.appliance_id],
                ['Tag ID', task.appliance.tag_id],
                ['Status', task.status],
                ['Assigned To', task.assignedEmployee.first_name + " " + task.assignedEmployee.last_name + " - " + task.assigned_to],
                ['Scheduled Date', task.scheduled_date],
                ['Due Date', task.due_date],
                ['Description', task.appliance.appliance_description],
                ['Follow Up', task.follow_up_request],
                ['Monitored By', task.monitoringEmployee.first_name + " " + task.monitoringEmployee.last_name + " - " + task.monitored_by],
                ['Inspection Date', task.inspection_date],
                ['Inspection Routine', task.routine.routine_name + " " + task.routine.frequency + " - " + task.routine.due_date_offset]
                // Add more fields as necessary
            ],
            startY: 25,
            styles: { fontSize: 8 },
            headStyles: { fillColor: [66, 66, 66] },
        });

        // Save PDF
        doc.save(`task_${task.schedule_id}.pdf`);
    };

    const getBase64Image = (url: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.crossOrigin = 'Anonymous'; // Handle CORS
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.drawImage(img, 0, 0);
                    const dataURL = canvas.toDataURL('image/png');
                    resolve(dataURL);
                } else {
                    reject('Could not get canvas context');
                }
            };
            img.onerror = (error) => reject(error);
        });
    };

    const handlePrintIssue = async (issue: any) => {
        const doc = new jsPDF();

        // Add title
        doc.setFontSize(16);
        doc.text(`Issue Details: ${issue.issue_id}`, 14, 15);

        // Add a line break
        doc.setFontSize(12);
        doc.text('Details:', 14, 25);

        // Create table
        (doc as any).autoTable({
            head: [['Field', 'Value']],
            body: [
                ['Issue ID', issue.issue_id],
                ['Master Issue ID', issue.master_issue_id],
                ['Name', issue.name],
                ['Type', issue.type],
                ['Raised Date', issue.raised_date],
                ['Closing Date', issue.closing_date || 'N/A'],
                ['Status', issue.status],
                ['Priority', issue.priority],
                ['Location', issue.location],
                ['Description', issue.description],
                ['Resolution Comment', issue.resolution_comment || 'N/A'],
                ['Appliance ID', issue.appliance_id],
                ['Reported By', `${issue.reportedBy.first_name} ${issue.reportedBy.last_name} ${issue.reportedBy.employee_id}`],
                ['Assigned To', issue.assigned_to || 'N/A'],
                ['Resolved By', `${issue.reportedBy.first_name} ${issue.reportedBy.last_name} ${issue.reportedBy.employee_id}` || 'N/A'],
                ['Resolved Date', issue.resolved_date || 'N/A'],
                ['Photo Evidence', issue.photo_evidence ? 'See attached image' : 'N/A'],
            ],
            startY: 30,
            styles: { fontSize: 10, cellPadding: 3 },
            headStyles: { fillColor: [66, 66, 66], textColor: [255, 255, 255] },
            theme: 'grid',
        });

        // Add image if available
        if (issue.photo_evidence) {
            const imgData = await getBase64Image(issue.photo_evidence);
            const imgWidth = 100; // Set width to 180 mm
            const imgHeight = (imgWidth * 160) / 180; // Maintain aspect ratio
            doc.addImage(imgData, 'PNG', 14, (doc as any).autoTable.previous.finalY + 10, imgWidth, imgHeight);
        }

        // Add a footer
        doc.setFontSize(10);
        doc.text('Generated on: ' + new Date().toLocaleString(), 14, (doc as any).autoTable.previous.finalY + 170);

        // Save PDF
        doc.save(`issue_${issue.issue_id}.pdf`);
    };

    return (
        <div className="w-full p-5 h-[90vh] overflow-y-auto scrollbar-hide">
            {isLoading && <Loader />}
            <Typography variant="h4" component="h1" gutterBottom>
                Inspection Tasks
            </Typography>

            <StyledTabs value={tabValue} onChange={handleTabChange} aria-label="inspection tasks tabs">
                <StyledTab label="Dashboard" />
                <StyledTab label="Schedule Inspection" />
                <StyledTab label="Reassign Task" />
            </StyledTabs>

            <Box sx={{ mt: 3 }}>
                {tabValue === 0 && (
                    <>
                        {/* Search bar and scan button */}
                        <div className='mb-4 flex justify-between items-center'>
                            <Banner title='Completed Inspection' count={totalInspectionsCompleted()} />
                        </div>
                        <div className="flex mb-5 gap-4 w-full">
                            <div className='w-full flex'>
                                <input
                                    type="text"
                                    placeholder="Search by appliance name, ID, or task ID..."
                                    className="w-[80%] p-2 border rounded mr-2 mb-2 md:mb-0"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                                <button className="w-[20%] bg-green-500 text-white p-2 rounded">
                                    Search
                                </button>
                            </div>
                            <div className='w-[20%]'>
                                <Filter options={["View All", "Self", "Completed", "Follow up", "Pending", "Pending over 30 days", "Unsatisfactory", "Due","Satisfactory", "Upcoming"]}
                                    onFilterChange={handleFilterChange}
                                    label={'Filter'}
                                    value={selectedFilter} 
                                />
                            </div>
                        </div>
                        
                        <Accordion
                            title="Inspection Tasks"
                            isOpened={true}
                            data={formatInspectionTasksForPDF()}
                            columns={inspectionTaskColumns}
                        >
                            <div className="overflow-x-auto">
                            {filteredInspectionTasks?.filter(x=>x?.type==="inspection_task")?.length>0 ?
                                (<table className="min-w-full bg-white">
                                    <thead>
                                        <tr className="bg-gray-100 border-b text-left font-bold text-sm">
                                            <th className="p-2">TaskId</th>
                                            <th className="p-2">Appliance Name</th>
                                            <th className="p-2">Tag Id</th>
                                            <th className="p-2">Description</th>
                                            <th className="p-2">Status</th>
                                            <th className="p-2">Assigned To</th>
                                            <th className="p-2">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredInspectionTasks?.filter(x=>x?.type==="inspection_task")?.map((task: any) => {
                                            return (
                                                <tr key={task.task_id} className="border-b text-left hover:bg-gray-50 text-sm">
                                                    <td className="p-2" onClick={() => handleTaskIdClick(task)} style={{ cursor: 'pointer', color: 'blue' }}>
                                                        {task.task_id || task.schedule_id}
                                                    </td>
                                                    <td className="p-2">{task?.appliance?.appliance_name}</td>
                                                    <td className="p-2">{task.appliance?.tag_id || "--"}</td>
                                                    <td className="p-2">{task?.appliance?.appliance_description}</td>
                                                    <td className="p-2">
                                                        <span className={`px-2 py-1 rounded-full text-xs font-semibold ${task.status === 'satisfactory' ? 'bg-green-100 text-green-800' :
                                                            task.status === 'unsatisfactory' ? 'bg-red-100 text-red-800' :
                                                                task.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' :
                                                                    'bg-gray-100 text-gray-800' // Default case if needed
                                                            }`}>
                                                            {task.status}
                                                        </span>
                                                    </td>
                                                    <td className="p-2">{(task.AssignedTo?.first_name && task.AssignedTo?.first_name + " " + task.AssignedTo?.last_name) || (task.assignedEmployee?.first_name + " " + task.assignedEmployee?.last_name)}</td>
                                                    <td className="p-2 flex gap-2 items-center">
                                                        <div className={`${(['satisfactory', 'unsatisfactory'].includes(task.status)) && 'invisible'}`}>
                                                            <Tooltip title="Edit">
                                                                <IconButton onClick={() => handleTaskEdit(task)} size="small">
                                                                    <EditIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                        <div className={`${(['satisfactory', 'unsatisfactory'].includes(task.status)) && 'invisible'}`}>
                                                            <Tooltip title="Delete">
                                                                <IconButton onClick={() => openConfirmation(task)} size="small">
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                        <Tooltip title="Print">
                                                            <IconButton onClick={() => handlePrintTask(task)} size="small">
                                                                <Printer className="w-5 h-5" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>):<p className='text-center'>{"This is the calm before the storm. Hold tight!"}</p>}
                            </div>
                        </Accordion>

                        <Accordion
                            title="Maintenance Tasks"
                            isOpened={true}
                            data={formatMaintenanceTasksForPDF()}
                            columns={maintenanceTaskColumns}
                        >
                            <div className="overflow-x-auto">
                            {filteredMaintenanceTasks?.filter(x=>x?.type==="maintenance_task")?.length > 0 ?
                               (<table className="min-w-full bg-white">
                                    <thead>
                                        <tr className="bg-gray-100 border-b text-left font-bold text-sm">
                                            <th className="p-2">TaskId</th>
                                            <th className="p-2">Appliance Name</th>
                                            <th className="p-2">Tag Id</th>
                                            <th className="p-2">Description</th>
                                            <th className="p-2">Status</th>
                                            <th className="p-2">Assigned To</th>
                                            <th className="p-2">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredMaintenanceTasks?.filter(x=>x?.type==="maintenance_task")?.map((task: any) => {
                                                return (
                                                    <tr key={task.task_id} className="border-b text-left hover:bg-gray-50 text-sm">
                                                        <td className="p-2" onClick={() => handleTaskIdClick(task)} style={{ cursor: 'pointer', color: 'blue' }}>
                                                            {task.task_id || task.schedule_id}
                                                        </td>
                                                        <td className="p-2">{task?.appliance?.appliance_name}</td>
                                                        <td className="p-2">{task.appliance?.tag_id || "--"}</td>
                                                        <td className="p-2">{task?.appliance?.appliance_description}</td>
                                                        <td className="p-2">
                                                            <span className={`px-2 py-1 rounded-full text-xs font-semibold ${task.status === 'satisfactory' ? 'bg-green-100 text-green-800' :
                                                                task.status === 'unsatisfactory' ? 'bg-red-100 text-red-800' :
                                                                    task.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' :
                                                                        'bg-gray-100 text-gray-800' // Default case if needed
                                                                }`}>
                                                                {task.status}
                                                            </span>
                                                        </td>
                                                        <td className="p-2">{(task.AssignedTo?.first_name && task.AssignedTo?.first_name + " " + task.AssignedTo?.last_name) || (task.assignedEmployee?.first_name + " " + task.assignedEmployee?.last_name)}</td>
                                                        <td className="p-2 flex gap-2 items-center">
                                                            <div className={`${(['satisfactory', 'unsatisfactory'].includes(task.status)) && 'invisible'}`}>
                                                                <Tooltip title="Edit">
                                                                    <IconButton onClick={() => handleTaskEdit(task)} size="small">
                                                                        <EditIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                            <div className={`${(['satisfactory', 'unsatisfactory'].includes(task.status)) && 'invisible'}`}>
                                                                <Tooltip title="Delete">
                                                                    <IconButton onClick={() => openConfirmation(task)} size="small">
                                                                        <DeleteIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                            <Tooltip title="Print">
                                                                <IconButton onClick={() => handlePrintTask(task)} size="small">
                                                                    <Printer className="w-5 h-5" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        )}
                                    </tbody>
                                </table>):<p className='text-center'>{"This is the calm before the storm. Hold tight!"}</p>}
                            </div>
                        </Accordion>

                        <Accordion
                            title="Issues Reported"
                            isOpened={true}
                            data={formatIssuesForPDF()}
                            columns={issuesColumns}
                        >
                            {filteredReportedIssues?.length>0 ?
                            (<TableContainer component={Paper}>
                                <Table>
                                    <StyledTableHead>
                                        <TableRow>
                                            <StyledTableCell>Issue ID</StyledTableCell>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>Type</StyledTableCell>
                                            <StyledTableCell>Raised Date</StyledTableCell>
                                            <StyledTableCell>Status</StyledTableCell>
                                            <StyledTableCell>Priority</StyledTableCell>
                                            <StyledTableCell>Location</StyledTableCell>
                                            <StyledTableCell>Actions</StyledTableCell>
                                        </TableRow>
                                    </StyledTableHead>
                                    <TableBody>
                                        {filteredReportedIssues?.map((issue: any) => (
                                            <TableRow key={issue.issue_id}>
                                                <TableCell
                                                    onClick={() => handleRowClick(issue)}
                                                    style={{ cursor: 'pointer', color: 'blue' }}
                                                >
                                                    {issue.issue_id}
                                                </TableCell>
                                                <TableCell>{issue.name || issue.appliance?.appliance_name}</TableCell>
                                                <TableCell>{issue.type}</TableCell>
                                                <TableCell>{issue.raised_date || issue.created_at}</TableCell>
                                                <TableCell>
                                                    <Chip
                                                        label={issue.status}
                                                        color={getStatusColor(issue.status)}
                                                        size="small"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Chip
                                                        label={issue.priority}
                                                        color={getPriorityColor(issue.priority)}
                                                        size="small"
                                                    />
                                                </TableCell>
                                                <TableCell>{issue.location}</TableCell>
                                                <TableCell className="p-2 flex gap-2 items-center" style={{display: 'flex'}}>
                                                    <div className={`${(['resolved'].includes(issue.status.toLowerCase())) && 'invisible'}`}>
                                                        <Tooltip title="Edit">
                                                            <IconButton onClick={() => handleTaskEdit(issue)} size="small">
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    <div className={`${(['resolved'].includes(issue.status.toLowerCase())) && 'invisible'}`}>
                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => openConfirmation(issue)} size="small">
                                                                <DeleteIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    <Tooltip title="Print">
                                                        <IconButton onClick={() => handlePrintIssue(issue)} size="small">
                                                            <Printer className="w-5 h-5" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>):<p className='text-center'>{"This is the calm before the storm. Hold tight!"}</p>}
                        </Accordion>

                        <Accordion
                            title="Joint Inspections"
                            isOpened={true}
                            data={formatJointInspectionsForPDF()}
                            columns={jointInspectionColumns}
                        >
                            {renderJointInspections()}
                        </Accordion>
                    </>
                )}
                {tabValue === 1 && (
                    <ScheduleInspectionView teamMembers={teams} allEmployees={employees} />
                )}
                {tabValue === 2 && (
                    <ReassignTaskForm tasksData={tasksData} teams={teams} />
                )}
            </Box>

            <ConfirmationPopup
                isOpen={isConfirmationOpen}
                onConfirm={confirmDelete}
                onCancel={closeConfirmation}
            />

            <NewModal isOpen={showEditModal} onClose={handleCancel}>
                {(selectedTask && employees) && (
                    <div className='h-[50vh] overflow-y-auto scrollbar-hide'>
                        <TaskEditForm
                            task={selectedTask}
                            employees={employees}
                            onSave={handleSave}
                            onCancel={handleCancel}
                        />
                    </div>
                )}
            </NewModal>

            {/* Popup for Task Info */}
            {isTaskInfoPopupOpen && (
                <NewModal isOpen={isTaskInfoPopupOpen} onClose={closeTaskInfoPopup} title='Task Details' >
                    {selectedTaskInfo && (
                        <div className='h-[50vh] overflow-y-auto scrollbar-hide'>
                            <div className='flex justify-between items-start py-1 gap-4'>
                                <p><strong>Schedule ID:</strong></p>
                                <p className='text-right '>{selectedTaskInfo.schedule_id}</p>
                            </div>
                            <div className='flex justify-between items-start py-1 gap-4'>
                                <p><strong>Type:</strong></p>
                                <p className='text-right '>{selectedTaskInfo.type.split("_").map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}</p>
                            </div>
                            <div className='flex justify-between items-start py-1 gap-4'>
                                <p><strong>Tag ID:</strong></p>
                                <p className='text-right '>{selectedTaskInfo?.appliance?.tag_id || "--"}</p>
                            </div>
                            <div className='flex justify-between items-start py-1 gap-4'>
                                <p><strong>Appliance:</strong></p>
                                <p className='text-right '>{selectedTaskInfo?.appliance?.appliance_name} - <em>{selectedTaskInfo?.appliance?.appliance_id}</em></p>
                            </div>
                            <div className='flex justify-between items-start py-1 gap-4'>
                                <p><strong>Description:</strong></p>
                                <p className='text-right '> {selectedTaskInfo.task_description}</p>
                            </div>
                            <div className='flex justify-between items-start py-1 gap-4'>
                                <p><strong>Status:</strong></p>
                                <p className='text-right '>{selectedTaskInfo.status}</p>
                            </div>
                            <div className='flex justify-between items-start py-1 gap-4'>
                                <p><strong>Inspection Date: </strong></p>
                                <p className='text-right '>{selectedTaskInfo.inspection_date}</p>
                            </div>
                            <div className='flex justify-between items-start py-1 gap-4'>
                                <p><strong>Assigned To: </strong></p>
                                <p className='text-right '> {selectedTaskInfo.assignedEmployee.first_name + " " + selectedTaskInfo.assignedEmployee.last_name + "-" + selectedTaskInfo.assigned_to}</p>
                            </div>
                            <div className='flex justify-between items-start py-1 gap-4'>
                                <p><strong>Department:</strong></p>
                                <p className='text-right '>{selectedTaskInfo.appliance?.ApplianceDepartments?.map((department: any) => department.AssociatedDepartment.department_name).join(', ')}</p>
                            </div>
                            <div className='flex justify-between items-start py-1 gap-4'>
                                <p><strong>Inspection Checklist ID:</strong></p>
                                <p className='text-right '>{selectedTaskInfo.appliance?.ApplianceDepartments?.map((department: any) => department.inspection_checklist_id || "--").join(', ')}</p>
                            </div>
                            <div className='flex justify-between items-start py-1 gap-4'>
                                <p><strong>Maintenance Checklist ID:</strong></p>
                                <p className='text-right '>{selectedTaskInfo.appliance?.ApplianceDepartments?.map((department: any) => department.maintenance_checklist_id || "--").join(', ')}</p>
                            </div>
                            <div className='flex justify-between items-start py-1 gap-4'>
                                <p><strong>Due Date:</strong></p>
                                <p className='text-right '>{selectedTaskInfo.due_date}</p>
                            </div>
                            <div className='flex justify-between items-start py-1 gap-4'>
                                <p><strong>Follow up:</strong></p>
                                <p className='text-right '>{selectedTaskInfo.follow_up ? "Yes" : "No"}</p>
                            </div>
                            <p className='py-1 '><strong>Photographic Evidence:</strong> {selectedTaskInfo.appliance_image
                                ? <img src={selectedTaskInfo.appliance_image} alt="error" />
                                : <></>} </p>
                            {
                                selectedTaskInfo.type == "inspection_task" && (
                                    <>
                                        <p><strong>Inspection Checklist Details:</strong>

                                            <div>
                                                <div className='flex justify-between items-start py-1 gap-4'>
                                                    <p><strong>Checklist Name:</strong></p>
                                                    <p className='text-right '>{selectedTaskInfo.inspectionChecklist?.name}</p>
                                                </div>
                                                <p><strong>Checklist Question Responses:</strong>
                                                    {selectedTaskInfo.inspectionChecklist?.questions.map((question: any, index: any) => {
                                                        return (
                                                            <div>
                                                                <p><strong>{index + 1}:</strong> {question.question_text}</p>
                                                                <p>{question.response || "--"}</p>
                                                            </div>
                                                        )
                                                    })}
                                                </p>
                                            </div>
                                        </p>
                                    </>
                                )
                            }

                            {
                                selectedTaskInfo.type == "maintenance_task" && (
                                    <>
                                        <p><strong>Inspection Checklist Details:</strong>

                                            <div>
                                                <div className='flex justify-between items-start py-1 gap-4'>
                                                    <p><strong>Checklist Name:</strong></p>
                                                    <p className='text-right '>{selectedTaskInfo.inspectionChecklist?.name}</p>
                                                </div>
                                                <p><strong>Checklist Question Responses:</strong>
                                                    {selectedTaskInfo.maintenanceChecklist?.questions.map((question: any, index: any) => {
                                                        return (
                                                            <div>
                                                                <p><strong>{index + 1}:</strong> {question.question_text}</p>
                                                                <p>{question.response || "--"}</p>
                                                            </div>
                                                        )
                                                    })}
                                                </p>
                                            </div>
                                        </p>
                                    </>
                                )
                            }
                            {/* Add more fields as necessary */}
                        </div>
                    )}
                </NewModal>
            )}



            {showIssueModal && (
                <IssueDetailsModal
                    issueDetails={modalDetails}
                    onClose={() => setShowIssueModal(false)}
                />
            )}
        </div>
    );
};

export default InspectionTasks;