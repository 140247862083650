import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  IconButton,
  Tooltip
} from '@mui/material';
import dayjs from 'dayjs';
import { PiBarcode } from "react-icons/pi";
import { FaPrint } from "react-icons/fa";
import Barcode from './Barcode';
import NewModal from '../common/newModal';
import { printBarcode } from '../../utils/common';

interface AttendanceRecord {
  employeeId: string;
  employeeName: string;
  punch_in: string;
  punch_out: string;
  date: string;
}

interface EmployeeAttendanceTableProps {
  attendanceData: AttendanceRecord[];
}

const EmployeeAttendanceTable: React.FC<EmployeeAttendanceTableProps> = ({ attendanceData }) => {
  const [isBarcodeModalOpen, setIsBarcodeModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<AttendanceRecord | null>(null);

  const handleBarcodeView = (employee: AttendanceRecord) => {
    setSelectedEmployee(employee);
    setIsBarcodeModalOpen(true);
  };

  const closeBarcodeModal = () => {
    setIsBarcodeModalOpen(false);
    setSelectedEmployee(null);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Employee Attendance
      </Typography>
      <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 250px)' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f8fafc' }}>Employee Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f8fafc' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f8fafc' }}>Punch In</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f8fafc' }}>Punch Out</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f8fafc' }}>Total Hours</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f8fafc' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendanceData.map((record, index) => {
              const punchIn = record.punch_in ? dayjs(record.punch_in) : null;
              const punchOut = record.punch_out ? dayjs(record.punch_out) : null;
              const totalHours = punchOut ? punchOut.diff(punchIn, 'hour', true).toFixed(2) : '-';

              return (
                <TableRow key={`${record.employeeId}-${index}`} hover>
                  <TableCell>{`${record.employeeName}`}</TableCell>
                  <TableCell>{dayjs(record.date).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{punchIn ? punchIn.format('HH:mm') : '-'}</TableCell>
                  <TableCell>{punchOut ? punchOut.format('HH:mm') : '-'}</TableCell>
                  <TableCell>{totalHours}</TableCell>
                  <TableCell>
                    <Tooltip title="View Barcode">
                      <IconButton 
                        onClick={() => handleBarcodeView(record)} 
                        size="small"
                        sx={{
                          '&:hover': {
                            color: 'primary.main',
                            backgroundColor: 'primary.lighter',
                          }
                        }}
                      >
                        <PiBarcode className="w-5 h-5" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Barcode Modal */}
      <NewModal 
        isOpen={isBarcodeModalOpen} 
        onClose={closeBarcodeModal} 
        title="Employee Barcode"
      >
        <div className="flex flex-col items-center justify-center">
          <div className='flex'>
            <img src="/company_logo.png" alt="Company Logo" width="140" height="140" />
            <div
              id="printableBarcode"
              className="flex items-center justify-center w-full h-full print:absolute print:inset-0"
            >
              {selectedEmployee && (
                <Barcode
                  value={selectedEmployee.employeeId}
                  width={2}
                  height={80}
                  displayValue={true}
                  format="CODE128"
                />
              )}
            </div>
          </div>
          <button
            onClick={() => {
              printBarcode();
              closeBarcodeModal();
            }}
            className="p-2 rounded-md bg-green-500 flex mt-4 p-2 text-white"
          >
            <FaPrint className="w-6 h-6" /> &nbsp; Print
          </button>
        </div>
      </NewModal>
    </Box>
  );
};

export default EmployeeAttendanceTable;