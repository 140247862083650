import React, { useState } from 'react';
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Chip,
  CircularProgress,
  InputLabel,
} from '@mui/material';
import {
  BuildRounded,
  ReceiptLongRounded,
  PhotoLibraryRounded,
  LocationOnRounded,
  SwapHorizRounded,
  BlockRounded,
  CheckCircleOutline as ActiveIcon,
  Block as InactiveIcon,
  Build as MaintenanceIcon,
  PersonOutline as AssignedIcon,
  ReportProblem as DamagedIcon,
  SwapHoriz as ReplacedIcon,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { toast } from 'react-toastify';
import { updateAssetLocation, updateAssetMaintenance, updateAssetPhoto, updateAssetStatus } from '../../../api/endpoints/APIEndpoints';

type AssetStatus = 'active' | 'inactive' | 'under_maintenance' | 'assigned' | 'damaged' | 'replaced';

interface UpdateAssetProps {
  assetData: any[];
  onAssetUpdate?: () => void;
}

const UpdateAsset: React.FC<UpdateAssetProps> = ({ assetData, onAssetUpdate }) => {
  const [selectedAsset, setSelectedAsset] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [activeAction, setActiveAction] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    maintenance: {
      date: null,
      notes: '',
      amount: '',
      invoice: null as File | null,
      photos: [] as File[],
    },
    invoice: {
      file: null as File | null,
    },
    photos: {
      files: [] as File[],
    },
    location: {
      newLocation: '',
    },
    replacement: {
      reason: '',
      date: null,
    },
    inactive: {
      reason: '',
      date: null,
    },
    status: {
      newStatus: '' as AssetStatus,
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const actionButtons = [
    {
      label: 'Update Asset Maintenance',
      icon: <BuildRounded />,
      color: 'primary',
      description: 'Record maintenance activities and costs',
      form: 'maintenance',
    },
    {
      label: 'Add Purchase Invoice/ Maintenance Invoice',
      icon: <ReceiptLongRounded />,
      color: 'secondary',
      description: 'Upload new invoices and documents',
      form: 'invoice',
    },
    {
      label: 'Update Asset Photographs',
      icon: <PhotoLibraryRounded />,
      color: 'info',
      description: 'Add or update asset images',
      form: 'photos',
    },
    {
      label: 'Update Asset Location',
      icon: <LocationOnRounded />,
      color: 'success',
      description: 'Change asset location details',
      form: 'location',
    },
    {
      label: 'Replace Asset',
      icon: <SwapHorizRounded />,
      color: 'warning',
      description: 'Mark asset for replacement',
      form: 'replacement',
    },
    {
      label: 'Update Asset Status',
      icon: <SwapHorizRounded />,
      color: 'info',
      description: 'Change asset status',
      form: 'status',
    },
  ];

  const handleActionClick = (form: string) => {
    setActiveAction(form);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setActiveAction(null);
  };

  const renderFormContent = () => {
    switch (activeAction) {
      case 'maintenance':
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Maintenance Date"
                  value={formData.maintenance.date}
                  onChange={(newValue) => setFormData((prev: any) => ({
                    ...prev,
                    maintenance: { ...prev.maintenance, date: newValue ? newValue : null }
                  }))}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                label="Maintenance Amount"
                value={formData.maintenance.amount}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  maintenance: { ...prev.maintenance, amount: e.target.value }
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Maintenance Notes"
                value={formData.maintenance.notes}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  maintenance: { ...prev.maintenance, notes: e.target.value }
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="flex flex-col gap-4">
                {/* Invoice Upload */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Maintenance Invoice
                  </label>
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      maintenance: { ...prev.maintenance, invoice: e.target.files?.[0] || null }
                    }))}
                    className="w-full p-2 border rounded"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        );

      case 'invoice':
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="flex flex-col gap-2">
                <label className="block text-sm font-medium text-gray-700">
                  Upload Invoice
                </label>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    invoice: { ...prev.invoice, file: e.target.files?.[0] || null }
                  }))}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
            </Grid>
          </Grid>
        );

      case 'photos':
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="flex flex-col gap-2">
                <label className="block text-sm font-medium text-gray-700">
                  Upload Asset Photographs
                </label>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(e) => {
                    const files = Array.from(e.target.files || []);
                    setFormData(prev => ({
                      ...prev,
                      photos: { files }
                    }));
                  }}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
            </Grid>
            {formData.photos.files.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" className="mb-2">
                  Selected Photos: {formData.photos.files.length}
                </Typography>
                <div className="flex gap-2 flex-wrap">
                  {formData.photos.files.map((file, index) => (
                    <div key={index} className="relative">
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`Preview ${index + 1}`}
                        className="w-24 h-24 object-cover rounded"
                      />
                    </div>
                  ))}
                </div>
              </Grid>
            )}
          </Grid>
        );

      case 'location':
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="New Location"
                value={formData.location.newLocation}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  location: { newLocation: e.target.value }
                }))}
                required
              />
            </Grid>
          </Grid>
        );

      case 'replacement':
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Replacement Date"
                  value={formData.replacement.date}
                  onChange={(newValue) => setFormData((prev: any) => ({
                    ...prev,
                    replacement: { ...prev.replacement, date: newValue }
                  }))}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Replacement Reason"
                value={formData.replacement.reason}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  replacement: { ...prev.replacement, reason: e.target.value }
                }))}
                required
              />
            </Grid>
          </Grid>
        );

      case 'status':
        const statusOptions = [
          { value: 'active', label: 'Active', icon: <ActiveIcon className="text-green-600" /> },
          { value: 'inactive', label: 'Inactive', icon: <InactiveIcon className="text-red-600" /> },
          { value: 'under_maintenance', label: 'Under Maintenance', icon: <MaintenanceIcon className="text-yellow-600" /> },
          { value: 'assigned', label: 'Assigned', icon: <AssignedIcon className="text-blue-600" /> },
          { value: 'damaged', label: 'Damaged', icon: <DamagedIcon className="text-orange-600" /> },
          { value: 'replaced', label: 'Replaced', icon: <ReplacedIcon className="text-purple-600" /> },
        ];

        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Current Status: <Chip 
                  label={assetData.find(a => a.asset_id === selectedAsset)?.status.toUpperCase()}
                  color={getStatusColor(assetData.find(a => a.asset_id === selectedAsset)?.status)}
                  size="small"
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>New Status</InputLabel>
                <Select
                  value={formData.status.newStatus}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    status: { ...prev.status, newStatus: e.target.value as AssetStatus }
                  }))}
                  label="New Status"
                >
                  {statusOptions.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {option.icon}
                        {option.label}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        );

      default:
        return null;
    }
  };

  const handleSubmit = async () => {
    if (!selectedAsset) return;

    setIsSubmitting(true);
    try {
      const convertFileToBase64 = async (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = error => reject(error);
        });
      };

      switch (activeAction) {
        case 'maintenance':
          const maintenanceInvoiceBase64 = formData.maintenance.invoice
            ? await convertFileToBase64(formData.maintenance.invoice)
            : null;

          const maintenancePayload = {
            asset_id: selectedAsset,
            maintenance_history: {
              amount: formData.maintenance.amount,
              date: dayjs(formData.maintenance.date).format('YYYY-MM-DD'),
              notes: formData.maintenance.notes,
              invoice: maintenanceInvoiceBase64 ? {
                file: maintenanceInvoiceBase64,
                filename: formData.maintenance.invoice?.name
              } : null
            }
          };

          await updateAssetMaintenance.post(maintenancePayload);
          break;

        // case 'invoice':
        //   const invoiceBase64 = formData.invoice.file 
        //     ? await convertFileToBase64(formData.invoice.file) 
        //     : null;

        //   const invoicePayload = {
        //     asset_id: selectedAsset,
        //     purchase_invoice: {
        //       file: invoiceBase64,
        //       filename: formData.invoice.file?.name
        //     }
        //   };

        //   await updateAssetInvoice.post(invoicePayload);
        //   break;

        case 'photos':
          const photosFormData = new FormData();
          photosFormData.append('asset_id', selectedAsset);

          // Convert all photos to base64
          const photoPromises = formData.photos.files.map(async (file) => {
            const base64 = await convertFileToBase64(file);
            return base64
          });

          const photoFiles = await Promise.all(photoPromises);

          const photosPayload = {
            asset_id: selectedAsset,
            photo_url: photoFiles[0]
          };

          await updateAssetPhoto.post(photosPayload);
          break;

        case 'location':
          await updateAssetLocation.post({
            asset_id: selectedAsset,
            location: formData.location.newLocation
          });
          break;

        // case 'replacement':
        //   await replaceAsset.post({
        //     asset_id: selectedAsset,
        //     replacement_date: dayjs(formData.replacement.date).format('YYYY-MM-DD'),
        //     reason: formData.replacement.reason
        //   });
        //   break;

        case 'status':
          await updateAssetStatus.post({
            asset_id: selectedAsset,
            status: formData.status.newStatus,
          });
          break;

      }

      toast.success('Asset updated successfully');
      handleCloseModal();

      // Refresh the asset data if needed
      if (typeof onAssetUpdate === 'function') {
        onAssetUpdate();
      }

    } catch (error) {
      console.error('Error updating asset:', error);
      toast.error('Failed to update asset. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const getStatusColor = (status: string) => {
    switch (status?.toLowerCase()) {
      case 'active':
        return 'success';
      case 'inactive':
        return 'error';
      case 'under_maintenance':
        return 'warning';
      case 'assigned':
        return 'info';
      case 'damaged':
        return 'error';
      case 'replaced':
        return 'secondary';
      default:
        return 'default';
    }
  };

  // Update the DialogActions section
  return (
    <div className="container mx-auto p-4">
      <FormControl fullWidth className="mb-6">
        <Select
          value={selectedAsset}
          onChange={(e) => setSelectedAsset(e.target.value)}
          displayEmpty
          className="bg-white"
          sx={{ minHeight: '56px' }}
        >
          <MenuItem value="" disabled>
            Select Asset
          </MenuItem>
          {assetData?.map((asset: any) => (
            <MenuItem key={asset.asset_id} value={asset.asset_id}>
              {asset.asset_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedAsset && (
        <>
          {/* Asset Details Section */}
          <Paper className="p-6 mb-6 mt-2 bg-gray-50">
            <Typography variant="h6" className="mb-4 text-gray-700 flex items-center gap-2">
              <span>Asset Details</span>
              <Chip
                label={assetData.find(a => a.asset_id === selectedAsset)?.status.toUpperCase()}
                size="small"
                color={assetData.find(a => a.asset_id === selectedAsset)?.status === 'active' ? 'success' : 'error'}
              />
            </Typography>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {/* Basic Information */}
              <div className="space-y-3">
                <Typography variant="subtitle2" className="text-gray-500 mb-2">
                  Basic Information
                </Typography>
                <div className="space-y-2">
                  {Object.entries({
                    'Asset ID': assetData.find(a => a.asset_id === selectedAsset)?.asset_id,
                    'Asset Name': assetData.find(a => a.asset_id === selectedAsset)?.asset_name,
                    'Department': assetData.find(a => a.asset_id === selectedAsset)?.departments?.[0]?.department_name,
                    'Location': assetData.find(a => a.asset_id === selectedAsset)?.location,
                  }).map(([label, value]) => (
                    <div key={label} className="flex justify-between">
                      <span className="text-sm text-gray-600">{label}:</span>
                      <span className="text-sm font-medium">{value}</span>
                    </div>
                  ))}
                </div>
              </div>

              {/* Technical Details */}
              <div className="space-y-3">
                <Typography variant="subtitle2" className="text-gray-500 mb-2">
                  Technical Details
                </Typography>
                <div className="space-y-2">
                  {Object.entries({
                    'Manufacturer': assetData.find(a => a.asset_id === selectedAsset)?.manufacturer,
                    'Model': assetData.find(a => a.asset_id === selectedAsset)?.model,
                    'Serial Number': assetData.find(a => a.asset_id === selectedAsset)?.serial_number,
                    'Reminder': assetData.find(a => a.asset_id === selectedAsset)?.reminder_frequency,
                  }).map(([label, value]) => (
                    <div key={label} className="flex justify-between">
                      <span className="text-sm text-gray-600">{label}:</span>
                      <span className="text-sm font-medium">{value}</span>
                    </div>
                  ))}
                </div>
              </div>

              {/* Financial Details */}
              <div className="space-y-3">
                <Typography variant="subtitle2" className="text-gray-500 mb-2">
                  Financial Details
                </Typography>
                <div className="space-y-2">
                  {Object.entries({
                    'Purchase Date': dayjs(assetData.find(a => a.asset_id === selectedAsset)?.purchase_date).format('DD MMM YYYY'),
                    'Purchase Cost': `₹${assetData.find(a => a.asset_id === selectedAsset)?.purchase_cost}`,
                    'Depreciation': `${assetData.find(a => a.asset_id === selectedAsset)?.depreciation_rate}%`,
                    'Last Maintenance': dayjs(assetData.find(a => a.asset_id === selectedAsset)?.maintenance_history?.maintenance_history?.[0]?.date).format('DD MMM YYYY'),
                  }).map(([label, value]) => (
                    <div key={label} className="flex justify-between">
                      <span className="text-sm text-gray-600">{label}:</span>
                      <span className="text-sm font-medium">{value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Quick Links */}
            <div className="mt-6 flex gap-4 border-t pt-4">
              {assetData.find(a => a.asset_id === selectedAsset)?.photo_url && (
                <a
                  href={assetData.find(a => a.asset_id === selectedAsset)?.photo_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800 text-sm flex items-center gap-1"
                >
                  <PhotoLibraryRounded fontSize="small" />
                  View Photo
                </a>
              )}
              {assetData.find(a => a.asset_id === selectedAsset)?.purchase_invoice_url && (
                <a
                  href={assetData.find(a => a.asset_id === selectedAsset)?.purchase_invoice_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800 text-sm flex items-center gap-1"
                >
                  <ReceiptLongRounded fontSize="small" />
                  View Invoice
                </a>
              )}
            </div>
          </Paper>

          {/* Action Buttons Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {actionButtons.map((button, index) => (
              <Paper
                key={index}
                className="p-4 transition-all hover:shadow-md cursor-pointer"
                onClick={() => handleActionClick(button.form)}
              >
                <div className="flex items-start gap-4">
                  <div className={`p-3 rounded-full bg-${button.color}-50`}>
                    {React.cloneElement(button.icon, { className: `text-${button.color}-500` })}
                  </div>
                  <div className="flex-1">
                    <Typography variant="subtitle1" className="font-medium mb-1">
                      {button.label}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {button.description}
                    </Typography>
                  </div>
                </div>
              </Paper>
            ))}
          </div>
        </>
      )}

      {/* Action Modal */}

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {actionButtons.find(b => b.form === activeAction)?.label}
        </DialogTitle>
        <DialogContent dividers>
          {renderFormContent()}
        </DialogContent>

        {
          activeAction !== 'inactive' &&
          <DialogActions>
            <Button
              onClick={handleCloseModal}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <div className="flex items-center gap-2">
                  <CircularProgress size={20} color="inherit" />
                  <span>Submitting...</span>
                </div>
              ) : (
                'Submit'
              )}
            </Button>
          </DialogActions>
        }

      </Dialog>
    </div>
  );
};


export default UpdateAsset; 