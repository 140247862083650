import APIInterface from "../APIInterface";

export const fetchNewRefreshToken = new APIInterface("/api/auth/refresh")
export const login = new APIInterface("/api/auth/login")
export const logout = new APIInterface("/api/auth/logout")
export const changePassword = new APIInterface("/api/auth/change-password")
export const forgotPassword = new APIInterface("/api/auth/forgot-password")
export const resetPassword = new APIInterface("/api/auth/reset-password")
export const fetchAssignedTasksForEmployee = new APIInterface('/tasks/assigned');
export const fetchAllTasks = new APIInterface("/tasks/all-tasks");
export const fetchAllTasksWithLevel = new APIInterface("/tasks/all-tasks-with-level");
export const updateTasks = new APIInterface("/tasks/update")
export const deleteTasks = new APIInterface("/tasks/delete_task")
export const reAssignInspection = new APIInterface("/tasks/reassign")

export const fetchAllAppliances = new APIInterface("/appliance/all-appliances")
export const addAppliance = new APIInterface("/appliance/add-appliance-tag")
export const updateAppliance = new APIInterface("/appliance/update-appliance")
export const deleteAppliance = new APIInterface("/appliance/delete-appliance")

export const getAllDepartments = new APIInterface('/departments/get_all_departments');
export const addDepartment = new APIInterface('/departments/add_department');

export const getAllRoles = new APIInterface('/roles/get_all_roles');
export const getAllProjects = new APIInterface('/projects/get_all_projects');
export const getAllCompanies = new APIInterface('/companies/get_all_companies');

export const addProject = new APIInterface("/projects/add_project");

export const registerUser = new APIInterface("/users/register_user");
export const fetchAllEmployees = new APIInterface("/users/all-employees")
export const fetchSelectedEmployeeDetails = new APIInterface("/users/employee-details")
export const teamInspectionStats = new APIInterface("/users/team-inspection-stats")
export const fetchEmployeeByDepartment = new APIInterface("/users/employees-by-department")
export const allEmployeePunchRecords = new APIInterface("/users/punch-all-records")

export const fetchAllTags = new APIInterface("/tags/all-tags")
export const generateTags = new APIInterface("/tags/generate-tag")
export const deleteTag = new APIInterface("/tags/delete-tag")
export const completedEmployeeInspection = new APIInterface("/tags//employee-completed-tag-inspections")

export const fetchAllChecklist = new APIInterface("/checklists/all_checklist")
export const addChecklist = new APIInterface("/checklists/add_checklist")
export const deleteChecklist = new APIInterface("/checklists/delete-checklist")
export const addCustomQuestion = new APIInterface("/checklists/add_custom_question")
export const fetchAppQuestions = new APIInterface("/checklists/app-questions")

export const fetchRoutines = new APIInterface("/routine/inspection-routines")
export const addInspectionRoutines = new APIInterface("/routine/add-inspection-routines")
export const scheduleInspection = new APIInterface("/routine/schedule_inspection")
export const editInspection = new APIInterface("/routine/edit-inspection")
export const departmentInspectionDetails = new APIInterface("/routine/department-inspection-details")

export const fetchTeamsWithProjectsAndMembers = new APIInterface("/teams/teams-with-projects-and-members")
export const getTeamMemebers = new APIInterface("/teams/members")
export const addEmployeeToTeam = new APIInterface("/teams/add-employee-to-team")
export const addTeam = new APIInterface("/teams/add-team")
export const fetchAllTeams = new APIInterface("/teams/all-teams")
export const reassignResponsibility = new APIInterface("/teams/reassign-responsibility")
export const grantPermission = new APIInterface("/teams/grant-permission")
export const fetchEmployeeTaskStatus = new APIInterface("/teams/team-members-task-status")

export const fetchEmployeeApprovalRequest = new APIInterface("/request/get-employee-approval-requests")
export const handleApprovalRequest = new APIInterface("/request/handle-approval-request")

export const exportAsPdf = new APIInterface("/report/export-department-inspection-pdf")
export const exportAsCSV = new APIInterface("/report/export-department-inspection")

export const reportIssue = new APIInterface("/issue/report-issue")
export const fetchAllIssues = new APIInterface("/issue/all-issues")
export const resolveIssues = new APIInterface("/issue/resolve-issue")
export const assignIssue = new APIInterface("/issue/assign-issue")

export const fetchNotifications = new APIInterface("/notification/employee-notifications")

export const fetchAllAlerts = new APIInterface("/alert/all-alerts")
export const addAlert = new APIInterface("/alert/add-alert")
export const editAlert = new APIInterface("/alert/edit-alert")
export const extendAlert = new APIInterface("/alert/extend-alert")

export const fetchDepartmentStats = new APIInterface("/stats/dashboard-stats")
export const fetchCompanySpecificStats = new APIInterface("/stats/company-dashboard-stats")
export const updateQuestion = new APIInterface("/checklists/update_question")

export const fetchTeamMemberInspection = new APIInterface("/report/team-member-inspection")
export const fetchApplianceReport = new APIInterface("/report/appliance-report")

export const addDocument = new APIInterface("/document/add_document")
export const fetchAllDocuments = new APIInterface("/document/get_all_docs")
export const updateRenewal = new APIInterface("/document/update_renewal")
export const deleteDocument = new APIInterface("/document/delete_document")
export const updateDocument = new APIInterface("/document/update_document")
export const fetchDocumentIssues = new APIInterface("/issue/all-document-issues")
export const resolveDocumentIssue = new APIInterface("/issue/resolve-document-issue")
export const reportDocumentIssue = new APIInterface("/issue/report-document-issue")

export const addAsset = new APIInterface("/asset/add_asset")
export const fetchAllAssets = new APIInterface("/asset/get_all_assets")

export const updateAssetMaintenance = new APIInterface("/asset/update_maintenance")
export const updateAssetPhoto = new APIInterface("/asset/update-asset-photo")
export const updateAssetLocation = new APIInterface("/asset/update-asset-location")
export const updateAssetStatus = new APIInterface("/asset/update-asset-status")
export const convertToAppliance = new APIInterface("/asset/convert-to-appliance")
export const assignAssetToEmployee = new APIInterface("/asset_assignment/assign-asset-to-employee")

export const fetchAssetHistory = new APIInterface("/asset/get-asset-history")
export const fetchAssetIssues = new APIInterface("/issue/get-asset-issues")
export const resolveAssetIssue = new APIInterface("/issue/resolve-asset-issue")
export const reportAssetIssue = new APIInterface("/issue/report-asset-issue")
