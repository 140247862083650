import React from 'react';
import { FaGem, FaSun, FaPuzzlePiece, FaMoneyBillWave } from 'react-icons/fa';

interface AssetStatisticsCardsProps {
  assetData: any[];
}

const AssetStatisticsCards: React.FC<AssetStatisticsCardsProps> = ({ assetData }) => {
  // Calculate statistics
  const totalAssets = assetData?.length || 0;
  
  const totalValue = assetData?.reduce((sum, asset) => {
    return sum + parseFloat(asset.purchase_cost || 0);
  }, 0);

  const calculateNetAssetsValue = (assets: any[]) => {
    if (!assets || !Array.isArray(assets)) return 0;
    
    return assets.reduce((sum, asset) => {
      try {
        // Check if required values exist and are valid numbers
        const purchaseCost = parseFloat(asset.purchase_cost);
        const depreciationRate = parseFloat(asset.depreciation_rate);
        
        if (isNaN(purchaseCost) || isNaN(depreciationRate) || !asset.purchase_date) {
          console.warn(`Invalid data for asset: ${asset.asset_name}`);
          return sum;
        }

        const purchaseDate = new Date(asset.purchase_date);
        const today = new Date();
        
        // Validate purchase date
        if (purchaseDate.toString() === 'Invalid Date') {
          console.warn(`Invalid purchase date for asset: ${asset.asset_name}`);
          return sum;
        }

        const yearsDiff = (today.getTime() - purchaseDate.getTime()) / (1000 * 60 * 60 * 24 * 365);
        
        // Calculate current value using depreciation formula
        const currentValue = purchaseCost * Math.pow(1 - (depreciationRate / 100), yearsDiff);
        
        // Return sum + currentValue only if calculation is valid
        return sum + (isFinite(currentValue) ? currentValue : 0);
      } catch (error) {
        console.error(`Error calculating depreciation for asset: ${asset.asset_name}`, error);
        return sum;
      }
    }, 0);
  };

  const netAssetsValue = calculateNetAssetsValue(assetData);

  // Calculate purchases in current fiscal year
  const getCurrentFiscalYearPurchases = () => {
    const today = new Date();
    const fiscalYearStart = new Date(today.getFullYear(), 3, 1); // April 1st
    if (today < fiscalYearStart) {
      fiscalYearStart.setFullYear(fiscalYearStart.getFullYear() - 1);
    }

    return assetData?.reduce((sum, asset) => {
      const purchaseDate = new Date(asset.purchase_date);
      if (purchaseDate >= fiscalYearStart && purchaseDate <= today) {
        return sum + parseFloat(asset.purchase_cost || 0);
      }
      return sum;
    }, 0);
  };

  const fiscalYearPurchases = getCurrentFiscalYearPurchases();

  const cards = [
    {
      title: "Number of Assets",
      value: totalAssets,
      icon: <FaGem className="text-blue-400 w-6 h-6" />,
      bgColor: "bg-blue-50",
    },
    {
      title: "Value of Assets",
      value: `₹${totalValue.toLocaleString('en-IN')}`,
      icon: <FaSun className="text-orange-400 w-6 h-6" />,
      bgColor: "bg-orange-50",
    },
    {
      title: "Net Assets Value",
      value: `₹${netAssetsValue.toLocaleString('en-IN', { maximumFractionDigits: 0 })}`,
      icon: <FaPuzzlePiece className="text-purple-400 w-6 h-6" />,
      bgColor: "bg-purple-50",
    },
    {
      title: "Purchases in Fiscal Year",
      value: `₹${fiscalYearPurchases.toLocaleString('en-IN', { maximumFractionDigits: 0 })}`,
      icon: <FaMoneyBillWave className="text-green-400 w-6 h-6" />,
      bgColor: "bg-green-50",
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
      {cards.map((card, index) => (
        <div 
          key={index} 
          className={`${card.bgColor} rounded-lg p-6 pt-2 pb-2 shadow-sm flex items-center`}
        >
          <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center shadow-sm">
            {card.icon}
          </div>
          <div className="mt-4 pl-4">
            <h3 className="text-3xl font-semibold text-gray-800">
              {card.value}
            </h3>
            <p className="text-sm text-gray-600 mt-1">
              {card.title}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AssetStatisticsCards;
