import React, { useMemo, useState } from 'react';
import { Check, X } from 'lucide-react';
import { shallowEqual, useSelector } from 'react-redux';
import { selectApprovalRequestData } from '../../store/store/mainSlice';
import { handleApprovalRequest } from '../../api/endpoints/APIEndpoints';
import Modal from '../../components/common/Modal';
import CloseIcon from '@mui/icons-material/Close';

// Interfaces and Types
interface ApprovalRequest {
  id: string;
  title: string;
  description: string;
  requester: string;
  date: string;
  status: string;
}

interface ApprovalPageProps {
}

const ApprovalPage: React.FC<ApprovalPageProps> = ({ }) => {
  const approvalData = useSelector(selectApprovalRequestData, shallowEqual);
  const [showQuestionsModal, setShowQuestionsModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<'pending' | 'approved'>('pending');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDetails, setModalDetails] = useState<any>(null);

  const sortedApprovalData = useMemo(() => {
    return [...approvalData].sort((a, b) => {
      if (a.status === 'pending' && b.status !== 'pending') return -1;
      if (a.status !== 'pending' && b.status === 'pending') return 1;
      if (a.status === 'approved' && b.status === 'denied') return -1;
      if (a.status === 'denied' && b.status === 'approved') return 1;
      return 0;
    });
  }, [approvalData]);

  const filteredApprovalData = sortedApprovalData.filter(request => request.status === activeTab);

  const getApprovalTitle = (type: string) => {
    switch (type) {
      case "checklist_addition":
        return "Checklist Addition";
      case "user_registration":
        return "User Registration";
      case "team_member_addition":
        return "Team Member Addition"
      case "role_change":
        return "Role Change";
      default:
        return ""
    }
  }

  const handleApprove = (id: string, request: any) => {
    console.log(`Approved request ${id}`);
    handleApprovalRequest.post({
      request_id: id,
      action: "approve"
    })
    .then((res) => {
      console.log("Request approved", res);
      const requestDetails = JSON.parse(request.details);
      setModalDetails({
        request_type: getApprovalTitle(request.type),
        requester_name: request.requester.name,
        requester_id: request.requester.id,
        approver_name: request.approver?.name,
        date: new Date(request.createdAt).toLocaleDateString(),
        details: requestDetails,
        type: request.type
      });
      setShowSuccessModal(true);
    })
    .catch((error) => console.log("ERROR!!", error));
  };

  const handleDecline = (id: string) => {
    console.log(`Declined request ${id}`);
    handleApprovalRequest.post(({
      request_id: id,
      action: "deny"
    })).then((res) => console.log("user registration rejected", res))
      .catch((error) => console.log("ERROR!!", error))
    // Implement decline logic here
  };

  const displayMessage = (type: string, requestDetails: any, requester: any) => {
    if (type === "checklist_addition") {
      return requester.name + "(" + requester.id + ")" + " has requested to add a checklist " + requestDetails.name + " of type " + requestDetails.checklist_type;
    } else if (type === "user_registration") {
      return requester.name + "(" + requester.id + ")" + " has requested to register a new user"
    } else if (type === "team_member_addition") {
      return requester.name + "(" + requester.id + ")" + " has requested to add a team member"
    } else if (type === "role_change") {
      return requester.name + "(" + requester.id + ")" + " has requested for a role change for " + requestDetails.employee_name + "( Level-" + requestDetails.current_role_id + " " + requestDetails.current_role_name + " )" + " to " + requestDetails.new_role_name + " " + "Level-" + requestDetails.new_role_id + ".";
    }
  }

  const handleShowQuestions = (request: any) => {
    const requestDetails = JSON.parse(request?.details);
    setSelectedRequest(requestDetails);
    setShowQuestionsModal(true);
  };

  console.log("Approval", approvalData)
  return (
    <div className="h-screen overflow-y-scroll bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className='flex justify-between'>
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Approval Requests</h1>

          <div className="flex mb-4">
            <button
              onClick={() => setActiveTab('pending')}
              className={`px-4 py-2 rounded-l-lg ${activeTab === 'pending' ? 'bg-gray-300' : 'bg-white'} border border-gray-300`}
            >
              Pending
            </button>
            <button
              onClick={() => setActiveTab('approved')}
              className={`px-4 py-2 rounded-r-lg ${activeTab === 'approved' ? 'bg-gray-300' : 'bg-white'} border border-gray-300`}
            >
              Approved
            </button>
          </div>
        </div>

        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {filteredApprovalData.map((request: any) => {
              const requestDetails = JSON.parse(request?.details);
              return (
                <li key={request.id} className={`px-4 py-4 sm:px-6 hover:bg-gray-50 transition duration-150 ease-in-out ${request.status === 'pending' ? 'bg-blue-50' : ''}`}>
                  <div className="flex items-center justify-between text-left">
                    <div className="flex-1 min-w-0">
                      <h2 className="text-lg font-medium text-indigo-600 truncate text-left">{getApprovalTitle(request?.type)}</h2>
                      <p className="mt-1 text-sm text-gray-500">
                        {displayMessage(request?.type, requestDetails, request?.requester)}</p>
                      <div className="rounded-lg p-4 pl-0">
                        {request.type === "checklist_addition" && (
                          <button
                            onClick={() => handleShowQuestions(request)}
                            className="text-blue-600 hover:text-blue-800 underline text-sm"
                          >
                            Show Questions
                          </button>
                        )}

                        <div className="mt-2 flex items-center text-sm text-gray-500">
                          <span className="mr-2">Requester: {request.requester.name}</span>
                          <span>Date: {request.createdAt}</span>
                        </div>
                        <span className="mr-2 items-center text-sm text-gray-500">Approver: {request.approver?.name || '--'}</span>
                      </div>
                      <p className="mt-1 text-sm font-medium text-gray-700">
                        Status: {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                      </p>
                    </div>
                    {request.status === 'pending' && (
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleApprove(request.id, request)}
                          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        >
                          <Check className="h-4 w-4 mr-1" />
                          Approve
                        </button>
                        <button
                          onClick={() => handleDecline(request.id)}
                          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <X className="h-4 w-4 mr-1" />
                          Decline
                        </button>
                      </div>
                    )}
                  </div>
                </li>
              )
            })}
          </ul>
        </div>

        <Modal
          show={showQuestionsModal}
          onClose={() => {
            setShowQuestionsModal(false);
            setSelectedRequest(null);
          }}
        >
          <div className="p-6 bg-white rounded-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Checklist Questions</h2>
              <button
                onClick={() => {
                  setShowQuestionsModal(false);
                  setSelectedRequest(null);
                }}
                className="text-gray-500 hover:text-gray-700"
              >
                <CloseIcon />
              </button>
            </div>

            <div className="max-h-[70vh] overflow-y-auto">
              {selectedRequest?.associatedQuestions?.map((question: any, index: number) => (
                <div key={question.question_id} className="mb-4 p-3 bg-gray-50 rounded-lg">
                  <div className="flex items-start">
                    <span className="font-medium mr-2">{index + 1}.</span>
                    <div>
                      <p className="font-medium">{question.question_text}</p>
                      <div className="mt-1 text-sm text-gray-600">
                        <span className="mr-4">Type: {question.question_type}</span>
                        {question.maintenance_phase && (
                          <span>Phase: {question.maintenance_phase}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal>

        {/* Success Modal */}
        <Modal show={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
          <div className="p-8 text-center bg-white rounded-lg">
            <img
              src="/success.png"
              alt="Success"
              className="mx-auto mb-6 w-24 h-24"
            />
            <h3 className="mb-4 text-2xl font-semibold text-green-600">
              Request Approved Successfully!
            </h3>
            <div className="mb-6 text-left bg-gray-50 p-4 rounded-md">
              <p className="mb-2">
                <span className="font-semibold">Request Type:</span> {modalDetails?.request_type}
              </p>
              <p className="mb-2">
                <span className="font-semibold">Requester:</span> {modalDetails?.requester_name} ({modalDetails?.requester_id})
              </p>
              <p className="mb-2">
                <span className="font-semibold">Approved By:</span> {modalDetails?.approver_name || 'N/A'}
              </p>
              <p className="mb-2">
                <span className="font-semibold">Date:</span> {modalDetails?.date}
              </p>

              {/* Conditional rendering based on request type */}
              {modalDetails?.type === 'checklist_addition' && (
                <>
                  <p className="mb-2">
                    <span className="font-semibold">Checklist Name:</span> {modalDetails?.details?.name}
                  </p>
                  <p className="mb-2">
                    <span className="font-semibold">Checklist Type:</span> {modalDetails?.details?.checklist_type}
                  </p>
                  <p className="mb-2">
                    <span className="font-semibold">Questions Count:</span> {modalDetails?.details?.associatedQuestions?.length}
                  </p>
                </>
              )}

              {modalDetails?.type === 'role_change' && (
                <>
                  <p className="mb-2">
                    <span className="font-semibold">Employee:</span> {modalDetails?.details?.employee_name}
                  </p>
                  <p className="mb-2">
                    <span className="font-semibold">Previous Role:</span> Level-{modalDetails?.details?.current_role_id} {modalDetails?.details?.current_role_name}
                  </p>
                  <p className="mb-2">
                    <span className="font-semibold">New Role:</span> Level-{modalDetails?.details?.new_role_id} {modalDetails?.details?.new_role_name}
                  </p>
                </>
              )}

              {modalDetails?.type === 'user_registration' && (
                <>
                  <p className="mb-2">
                    <span className="font-semibold">New User:</span> {modalDetails?.details?.first_name} {modalDetails?.details?.last_name}
                  </p>
                  <p className="mb-2">
                    <span className="font-semibold">Role:</span> {modalDetails?.details?.role_name}
                  </p>
                </>
              )}

              {modalDetails?.type === 'team_member_addition' && (
                <>
                  <p className="mb-2">
                    <span className="font-semibold">Team Member:</span> {modalDetails?.details?.employee_name}
                  </p>
                  <p className="mb-2">
                    <span className="font-semibold">Department:</span> {modalDetails?.details?.department_name}
                  </p>
                </>
              )}
            </div>
            <button
              type="button"
              className="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center transition duration-300 ease-in-out transform hover:scale-105"
              onClick={() => setShowSuccessModal(false)}
            >
              Close
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ApprovalPage;
