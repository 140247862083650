import React, { useState } from 'react';
import { FaTasks, FaTools, FaTags, FaClipboardList, FaClipboardCheck } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { TbUsersGroup } from "react-icons/tb";
import { BiSolidReport } from "react-icons/bi";
import { GoAlertFill } from "react-icons/go";
import { FluentAlertOn24Filled } from '../../icons/FluentAlertOn24Filled';
import { AiOutlineFileProtect } from "react-icons/ai";
import { BsBarChartLine } from "react-icons/bs";
import { IoDocumentsOutline } from "react-icons/io5";
import { SiNginxproxymanager } from "react-icons/si";

const Sidebar = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const navigate = useNavigate();

    const handleTabClick = (index: number) => {
        switch (index) {
            case 0:
                navigate('/inspection/tasks');
                break;
            case 1:
                navigate('/inspection/appliances');
                break;
            case 2:
                navigate('/inspection/tags');
                break;
            case 3:
                navigate('/inspection/routine');
                break;
            case 4:
                navigate('/inspection/checklist');
                break;
            case 5:
                navigate('/inspection/teams');
                break;
            case 6:
                navigate('/inspection/statistics');
                break;
            case 7:
                navigate('/inspection/reports');
                break;
            case 8:
                navigate('/inspection/report-issue');
                break;
            case 9:
                navigate('/inspection/alerts');
                break;
            case 10:
                navigate('/inspection/approval');
                break;
            default: return false;
        }
        setSelectedTab(index);
    };

    const features = [
        { icon: <FaTasks size={20} />, text: 'Tasklist', bgColor: "bg-[#0fa4af]" },
        { icon: <FaTools size={20} />, text: 'Appliances', bgColor: "bg-[#0fa4af]" },
        { icon: <FaTags size={20} />, text: 'Tags', bgColor: "bg-[#0fa4af]" },
        { icon: <FaClipboardList size={20} />, text: 'Routine', bgColor: "bg-[#0fa4af]" },
        { icon: <FaClipboardCheck size={20} />, text: 'Checklist', bgColor: "bg-[#0fa4af]" },
        { icon: <TbUsersGroup size={20} />, text: 'Team', bgColor: "bg-[#0fa4af]" },
        { icon: <BsBarChartLine size={20} /> , text: 'Statistics', bgColor: "bg-[#0fa4af]" },
        { icon: <BiSolidReport size={20} />, text: 'Reports', bgColor: "bg-[#0fa4af]" },
        { icon: <GoAlertFill size={20} />, text: 'Report Issue', bgColor: "bg-[#0fa4af]" },
        { icon: <FluentAlertOn24Filled className='h-6 w-6' />, text: 'Alerts', bgColor: "bg-[#0fa4af]" },
        { icon: <AiOutlineFileProtect size={25} />, text: 'Approval', bgColor: "bg-[#0fa4af]" },  
    ];


    return (
        <div className="bg-blue-900 text-white text-sm h-[100vh] sticky pt-4 z-[49] w-48 flex flex-col text-left">
            {features.map((feature, index) => (
                <div
                    key={index}
                    className={`flex flex-col items-center text-center text-white pl-4 py-4 cursor-pointer
                            ${selectedTab === index
                            ? 'font-bold'
                            : ''
                        }`}
                    style={{ alignItems: "flex-start" }}
                    onClick={() => handleTabClick(index)}
                >
                    <div className='flex gap-2'>
                        <div>{feature.icon}</div>
                        <p className='text-md'>{feature.text}</p>
                    </div>
                </div>
            ))}

            {/* <nav className="flex flex-col space-y-4">
                <a href="#" className="flex items-center p-3 rounded-md hover:bg-blue-800">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                            d="M9 13h6m-3-3V7m3 6V7" />
                    </svg>
                    Tasklist
                </a>
                <a href="#" className="flex items-center p-3 rounded-md hover:bg-blue-800">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                            d="M9 12h6m-3-3V7m3 6V7" />
                    </svg>
                    Applian
                </a>
                <a href="#" className="flex items-center p-3 rounded-md hover:bg-blue-800">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                            d="M17 20.05H7m10 0L7 4.951m10 0A3 3 0 0 1 17 7.951V20.05z" />
                    </svg>
                    Logbook
                </a>
                <a href="#" className="flex items-center p-3 rounded-md hover:bg-blue-800">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                            d="M8 12h.01M12 12h.01M16 12h.01M8   
 16V.01M12 16V.01M16 16V.01" />
                    </svg>
                    Support
                </a>
            </nav> */}
        </div>
    );
};

export default Sidebar;