import React, { useState, useEffect } from 'react';
import styles from '../../styles/newModal.module.css';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  extraStyles?: Object; 
}

const NewModal: React.FC<ModalProps> = ({ isOpen, onClose, title = '',extraStyles={}, children }) => {
  const [isVisible, setIsVisible] = useState(isOpen);
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      setIsVisible(true);
      setAnimationClass(styles.slideUp);
    } else {
      setAnimationClass(styles.slideDown);
      const timer = setTimeout(() => {
        setIsVisible(false);
        document.body.style.overflow = '';
      }, 300); 
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isVisible) return null;

  return (
    <div className={styles.overlay} onClick={onClose}>
      <div
        className={`${styles.modal} ${animationClass}`}
        style={extraStyles}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.header}>
          <h4 className='text-xl font-bold'>{title}</h4>
          <button className={styles.closeButton} onClick={onClose}>
            &times;
          </button>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default NewModal;
