import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Chip,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import { 
  Edit as EditIcon, 
  Delete as DeleteIcon,
  Add as AddIcon,
  AssignmentInd as AssignIcon 
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { fetchEmployeeByDepartment, fetchAllEmployees, fetchAllAssets, assignAssetToEmployee } from '../../../../api/endpoints/APIEndpoints';
import { useSelector, shallowEqual } from 'react-redux';
import { selectUserData } from '../../../../store/store/authSlice';
import Loader from '../../../common/Loader';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const TableWrapper = styled(Box)({
  height: 'calc(100vh - 280px)',
  overflow: 'auto'
});

interface TeamMember {
  employeeId: string;
  firstName: string;
  lastName: string;
  email: string;
  role: {
    roleName: string;
  };
  departments: Array<{
    departmentId: string;
    departmentName: string;
  }>;
  assigned_assets?: Array<{
    asset_id: string;
    asset_name: string;
  }>;
  status: 'active' | 'inactive';
}

const TeamDashboard: React.FC = () => {
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const userDetails = useSelector(selectUserData, shallowEqual);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [assignmentForm, setAssignmentForm] = useState({
    asset_id: '',
    employee_id: '',
    team_id: userDetails?.teams[0]?.team_id || '',
    assignment_type: 'permanent',
    start_date: dayjs(),
    end_date: null,
    notes: '',
    assigned_by: userDetails?.employee_id,
    status: 'active'
  });
  const [availableAssets, setAvailableAssets] = useState<any[]>([]);

  useEffect(() => {
    fetchTeamData();
  }, []);

  const fetchTeamData = async () => {
    try {
      setIsLoading(true);
      if (!userDetails?.departments) return;

      const employeePromises = userDetails.departments.map(dept => 
        fetchEmployeeByDepartment.post({ departmentId: dept.department_id })
      );

      const departmentEmployees = await Promise.all(employeePromises);
      const employeeMap = new Map();

      departmentEmployees.forEach((deptEmployees, index) => {
        const currentDept = userDetails.departments[index];
        
        deptEmployees.forEach((employee: any) => {
          const employeeKey = employee.employeeId;
          
          if (employeeMap.has(employeeKey)) {
            const existingEmployee = employeeMap.get(employeeKey);
            if (!existingEmployee.departments.some(
              (dept: any) => dept.departmentId === currentDept.department_id
            )) {
              existingEmployee.departments.push({
                departmentId: currentDept.department_id,
                departmentName: currentDept.department_name
              });
            }
          } else {
            employeeMap.set(employeeKey, {
              ...employee,
              departments: [{
                departmentId: currentDept.department_id,
                departmentName: currentDept.department_name
              }],
              assigned_assets: [] // Initialize empty assigned assets array
            });
          }
        });
      });

      const uniqueEmployees = Array.from(employeeMap.values());
      setTeamMembers(uniqueEmployees);
    } catch (error) {
      console.error('Error fetching team data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenAssignModal = async () => {
    try {
      const assets = await fetchAllAssets.get();
      const unassignedAssets = assets.data.filter((asset: any) => 
        !asset.assigned_to && asset.status === 'active'
      );
      setAvailableAssets(unassignedAssets);
      setIsAssignModalOpen(true);
    } catch (error) {
      console.error('Error fetching assets:', error);
    }
  };

  const handleAssignAsset = async () => {
    try {
      if (!assignmentForm.asset_id || !assignmentForm.employee_id) return;
      
      await assignAssetToEmployee.post({
        ...assignmentForm,
        start_date: dayjs(assignmentForm.start_date).format('YYYY-MM-DD'),
        end_date: assignmentForm.end_date ? dayjs(assignmentForm.end_date).format('YYYY-MM-DD') : null,
      });

      setIsAssignModalOpen(false);
      setAssignmentForm({
        asset_id: '',
        employee_id: '',
        team_id: userDetails?.teams[0]?.team_id || '',
        assignment_type: 'permanent',
        start_date: dayjs(),
        end_date: null,
        notes: '',
        assigned_by: userDetails?.employee_id,
        status: 'active'
      });
      fetchTeamData();
      
    } catch (error) {
      console.error('Error assigning asset:', error);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="flex flex-col h-full">
      {/* Header Actions */}
      <div className="flex justify-end mb-6 gap-4">
        <Button
          variant="contained"
          startIcon={<AssignIcon />}
          color="primary"
          onClick={handleOpenAssignModal}
        >
          Assign Asset
        </Button>
      </div>

      {/* Statistics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <Paper className="p-4">
          <Typography variant="h6" color="textSecondary">Total Members</Typography>
          <Typography variant="h4">{teamMembers.length}</Typography>
        </Paper>
        <Paper className="p-4">
          <Typography variant="h6" color="textSecondary">Departments</Typography>
          <Typography variant="h4">
            {new Set(teamMembers.flatMap(member => 
              member.departments.map(dept => dept.departmentName)
            )).size}
          </Typography>
        </Paper>
        <Paper className="p-4">
          <Typography variant="h6" color="textSecondary">Assigned Assets</Typography>
          <Typography variant="h4">
            {teamMembers.reduce((acc, member) => acc + (member.assigned_assets?.length || 0), 0)}
          </Typography>
        </Paper>
      </div>

      {/* Team Members Table */}
      <TableWrapper>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Employee ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Assigned Assets</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamMembers.map((member) => (
                <TableRow key={member.employeeId}>
                  <TableCell>{member.employeeId}</TableCell>
                  <TableCell>{`${member.firstName} ${member.lastName}`}</TableCell>
                  <TableCell>{member.email}</TableCell>
                  <TableCell>
                    {member.departments.map(dept => (
                      <Chip
                        key={dept.departmentId}
                        label={dept.departmentName}
                        size="small"
                        className="mr-1 mb-1"
                      />
                    ))}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={member.role.roleName}
                      size="small"
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    {member.assigned_assets?.map(asset => (
                      <Chip
                        key={asset.asset_id}
                        label={asset.asset_name}
                        size="small"
                        className="mr-1 mb-1"
                        variant="outlined"
                      />
                    )) || 'No assets assigned'}
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2">
                      <Tooltip title="Assign Asset">
                        <IconButton 
                          size="small" 
                          color="primary"
                          onClick={() => handleOpenAssignModal()}
                        >
                          <AssignIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton size="small">
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>

      <Dialog
        open={isAssignModalOpen}
        onClose={() => setIsAssignModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Assign Asset
        </DialogTitle>
        <DialogContent dividers>
          <div className="space-y-4">
            {/* Employee Selection */}
            <FormControl fullWidth>
              <InputLabel>Select Employee</InputLabel>
              <Select
                value={assignmentForm.employee_id}
                onChange={(e) => setAssignmentForm(prev => ({
                  ...prev,
                  employee_id: e.target.value
                }))}
                label="Select Employee"
              >
                {teamMembers.map((employee) => (
                  <MenuItem key={employee.employeeId} value={employee.employeeId}>
                    <div className="flex justify-between items-center w-full">
                      <span>{`${employee.firstName} ${employee.lastName}`}</span>
                      <Chip 
                        label={`ID: ${employee.employeeId}`}
                        size="small"
                        variant="outlined"
                      />
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Asset Selection */}
            <FormControl fullWidth>
              <InputLabel>Select Asset</InputLabel>
              <Select
                value={assignmentForm.asset_id}
                onChange={(e) => setAssignmentForm(prev => ({
                  ...prev,
                  asset_id: e.target.value
                }))}
                label="Select Asset"
              >
                {availableAssets.map((asset) => (
                  <MenuItem key={asset.asset_id} value={asset.asset_id}>
                    <div className="flex justify-between items-center w-full">
                      <span>{asset.asset_name}</span>
                      <Chip 
                        label={`ID: ${asset.asset_id}`}
                        size="small"
                        variant="outlined"
                      />
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Assignment Type */}
            <FormControl fullWidth>
              <InputLabel>Assignment Type</InputLabel>
              <Select
                value={assignmentForm.assignment_type}
                onChange={(e) => setAssignmentForm(prev => ({
                  ...prev,
                  assignment_type: e.target.value,
                  end_date: e.target.value === 'permanent' ? null : prev.end_date
                }))}
                label="Assignment Type"
              >
                <MenuItem value="permanent">Permanent</MenuItem>
                <MenuItem value="temporary">Temporary</MenuItem>
              </Select>
            </FormControl>

            {/* Date Selection */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="grid grid-cols-2 gap-4">
                <DatePicker
                  label="Start Date"
                  value={assignmentForm.start_date}
                  onChange={(newValue) => setAssignmentForm((prev: any) => ({
                    ...prev,
                    start_date: newValue
                  }))}
                  slotProps={{ textField: { fullWidth: true } }}
                />
                {assignmentForm.assignment_type === 'temporary' && (
                  <DatePicker
                    label="End Date"
                    value={assignmentForm.end_date}
                    onChange={(newValue) => setAssignmentForm((prev: any) => ({
                      ...prev,
                      end_date: newValue
                    }))}
                    slotProps={{ textField: { fullWidth: true } }}
                    minDate={dayjs(assignmentForm.start_date).add(1, 'day')}
                  />
                )}
              </div>
            </LocalizationProvider>

            {/* Notes */}
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Assignment Notes"
              value={assignmentForm.notes}
              onChange={(e) => setAssignmentForm(prev => ({
                ...prev,
                notes: e.target.value
              }))}
              placeholder="Add any relevant notes about this assignment"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setIsAssignModalOpen(false)}
            color="inherit"
          >
            Cancel
          </Button>
          <Button
            onClick={handleAssignAsset}
            variant="contained"
            color="primary"
            disabled={!assignmentForm.asset_id || !assignmentForm.employee_id}
          >
            Assign Asset
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TeamDashboard; 